import { useState, FC, useEffect } from "react";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { DatePickerInterface } from "./types";
import { useMount } from "hooks/useMount";
import { useTranslation } from "react-i18next";


export const BasicDatePicker: FC<DatePickerInterface> = ({
  label,
  handleValue,
  initValue,
  isStartOfTheDay = false,
  isEndOfTheDay = false,
  minValue,
  maxValue,
  validationMessage,
  error,
}: DatePickerInterface) => {
  const [value, setValue] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState<Date | undefined | any>(undefined);
  const [maxDate, setMaxDate] = useState<Date | undefined | any>(undefined);
  const { i18n } = useTranslation();

  const maskMap = {
    en: '__/__/____',
    de: '__.__.____',
  };

  const defineTime = (d: Date) => {
    if (isStartOfTheDay) d.setHours(0, 0, 0, 0);
    if (isEndOfTheDay) d.setHours(23, 59, 59, 999);

    return d;
  }

  useMount(() => {
    if (initValue) {
      setValue(new Date(initValue));
    }
  });
  useEffect(() => {
    if (minValue) setMinDate(minValue);
  }, [minValue])
  useEffect(() => {
    if (maxValue) setMaxDate(maxValue);
  }, [maxValue])

  return (
    <DatePicker
      label={label}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      mask={maskMap[i18n.language]}
      disableHighlightToday={true}
      onChange={(newValue: Date | null) => {
        setValue(newValue);
        if (newValue instanceof Date) {
          handleValue(defineTime(newValue));
        } else {
          handleValue(null);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={"standard"}
          fullWidth={true}
          color={"accent"}
          style={{ marginTop: "16px" }}
          helperText={validationMessage}
          error={error}
          autoComplete={"off"}
        />
      )}
    />
  );
};
