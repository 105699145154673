import { RootReducer } from "../../reducers";
import { Program, TableProgram } from "./types";

export const tableProgramsSelector = (
  state: RootReducer
): TableProgram[] | null =>
  state.programs.programs?.map((program) => ({
    id: program.id,
    name: program.name,
    created_at: program.created_at,
    external_id: program.external_id,
    code: program.code
  })) || null;

export const programSelector = (state: RootReducer): Program | null =>
  state.programs.program;

export const isProgramCreatingSelector = (state: RootReducer): boolean =>
  state.programs.isProgramCreating;

export const isProgramUpdatingSelector = (state: RootReducer): boolean =>
  state.programs.isProgramUpdating;

export const isProgramDeletingSelector = (state: RootReducer): boolean =>
  state.programs.isProgramDeleting;

export const isProgramLoadingSelector = (state: RootReducer): boolean =>
  state.programs.isProgramLoading;

export const areProgramsLoadingSelector = (state: RootReducer): boolean =>
  state.programs.areProgramsLoading;
