import { FC } from "react";
import { Helmet } from "react-helmet";
import { HeadProps } from "./types";

export const Head: FC<HeadProps> = ({ title }: HeadProps) => {
  const titleText = `${title ? `${title} - ` : ""}TSG Portal`;

  return (
    <Helmet>
      <title>{titleText}</title>
      <link rel={"shortcut icon"} href={"/favicon.ico"} />
    </Helmet>
  );
};
