import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";


export type Reducer = {
    stationCalendarResponse: GetStationCalendarResponse | null;
    isCalendarLoading: boolean;
};


const initialState: Reducer = {
    stationCalendarResponse: null,
    isCalendarLoading: false,
};

export const reducer = reducerWithInitialState(initialState)
    .case(
        actions.getStationCalendar.started,
        (state): Reducer => ({
            ...state,
            isCalendarLoading: true
        })
    )
    .case(
        actions.getStationCalendar.done,
        (state, payload): Reducer => ({
            ...state,
            stationCalendarResponse: payload.result,
            isCalendarLoading: false
        })
    )
    .case(
        actions.getStationCalendar.failed,
        (state): Reducer => ({
            ...state,
            isCalendarLoading: false
        })
    )
    .case(
        actions.putCalendar.started,
        (state): Reducer => ({
            ...state,
            isCalendarLoading: true
        })
    )
    .case(
        actions.putCalendar.done,
        (state, payload): Reducer => ({
            ...state,
            isCalendarLoading: false
        })
    )
    .case(
        actions.putCalendar.failed,
        (state): Reducer => ({
            ...state,
            isCalendarLoading: false
        })
    )
    .case(
        actions.putStationCalendar.started,
        (state): Reducer => ({
            ...state,
            isCalendarLoading: true
        })
    )
    .case(
        actions.putStationCalendar.done,
        (state, payload): Reducer => ({
            ...state,
            isCalendarLoading: false
        })
    )
    .case(
        actions.putStationCalendar.failed,
        (state): Reducer => ({
            ...state,
            isCalendarLoading: false
        })
    )
    .case(actions.clear, (): Reducer => initialState);
