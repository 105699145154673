import { AxiosResponse } from "axios";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { axiosInstance } from "../../../axios";
import { keycloak } from "../../../keycloak";
import * as actions from "./actions";
import { enqueueSnackbar } from '../notifications/actions';
import {
  CreatePlanParams,
  CreatePlanResponse,
  DeletePlanParams,
  DeletePlanResponse,
  GetPlanParams,
  GetPlanResponse,
  GetPlansParams,
  GetPlansResponse,
  UpdatePlanParams,
  UpdatePlanResponse
} from "./types";

export function* getPlanSaga(
  action: Action<GetPlanParams>
): SagaIterator<void> {
  try {
    const { id } = action.payload;
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<GetPlanResponse> = yield call(
      axiosInstance.get,
      `plans/${id}`
    );
    yield put(
      actions.getPlan.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(actions.getPlan.failed({ params: action.payload, error: e }));
  }
}

export function* getPlansSaga(
  action: Action<GetPlansParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<GetPlansResponse> = yield call(
      axiosInstance.get,
      `plans`
    );
    yield put(
      actions.getPlans.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(actions.getPlans.failed({ params: action.payload, error: e }));
  }
}

export function* createPlanSaga(
  action: Action<CreatePlanParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { data } = action.payload;
    const response: AxiosResponse<CreatePlanResponse> = yield call(
      axiosInstance.post,
      `plans`,
      data
    );
    yield put(
      actions.createPlan.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.plan.create', variant: 'success' }));
  } catch (e: any) {
    yield put(actions.createPlan.failed({ params: action.payload, error: e }));
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* updatePlanSaga(
  action: Action<UpdatePlanParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { id, data } = action.payload;
    const response: AxiosResponse<UpdatePlanResponse> = yield call(
      axiosInstance.put,
      `plans/${id}`,
      data
    );
    yield put(
      actions.updatePlan.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.plan.update', variant: 'success' }));
  } catch (e: any) {
    yield put(actions.updatePlan.failed({ params: action.payload, error: e }));
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* deletePlanSaga(
  action: Action<DeletePlanParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { id } = action.payload;
    const response: AxiosResponse<DeletePlanResponse> = yield call(
      axiosInstance.delete,
      `plans/${id}`
    );
    yield put(
      actions.deletePlan.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.plan.delete', variant: 'success' }));
  } catch (e: any) {
    yield put(actions.deletePlan.failed({ params: action.payload, error: e }));
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getPlan.started, getPlanSaga),
    takeLeading(actions.getPlans.started, getPlansSaga),
    takeEvery(actions.createPlan.started, createPlanSaga),
    takeEvery(actions.updatePlan.started, updatePlanSaga),
    takeEvery(actions.deletePlan.started, deletePlanSaga)
  ]);
}
