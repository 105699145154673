import Button from "@mui/material/Button";
import { FormDialog } from "components/common/Dialog/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/Dialog/FormDialog/types";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as programsActions from "../../../store/modules/programs/actions";
import {
  isProgramCreatingSelector,
  isProgramDeletingSelector,
  isProgramUpdatingSelector,
  tableProgramsSelector
} from "../../../store/modules/programs/selectors";
import { TableProgram } from "../../../store/modules/programs/types";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { number, string } from "yup";
import { DIALOG_TYPES } from "./types";
import { useTranslation } from "react-i18next";

export const Programs: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const programs = useSelector(tableProgramsSelector);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const isProgramCreating = useSelector(isProgramCreatingSelector);
  const isProgramUpdating = useSelector(isProgramUpdatingSelector);
  const isProgramDeleting = useSelector(isProgramDeletingSelector);
  const isOperationInProgress =
    isProgramCreating || isProgramUpdating || isProgramDeleting;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleCreateProgramButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE,
      isOpened: true
    });
  }, []);

  const handleEditProgramMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleDeleteProgramMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const tableActions: TableRowActionsMenuItem[] = [
    {
      label: t("uiComponents.table.actions.edit"),
      handler: handleEditProgramMenuItemClick,
      isDisabled: () => true
    },
    {
      label: t("uiComponents.table.actions.delete"),
      handler: handleDeleteProgramMenuItemClick
    }
  ];

  const tableColumns: TableColumn[] = [
    { key: "name", label: t("uiComponents.table.headers.name") },
    { key: "code", label: t("uiComponents.table.headers.code") },
    { key: "external_id", label: t("uiComponents.table.headers.external_id") }
  ];

  useMount(() => {
    dispatch(programsActions.getPrograms.started({}));
  });

  useUnmount(() => {
    dispatch(programsActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(programsActions.getPrograms.started({}));
    }
  }, [previousIsOperationInProgress, isOperationInProgress, dispatch]);

  const handleConfirmCreateProgram = useCallback(
    (data: {
      id: string;
      created_at: string;
      code: string;
      external_id: string;
      name: string;
    }) => {
      dispatch(
        programsActions.createProgram.started({
          data: { ...data }
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog]
  );

  const handleConfirmEditProgram = useCallback(
    (data: {
      id: string;
      created_at: string;
      code: string;
      external_id: string;
      name: string;
    }) => {
      if (selectedItemId) {
        dispatch(
          programsActions.updateProgram.started({
            id: selectedItemId,
            data: { ...data }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmDeleteProgram = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        programsActions.deleteProgram.started({
          id: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [dispatch, selectedItemId, handleCloseDialog]);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateProgram,
      title: "Create program",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string().required()
        },
        {
          name: "external_id",
          type: FIELD_TYPES.TEXT,
          label: "external ID",
          rules: string().required()
        },
        {
          name: "code",
          type: FIELD_TYPES.TEXT,
          label: "code",
          rules: string().required()
        },
        {
          name: "client_id",
          type: FIELD_TYPES.NUMBER,
          label: "Client ID",
          rules: number().required()
        }
      ]
    },
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditProgram,
      title: "Edit program",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            programs?.find((program) => program.id === selectedItemId)?.name ||
            "",
          rules: string().required()
        },
        {
          name: "code",
          type: FIELD_TYPES.TEXT,
          label: "Code",
          defaultValue:
            programs?.find((program) => program.id === selectedItemId)?.code ||
            "",
          rules: string().required()
        },
        {
          name: "external_id",
          type: FIELD_TYPES.TEXT,
          label: "External ID",
          defaultValue:
            programs?.find((program) => program.id === selectedItemId)
              ?.external_id || "",
          rules: string().required()
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteProgram,
      title: t("uiComponents.deleteForm.titles.program"),
      confirmButtonLabel: t("uiComponents.deleteForm.actions.delete")
    }
  };

  return (
    <>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={programs as any || []}
        columns={tableColumns}
        actions={tableActions}
        isLoading={!programs}
        toolbarItems={
          <Button
            onClick={handleCreateProgramButtonClick}
            variant={"contained"}
            disabled={true}
          >
            Create Program
          </Button>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
