import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { DialogTitle as DialogTitleOrg } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import DialogContentOrg from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const Dialog = styled(MuiDialog)`
  & .MuiDialog-paper {
    width: 600px;
  }
`;

export const DialogTitle = styled(DialogTitleOrg)`
  display: flex;
  justify-content: space-between;
`

export const DialogContent = styled(DialogContentOrg)`
  display: flex;
  flex-direction: column;
`;

export const FormDialogContent = styled(DialogContentOrg)`
  display: flex;
  flex-direction: column;
`;

export const StepArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(0, 1)};
`;

export const StepButton = styled(IconButton)`
  padding: 0;
`;

export const ArrowUpIcon = styled(ArrowUpward)`
  font-size: small;
`;

export const ArrowDownIcon = styled(ArrowDownward)`
  font-size: small;
`;

export const LabelFieldText = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const LoaderContainer = styled.div`
  height: ${({ theme }) => theme.spacing(9)};
`;
