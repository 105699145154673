import {
  Box,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import {
  PayMethodTableFilterFormInterface,
  PayMethodTableFilterParams,
  TransactionsTableFilterFormInterface,
  TransactionsTableFilterParams
} from "./types";
import { debounce } from "lodash";
import { t } from "i18next";
import { ArrowUpward } from "@mui/icons-material";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import { useTranslation } from "react-i18next";

export const PayMethodTableFilterForm: FC<
  PayMethodTableFilterFormInterface
> = ({
  tableFilterParams = {
    type: "",
    page: 1,
    order_by: "external_id",
    sorting_order: "asc",
    search: ""
  },
  tableColumns,
  typeNames,
  onTableShouldUpdate
}) => {
  const [filterParamsState, setfilterParamsState] =
    useState<PayMethodTableFilterParams>(tableFilterParams);

  const updateTable = useCallback(
    // debounce table update callback by 500ms because this can potentially
    // be called very often (on user type in search field).
    debounce(() => {
      onTableShouldUpdate(filterParamsState);
    }, 500),
    [filterParamsState]
  );

  useEffect(() => {
    updateTable();
  }, [filterParamsState, updateTable]);

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        key="type_filter"
        select
        label={t("uiComponents.table.actions.type_filter")}
        sx={{ minWidth: "200px" }}
        size="small"
        color="accent"
        value={filterParamsState.type}
        onChange={(e) => {
          // overwrite current filterParamsState with state containing new type name
          return setfilterParamsState({
            ...filterParamsState,
            type: e.target.value
          });
        }}
      >
        <MenuItem key="type-filter-none" value="">
          {t("no-filter")}
        </MenuItem>
        {typeNames.map((tn) => (
          <MenuItem key={tn.key} value={tn.key}>
            {tn.label}
          </MenuItem>
        ))}
      </TextField>
      <Stack direction="row" spacing={1}>
        <TextField
          key="sort_by"
          select
          label={t("uiComponents.table.actions.sort_by")}
          sx={{ minWidth: "200px" }}
          size="small"
          color="accent"
          value={filterParamsState.order_by}
          onChange={(e) =>
            // overwrite current filterParamsState with state containing new sort_by item
            setfilterParamsState({
              ...filterParamsState,
              order_by: e.target.value
            })
          }
        >
          {tableColumns.map((tc) => (
            <MenuItem key={tc.key} value={tc.key}>
              {tc.label}
            </MenuItem>
          ))}
        </TextField>
        <ToggleButtonGroup
          value={filterParamsState.sorting_order}
          exclusive
          size="small"
          onChange={(_, sorting_order: string) => {
            setfilterParamsState({
              ...filterParamsState,
              sorting_order
            });
          }}
          aria-label="sorting order"
        >
          <ToggleButton value="asc" aria-label="ascending order">
            <ArrowUpward />
          </ToggleButton>
          <ToggleButton value="desc" aria-label="descending order">
            <ArrowDownward />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Box key="search" sx={{ maxWidth: "200px" }}>
        <TextField
          type="text"
          variant="outlined"
          label={t("uiComponents.search.label")}
          size="small"
          fullWidth={true}
          color={"accent"}
          onChange={(e) =>
            // overwrite current filterParamsState with state containing new search string
            setfilterParamsState({
              ...filterParamsState,
              search: e.target.value
            })
          }
        />
      </Box>
    </Stack>
  );
};

export const TransactionsTableFilterForm: FC<
  TransactionsTableFilterFormInterface
> = ({
  tableFilterParams = {
    page: 1,
    station_id: "",
    terminal_id: ""
  },
  nestedStations,
  onTableShouldUpdate
}) => {
  const { t, i18n } = useTranslation();

  const [filterParamsState, setfilterParamsState] =
    useState<TransactionsTableFilterParams>(tableFilterParams);

  const updateTable = useCallback(
    // debounce table update callback by 500ms because this can potentially
    // be called very often (on user type in search field).
    debounce(() => {
      onTableShouldUpdate(filterParamsState);
    }, 500),
    [filterParamsState]
  );

  const getTerminals = () => {
    let terminals = nestedStations[filterParamsState.station_id]?.terminals;
    if (terminals !== undefined) {
      return Object.values(terminals);
    }

    terminals = {};
    for (const station of Object.values(nestedStations)) {
      terminals = {
        ...terminals,
        ...station.terminals
      };
    }

    return Object.values(terminals);
  };

  useEffect(() => {
    updateTable();
  }, [filterParamsState, updateTable]);

  return (
    <Stack direction="row" spacing={2}>
      <Stack direction="row" spacing={1}>
        <TextField
          key="station"
          select
          label={t("transaction.uiComponents.table.actions.station")}
          sx={{ minWidth: "200px" }}
          size="small"
          color="accent"
          value={filterParamsState.station_id}
          onChange={(e) =>
            // overwrite current filterParamsState with state containing new sort_by item
            setfilterParamsState({
              ...filterParamsState,
              station_id: e.target.value,
              terminal_id: ""
            })
          }
        >
          <MenuItem key="no-station" value="">
            {t("no-filter")}
          </MenuItem>
          {Object.entries(nestedStations).map(([stationId, station]) => (
            <MenuItem key={stationId} value={stationId}>
              {station.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          key="terminal"
          select
          label={t("transaction.uiComponents.table.actions.terminal")}
          sx={{ minWidth: "200px" }}
          size="small"
          color="accent"
          value={filterParamsState.terminal_id}
          onChange={(e) =>
            // overwrite current filterParamsState with state containing new sort_by item
            setfilterParamsState({
              ...filterParamsState,
              terminal_id: e.target.value
            })
          }
        >
          <MenuItem key="no-terminal" value="">
            {t("no-filter")}
          </MenuItem>
          {getTerminals().map((terminal) => (
            <MenuItem key={terminal.id} value={terminal.id}>
              {terminal.name}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Stack>
  );
};
