import { useState, FC, ChangeEvent, useEffect } from "react";
import { MultiSelectInterface } from "components/common/FormFields/MultiSelect/types";
import { SelectOption } from "components/common/Dialog/FormDialog/types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useMount } from "hooks/useMount";

export const MultiSelect: FC<MultiSelectInterface> = ({
  label,
  invisible,
  options,
  handleValue,
  autocomplete,
  initValue = [],
  payloadValue = null,
  error,
  validationMessage,
}) => {
  const [value, setValue] = useState<SelectOption[] | []>([]);

  useMount(() => {
    setValue(initValue)
    handleValue(initValue.map((option) => option.value))
  })

  return (
    <Autocomplete
      multiple
      limitTags={2}
      value={value}
      options={options}
      fullWidth={true}
      onChange={(e, value: SelectOption[]) => {
        const programsIdsList: string[] = value.map(
          (program) => program.value
        );
        setValue(value);
        handleValue(programsIdsList);
      }}
      getOptionLabel={(option: SelectOption) =>
        option.label
      }
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value;
      }}
      renderOption={(props, option: SelectOption) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={"standard"}
          label={label}
          fullWidth={true}
          color={"accent"}
          autoComplete={autocomplete}
          error={error}
          style={{ marginTop: "16px" }}
          helperText={validationMessage}
        />
      )}
    />
  )
}
