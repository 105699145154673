import { AxiosResponse } from "axios";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { axiosInstance } from "../../../axios";
import { keycloak } from "../../../keycloak";
import * as actions from "./actions";
import { enqueueSnackbar } from '../notifications/actions';
import {
  AddCreditParams,
  AddCreditResponse,
  CreatePaymentMethodParams,
  CreatePaymentMethodResponse,
  CreateVoucherParams,
  CreateVoucherResponse,
  DeletePaymentMethodParams,
  DeletePaymentMethodResponse,
  GetPaymentMethodParams,
  GetPaymentMethodResponse,
  GetPaymentMethodsParams,
  GetPaymentMethodsResponse,
  PaymentMethod,
  UpdatePaymentMethodParams as UpdatePaymentMethodParams,
  UpdatePaymentMethodResponse
} from "./types";

export function* getPaymentMethodSaga(
  action: Action<GetPaymentMethodParams>
): SagaIterator<void> {
  try {
    const { id } = action.payload;
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<GetPaymentMethodResponse> = yield call(
      axiosInstance.get,
      `pay-methods/${id}`
    );
    yield put(
      actions.getPaymentMethod.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(
      actions.getPaymentMethod.failed({ params: action.payload, error: e })
    );
  }
}

export function* getPaymentMethodsSaga(
  action: Action<GetPaymentMethodsParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<PaymentMethod[]> = yield call(
      axiosInstance.get,
      `pay-methods`,
      {
        params: action.payload
      }
    );
    yield put(
      actions.getPaymentMethods.done({
        params: action.payload,
        result: {
          data: response.data,
          totalPages: parseInt(response.headers["x-total-pages"])
        }
      })
    );
  } catch (e) {
    yield put(
      actions.getPaymentMethods.failed({ params: action.payload, error: e })
    );
  }
}

export function* createPaymentMethodSaga(
  action: Action<CreatePaymentMethodParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { data } = action.payload;
    const response: AxiosResponse<CreatePaymentMethodResponse> = yield call(
      axiosInstance.post,
      `pay-methods`,
      data
    );
    yield put(
      actions.createPaymentMethod.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.paymentMethod.create', variant: 'success' }));
  } catch (e: any) {
    yield put(
      actions.createPaymentMethod.failed({ params: action.payload, error: e })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* createVoucherSaga(
  action: Action<CreateVoucherParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { data } = action.payload;
    const response: AxiosResponse<CreateVoucherResponse> = yield call(
      axiosInstance.post,
      `vouchers`,
      data
    );
    yield put(
      actions.createVoucher.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.paymentMethod.create', variant: 'success' }));
  } catch (e: any) {
    yield put(
      actions.createVoucher.failed({ params: action.payload, error: e })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* updatePaymentMethodSaga(
  action: Action<UpdatePaymentMethodParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { id, data } = action.payload;
    const response: AxiosResponse<UpdatePaymentMethodResponse> = yield call(
      axiosInstance.put,
      `pay-methods/${id}`,
      data
    );
    yield put(
      actions.updatePaymentMethod.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.paymentMethod.update', variant: 'success' }));
  } catch (e: any) {
    yield put(
      actions.updatePaymentMethod.failed({ params: action.payload, error: e })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* deletePaymentMethodSaga(
  action: Action<DeletePaymentMethodParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { id } = action.payload;
    const response: AxiosResponse<DeletePaymentMethodResponse> = yield call(
      axiosInstance.delete,
      `pay-methods/${id}`
    );
    yield put(
      actions.deletePaymentMethod.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.paymentMethod.delete', variant: 'success' }));
  } catch (e: any) {
    yield put(
      actions.deletePaymentMethod.failed({ params: action.payload, error: e })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* addCreditSaga(
  action: Action<AddCreditParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { id, data } = action.payload;
    const response: AxiosResponse<AddCreditResponse> = yield call(
      axiosInstance.put,
      `pay-methods/${id}/balance`,
      data
    );
    yield put(
      actions.addCredit.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.paymentMethod.add_credit', variant: 'success' }));
  } catch (e: any) {
    yield put(
      actions.addCredit.failed({ params: action.payload, error: e })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getPaymentMethod.started, getPaymentMethodSaga),
    takeLeading(actions.getPaymentMethods.started, getPaymentMethodsSaga),
    takeEvery(actions.createPaymentMethod.started, createPaymentMethodSaga),
    takeEvery(actions.createVoucher.started, createVoucherSaga),
    takeEvery(actions.updatePaymentMethod.started, updatePaymentMethodSaga),
    takeEvery(actions.deletePaymentMethod.started, deletePaymentMethodSaga),
    takeEvery(actions.addCredit.started, addCreditSaga)
  ]);
}
