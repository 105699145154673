import actionCreatorFactory from "typescript-fsa";
import { GetTransactionsParams, GetTransactionsResponse } from "./types";

const actionCreator = actionCreatorFactory("TRANSACTIONS");


export const getTransactions = actionCreator.async<
    GetTransactionsParams,
    GetTransactionsResponse,
    unknown
>("GET_TRANSACTIONS");


export const clear = actionCreator<void>("CLEAR");