import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Program } from "./types";

export type Reducer = {
  programs: Program[] | null;
  program: Program | null;
  isProgramLoading: boolean;
  areProgramsLoading: boolean;
  isProgramCreating: boolean;
  isProgramUpdating: boolean;
  isProgramDeleting: boolean;
};

const initialState: Reducer = {
  programs: null,
  program: null,
  isProgramLoading: false,
  areProgramsLoading: false,
  isProgramCreating: false,
  isProgramUpdating: false,
  isProgramDeleting: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getProgram.started,
    (state): Reducer => ({
      ...state,
      isProgramLoading: true
    })
  )
  .case(
    actions.getProgram.done,
    (state, payload): Reducer => ({
      ...state,
      program: payload.result,
      isProgramLoading: false
    })
  )
  .case(
    actions.getProgram.failed,
    (state): Reducer => ({
      ...state,
      isProgramLoading: false
    })
  )
  .case(
    actions.getPrograms.started,
    (state): Reducer => ({
      ...state,
      areProgramsLoading: true
    })
  )
  .case(
    actions.getPrograms.done,
    (state, payload): Reducer => ({
      ...state,
      programs: payload.result,
      areProgramsLoading: false
    })
  )
  .case(
    actions.getPrograms.failed,
    (state): Reducer => ({
      ...state,
      areProgramsLoading: false
    })
  )
  .case(
    actions.createProgram.started,
    (state): Reducer => ({
      ...state,
      isProgramCreating: true
    })
  )
  .case(
    actions.createProgram.done,
    (state): Reducer => ({
      ...state,
      isProgramCreating: false
    })
  )
  .case(
    actions.createProgram.failed,
    (state): Reducer => ({
      ...state,
      isProgramCreating: false
    })
  )
  .case(
    actions.updateProgram.started,
    (state): Reducer => ({
      ...state,
      isProgramUpdating: true
    })
  )
  .case(
    actions.updateProgram.done,
    (state): Reducer => ({
      ...state,
      isProgramUpdating: false
    })
  )
  .case(
    actions.updateProgram.failed,
    (state): Reducer => ({
      ...state,
      isProgramUpdating: false
    })
  )
  .case(
    actions.deleteProgram.started,
    (state): Reducer => ({
      ...state,
      isProgramDeleting: true
    })
  )
  .case(
    actions.deleteProgram.done,
    (state): Reducer => ({
      ...state,
      isProgramDeleting: false,
      program: null
    })
  )
  .case(
    actions.deleteProgram.failed,
    (state): Reducer => ({
      ...state,
      isProgramDeleting: false
    })
  )
  .case(actions.clear, (): Reducer => initialState);
