import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Plan } from "./types";

export type Reducer = {
  plans: Plan[] | null;
  plan: Plan | null;
  isPlanLoading: boolean;
  arePlansLoading: boolean;
  isPlanCreating: boolean;
  isPlanUpdating: boolean;
  isPlanDeleting: boolean;
};

const initialState: Reducer = {
  plans: null,
  plan: null,
  isPlanLoading: false,
  arePlansLoading: false,
  isPlanCreating: false,
  isPlanUpdating: false,
  isPlanDeleting: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getPlan.started,
    (state): Reducer => ({
      ...state,
      isPlanLoading: true
    })
  )
  .case(
    actions.getPlan.done,
    (state, payload): Reducer => ({
      ...state,
      plan: payload.result,
      isPlanLoading: false
    })
  )
  .case(
    actions.getPlan.failed,
    (state): Reducer => ({
      ...state,
      isPlanLoading: false
    })
  )
  .case(
    actions.getPlans.started,
    (state): Reducer => ({
      ...state,
      arePlansLoading: true
    })
  )
  .case(
    actions.getPlans.done,
    (state, payload): Reducer => ({
      ...state,
      plans: payload.result,
      arePlansLoading: false
    })
  )
  .case(
    actions.getPlans.failed,
    (state): Reducer => ({
      ...state,
      arePlansLoading: false
    })
  )
  .case(
    actions.createPlan.started,
    (state): Reducer => ({
      ...state,
      isPlanCreating: true
    })
  )
  .case(
    actions.createPlan.done,
    (state): Reducer => ({
      ...state,
      isPlanCreating: false
    })
  )
  .case(
    actions.createPlan.failed,
    (state): Reducer => ({
      ...state,
      isPlanCreating: false
    })
  )
  .case(
    actions.updatePlan.started,
    (state): Reducer => ({
      ...state,
      isPlanUpdating: true
    })
  )
  .case(
    actions.updatePlan.done,
    (state): Reducer => ({
      ...state,
      isPlanUpdating: false
    })
  )
  .case(
    actions.updatePlan.failed,
    (state): Reducer => ({
      ...state,
      isPlanUpdating: false
    })
  )
  .case(
    actions.deletePlan.started,
    (state): Reducer => ({
      ...state,
      isPlanDeleting: true
    })
  )
  .case(
    actions.deletePlan.done,
    (state): Reducer => ({
      ...state,
      isPlanDeleting: false,
      plan: null
    })
  )
  .case(
    actions.deletePlan.failed,
    (state): Reducer => ({
      ...state,
      isPlanDeleting: false
    })
  )
  .case(actions.clear, (): Reducer => initialState);
