import {
  Box,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
  Switch
} from "@mui/material";
import { t } from "i18next";
import React, { FC } from "react";
import { InputField, RestoreConfigContext } from "../tools";
import { SelfwashDeviceOptions, SelfwashUnmount } from "./SelfwashSelection";
import { Dropdown } from "components/common/Dropdown";

const deviceUnmounts: {
  [x: string]: (
    config: Record<string, unknown>,
    carwashConfig: Record<string, unknown>
  ) => void;
} = {
  selfwash: SelfwashUnmount,
  carwash: CarwashUnmount,
  voucherpay: VoucherPayUnmount,
  cr214_api: PrinterLCPUnmount
};

export const DeviceSelection: FC<{
  device: [string, React.Dispatch<React.SetStateAction<string>>];
}> = (props) => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }

  if (!restoreConfig.carwashConfig) {
    throw TypeError("Carwash Config not defined in context");
  }

  const [device, setDevice] = props.device;
  const [config, setConfig] = restoreConfig.config;
  const [carwashConfig] = restoreConfig.config;

  return (
    <>
      <Box sx={{ width: "95%" }}>
        <h2>Device Selection</h2>
        <Dropdown
          name="device-select"
          label={t("restoreConfigToolPage.dropdowns.selectDevice")}
          value={device}
          onChange={(event: SelectChangeEvent) => {
            if (device !== "") {
              deviceUnmounts[device](config, carwashConfig);
            }

            if (event.target.value != "") {
              config[event.target.value] = { active: true };
            }

            setDevice(event.target.value);
            setConfig({ ...config });
          }}
          items={{
            "": { name: t("restoreConfigToolPage.dropdowns.selectDevice") },
            selfwash: { name: "Selfwash" },
            carwash: { name: "Carwash (web-api)" },
            voucherpay: { name: "Voucherpay" },
            cr214_api: { name: "PrinterLCP" }
          }}
        />
        <ShowDeviceOptions />
      </Box>
    </>
  );

  function ShowDeviceOptions() {
    switch (device) {
      case "selfwash":
        return <SelfwashDeviceOptions />;
      case "carwash":
        return <CarwashDeviceOptions />;
      case "voucherpay":
        return <VoucherPayDeviceOptions />;
      case "cr214_api":
        return <PrinterLCPDeviceOptions />;
      default:
        return <></>;
    }
  }
};

function CarwashUnmount(
  config: Record<string, unknown>,
  carwashConfig: Record<string, unknown>
) {
  delete config["carwash"];
}

const CarwashDeviceOptions: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  if (!restoreConfig.carwashConfig) {
    throw TypeError("CarwashConfig not defined in context");
  }

  const [config, setConfig] = restoreConfig.config;
  const [carwashConfig, setCarwashConfig] = restoreConfig.carwashConfig;

  const configCarwash: Record<string, unknown> = config["carwash"] as Record<
    string,
    unknown
  >;

  const mode = (configCarwash["mode"] as string) || "";
  const version = (configCarwash["webapi_version"] as string) || "";

  const queue_size = configCarwash["queue_size"]
    ? (configCarwash["queue_size"] as number).toString()
    : "";

  const simulator = (configCarwash["simulator"] as boolean) || false;
  const url = (configCarwash["url"] as string) || "";
  const auth = (configCarwash["auth"] as string) || "";

  const vehicleRestrictions = carwashConfig["vehicle_restrictions"]
    ? true
    : false;

  return (
    <Box sx={{ width: "95%", marginLeft: "5%" }}>
      <Dropdown
        name="webapi_version"
        label={"WebApi version"}
        value={version}
        onChange={(event: SelectChangeEvent) => {
          if (event.target.value === "") {
            delete configCarwash["webapi_version"];
          } else {
            configCarwash["webapi_version"] = event.target.value;
          }

          setConfig({ ...config });
        }}
        items={{
          "": {
            name: "Default"
          },
          v1: {
            name: "Version 1.x.x"
          },
          v2: {
            name: "Version 2.x.x"
          }
        }}
      />
      <Dropdown
        name="mode"
        label={"Carwash Mode"}
        value={mode}
        onChange={(event: SelectChangeEvent) => {
          configCarwash["mode"] = event.target.value;

          setConfig({ ...config });
        }}
        items={{
          drive_in: {
            name: "Drive In (you can decide if you stay of you go out)"
          },
          drive_in_out: {
            name: "Drive In (you have to go out of the car)"
          },
          drive_in_stay: {
            name: "Drive In (you have to stay in the car)"
          },
          get_out: {
            name: "Get Out (you have to go out of the car)"
          }
        }}
      />
      <InputField
        name="queue_size"
        label="Queue Size"
        type="number"
        value={queue_size}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          const intValue = parseInt(event.target.value);

          if (isNaN(intValue) === false) {
            if (intValue > 0) {
              configCarwash["queue_size"] = intValue;
              setConfig({ ...config });
            } else {
              alert("Queue Size has to be greater or equal to 1");
            }
          } else {
            delete configCarwash["queue_size"];
            setConfig({ ...config });
          }
        }}
      />
      <FormGroup>
        <FormControlLabel
          label="Simulator"
          control={
            <Switch
              color="success"
              checked={simulator}
              onChange={() => {
                // on change the value of state is the same as before so I have to check if the new state, that is set afterwards will have the correct value
                if (simulator == false) {
                  configCarwash["simulator"] = true;
                  configCarwash["url"] = "http://localhost/carwash-simulator";
                  configCarwash["auth"] = "";
                } else {
                  delete configCarwash["simulator"];
                  delete configCarwash["url"];
                  delete configCarwash["auth"];
                }

                setConfig({ ...config });
              }}
            />
          }
        />
      </FormGroup>
      {simulator === false && (
        <>
          <InputField
            name="url"
            label="WebApi URL"
            value={url}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              if (event.target.value != "") {
                configCarwash["url"] = event.target.value;
              } else {
                delete configCarwash["url"];
              }
              setConfig({ ...config });
            }}
          />
          <InputField
            name="auth"
            label="WebApi Auth"
            value={auth}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              if (event.target.value != "") {
                configCarwash["auth"] = event.target.value;
              } else {
                delete configCarwash["auth"];
              }
              setConfig({ ...config });
            }}
          />
        </>
      )}

      <FormGroup>
        <FormControlLabel
          label="Vehicle Restrictions"
          control={
            <Switch
              color="success"
              checked={vehicleRestrictions}
              onChange={() => {
                // on change the value of state is the same as before so I have to check if the new state, that is set afterwards will have the correct value
                if (vehicleRestrictions == false) {
                  carwashConfig["vehicle_restrictions"] = [];
                } else {
                  delete carwashConfig["vehicle_restrictions"];
                }

                setConfig({ ...config });
              }}
            />
          }
        />
      </FormGroup>
      {vehicleRestrictions === true && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          This config is not available right now, please add it yourself
          manually.
          <br />
          <a
            target="blank"
            href="https://tsg-germany.atlassian.net/wiki/spaces/TSG/pages/598867981/Available+Configurations#Vehicle-selection-before-program-selection"
          >
            Documentation
          </a>
        </Box>
      )}
    </Box>
  );
};

function VoucherPayUnmount(
  config: Record<string, unknown>,
  carwashConfig: Record<string, unknown>
) {
  delete config["voucherpay"];
}

const VoucherPayDeviceOptions: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  // on mount
  React.useEffect(() => {
    if (!config["voucherpay"]) {
      return;
    }
    if (!(config["voucherpay"] as Record<string, unknown>)["service"]) {
      (config["voucherpay"] as Record<string, unknown>)["service"] = "ccp";
      setConfig({ ...config });
    }
  }, []);

  return <></>;
};

function PrinterLCPUnmount(
  config: Record<string, unknown>,
  carwashConfig: Record<string, unknown>
) {
  delete config["cr214_api"];

  Object.keys(config)
    .filter((key) => key.split(".")[0] === "restaurant_printer")
    .map((key) => delete config[key]);
}

const PrinterLCPDeviceOptions: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  React.useEffect(() => {
    let configChanged = false;
    if (!("server.get_voucher_definitions" in config)) {
      config["server.get_voucher_definitions"] =
        "https://charlie.tokheimsolutions.com/api/v1/voucher/definitions";
      configChanged = true;
    }

    if (!("server.create_voucher_from_definition" in config)) {
      config["server.create_voucher_from_definition"] =
        "https://charlie.tokheimsolutions.com/api/v1/voucher/create";
      configChanged = true;
    }

    if (!("server.deactivate_voucher" in config)) {
      config["server.deactivate_voucher"] =
        "https://charlie.tokheimsolutions.com/api/v1/voucher/deactivate";
      configChanged = true;
    }

    if (configChanged) {
      setConfig({ ...config });
    }
  }, []);

  return (
    <Box sx={{ width: "95%", marginLeft: "5%" }}>
      <InputField
        name="restaurant_printer.logo"
        label="Logo"
        value={(config["restaurant_printer.logo"] as string) || ""}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          config["restaurant_printer.logo"] = event.target.value;

          if (event.target.value == "") {
            delete config["restaurant_printer.logo"];
          }

          setConfig({ ...config });
        }}
      />
      <InputField
        name="restaurant_printer.suffic_text"
        label="Suffix Text"
        value={(config["restaurant_printer.suffix_text"] as string) || ""}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          config["restaurant_printer.suffix_text"] = event.target.value;

          if (event.target.value == "") {
            delete config["restaurant_printer.suffix_text"];
          }

          setConfig({ ...config });
        }}
      />
    </Box>
  );
};
