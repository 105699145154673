import { CalendarEvent, EventType } from "components/common/Calendar/types";

export enum DIALOG_TYPES {
    CREATE,
    IMPORT,
    EDIT
}

export type SelectedEvent = {
    title: string;
    start: Date;
    end: Date;
    allDay: boolean;
    calendarEvent: CalendarEvent;
}

export type DialogEvent = {
    title?: string;
    start?: Date;
    end?: Date;
    allDay: boolean;
    type?: EventType;
    percentage: number;
    recurrency: boolean;
    endCondition?: "count" | "date" | "never";
    count?: number;
    until?: Date;
    interval?: number;
    frequency?: "day" | "week" | "month" | "year";
}

export type CheckedDialogEvent = {
    title: string;
    start: Date;
    end: Date;
    allDay: boolean;
    type: EventType;
    percentage?: number;
    recurrency: boolean;
    endCondition: "count" | "date" | "never";
    count: number;
    until: Date;
    interval: number;
    frequency: "day" | "week" | "month" | "year";
}

export type ImportEvent = {
    file: File[];
    type: EventType;
    percentage?: number;
}

export type ICSFileEvent = {
    type: string;
    start: Date;
    end: Date;
    summary: string;
    "event-type": string;
    allDay?: boolean;
    rrule?: string;
    exdate?: { [day: string]: Date };
}

export type ICSFile = {
    [uid: string]: ICSFileEvent;
}

export type RRule = {
    interval?: string;
    freq?: string;
    until?: string;
    count?: string;
}