import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Typography } from "@mui/material";
import { FC } from "react";
import * as s from "../../common/Table/styles";
import { useTranslation } from "react-i18next";
import { Terminal } from "../../../store/modules/terminals/types";
import { StationTerminals } from "../../../store/modules/stations/types";
import { PayMethodTypeKeyNameMap, Status } from "./types";
import { Date, Time } from "components/common/DateTime";

const localeMap = {
  en: "en-GB",
  de: "de-DE"
};
const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
};
const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
};
export const DateComponent: FC<{ date: Date }> = ({ date }) => {
  const [t, i18n] = useTranslation();

  const dateString = date.toLocaleDateString(
    localeMap[i18n.language],
    dateOptions
  );
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <s.TableCellText title={dateString} sx={{ textAlign: "center" }}>
        <Date datetime={date} />
      </s.TableCellText>
    </Box>
  );
};

export const TimeComponent: FC<{ date: Date }> = ({ date: time }) => {
  const [t, i18n] = useTranslation();

  const timeString = time.toLocaleTimeString(
    localeMap[i18n.language],
    timeOptions
  );
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <s.TableCellText title={timeString} sx={{ textAlign: "center" }}>
        <Time datetime={time} />
      </s.TableCellText>
    </Box>
  );
};

export const StationComponent: FC<{
  station: StationTerminals | undefined;
}> = ({ station }) => {
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <s.TableCellText title={station?.name ?? ""} sx={{ textAlign: "center" }}>
        {station?.name ?? ""}
      </s.TableCellText>
    </Box>
  );
};

export const TerminalComponent: FC<{ terminal: Terminal | undefined }> = ({
  terminal
}) => {
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <s.TableCellText
        title={terminal?.name ?? ""}
        sx={{ textAlign: "center" }}
      >
        {terminal?.name ?? ""}
      </s.TableCellText>
    </Box>
  );
};

export const PaymentTypeComponent: FC<{ type: string }> = ({ type }) => {
  const [t, i18n] = useTranslation();

  const typeNames: PayMethodTypeKeyNameMap = {
    fleetcard: t("uiComponents.table.headers.type_name.fleetcard"),
    voucher: t("uiComponents.table.headers.type_name.voucher"),
    license_plate: t("uiComponents.table.headers.type_name.license_plate"),
    cash: t("uiComponents.table.headers.type_name.cash"),
    fingerprint: t("uiComponents.table.headers.type_name.fingerprint"),
    mobile: t("uiComponents.table.headers.type_name.mobile"),
    card: t("uiComponents.table.headers.type_name.card")
  };
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <s.TableCellText title={typeNames[type]} sx={{ textAlign: "center" }}>
        {typeNames[type]}
      </s.TableCellText>
    </Box>
  );
};

export const PaymentIdComponent: FC<{ id: string }> = ({ id }) => {
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <s.TableCellText title={id} sx={{ textAlign: "center" }}>
        {id}
      </s.TableCellText>
    </Box>
  );
};

export const AmountComponent: FC<{ amount: number }> = ({ amount }) => {
  const amountString = `${
    amount % 100 == 0 ? amount / 100 : (amount / 100).toFixed(2)
  } €`;

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <s.TableCellText title={amountString} sx={{ textAlign: "center" }}>
        {amountString}
      </s.TableCellText>
    </Box>
  );
};

export const StatusComponent: FC<{ status: string }> = ({ status }) => {
  const getIcon = () => {
    switch (status) {
      case Status.DONE:
        return <CheckCircleOutlineOutlinedIcon color="success" />;
      case Status.PENDING:
        return <PendingOutlinedIcon color="info" />;
      case Status.CANCELLED:
        return <CancelOutlinedIcon color="error" />;
      case Status.FAILED:
        return <ErrorIcon color="error" />;
    }
    return <>{status}</>;
  };

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Typography title={status}>{getIcon()}</Typography>
    </Box>
  );
};
