import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { KeycloakUser } from "./types";

export type Reducer = {
  isLoggingOut: boolean;
  isUserLoading: boolean;
  user: KeycloakUser | null;
};

const initialState: Reducer = {
  isLoggingOut: false,
  isUserLoading: false,
  user: null
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getUserDetails.started,
    (state): Reducer => ({
      ...state,
      isUserLoading: true
    })
  )
  .case(
    actions.getUserDetails.done,
    (state, payload): Reducer => ({
      ...state,
      isUserLoading: false,
      user: payload.result
    })
  )
  .case(
    actions.getUserDetails.failed,
    (state): Reducer => ({
      ...state,
      isUserLoading: false
    })
  )
  .case(
    actions.logout.started,
    (state): Reducer => ({
      ...state,
      isLoggingOut: true
    })
  )
  .case(
    actions.logout.done,
    (state): Reducer => ({
      ...state,
      isLoggingOut: false
    })
  )
  .case(
    actions.logout.failed,
    (state): Reducer => ({
      ...state,
      isLoggingOut: false
    })
  );
