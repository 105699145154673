import { Action } from "typescript-fsa";
import { GetNestedStationsParams, GetNestedStationsResponse, GetStationsParams, GetStationsResponse } from "./types";
import { SagaIterator } from "redux-saga";
import { keycloak } from "../../../keycloak";
import { axiosInstance } from "../../../axios";
import { AxiosResponse } from "axios";
import { all, call, put, takeLeading } from "redux-saga/effects";
import * as actions from "./actions";
import { GetAllTerminalsParams, GetTerminalsResponse } from "../terminals/types";

export function* getStationsSaga(
    action: Action<GetStationsParams>
): SagaIterator<void> {
    try {
        const token = keycloak.idToken?.toString() || "";
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response: AxiosResponse<GetStationsResponse> = yield call(
            axiosInstance.get,
            `stations`,
            {
                params: action.payload
            }
        );
        yield put(
            actions.getStations.done({
                params: action.payload,
                result: response.data
            })
        );
    } catch (e) {
        yield put(
            actions.getStations.failed({ params: action.payload, error: e })
        );
    }
}

export function* getNestedStationsSaga(
    action: Action<GetNestedStationsParams>
): SagaIterator<void> {
    try {
        const token = keycloak.idToken?.toString() || "";
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response: GetNestedStationsResponse = {};
        const stationsResponse: AxiosResponse<GetStationsResponse> = yield call(
            axiosInstance.get,
            `stations`
        );
        for (const station of stationsResponse.data) {
            response[station.id] = {
                ...station,
                terminals: {}
            };

            const terminalResponse: AxiosResponse<GetTerminalsResponse> = yield call(
                axiosInstance.get,
                `stations/${station.id}/terminals`
            );
            for (const terminal of terminalResponse.data) {
                response[station.id].terminals[terminal.id] = terminal;
            }
        }


        yield put(
            actions.getNestedStations.done({
                params: action.payload,
                result: response
            })
        );
    } catch (e) {
        yield put(
            actions.getNestedStations.failed({ params: action.payload, error: e })
        );
    }
}

export function* watcherSaga(): SagaIterator<void> {
    yield all([
        takeLeading(actions.getStations.started, getStationsSaga),
        takeLeading(actions.getNestedStations.started, getNestedStationsSaga)
    ]);
}
