import { reducerWithInitialState } from "typescript-fsa-reducers";
import { GetAllTerminalsResponse } from "./types";
import * as actions from "./actions";


export type Reducer = {
    terminalsResponse: GetAllTerminalsResponse | null;
    areTerminalsLoading: boolean;
};


const initialState: Reducer = {
    terminalsResponse: null,
    areTerminalsLoading: false
};

export const reducer = reducerWithInitialState(initialState)
    .case(
        actions.getAllTerminals.started,
        (state): Reducer => ({
            ...state,
            areTerminalsLoading: true
        })
    )
    .case(
        actions.getAllTerminals.done,
        (state, payload): Reducer => ({
            ...state,
            terminalsResponse: payload.result,
            areTerminalsLoading: false
        })
    )
    .case(
        actions.getAllTerminals.failed,
        (state): Reducer => ({
            ...state,
            areTerminalsLoading: false
        })
    )
    .case(actions.clear, (): Reducer => initialState);
