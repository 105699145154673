import {
  FormControl,
  SxProps,
  TextField
} from "@mui/material";
import React, { FC } from "react";

export const isIpv4 = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;

export const RestoreConfigContext: React.Context<{
  config?: [
    Record<string, unknown>,
    React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  ];
  carwashConfig?: [
    Record<string, unknown>,
    React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  ];
  languages?: [
    Record<string, unknown>,
    React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  ];
}> = React.createContext({});



export const InputField: FC<{
  onBlur: (event: React.FocusEvent<any>) => void;
  name: string;
  label: string;
  sx?: SxProps;
  value?: string;
  fullWidth?: boolean;
  type?: string;
  inputProps?: Record<string, unknown>;
  test?: RegExp;
}> = (props) => {
  const [value, setValue] = React.useState(props.value || "");

  return (
    <FormControl
      fullWidth={props.fullWidth === undefined}
      sx={{ marginTop: "10px", ...props.sx }}
    >
      <TextField
        color={
          props.test
            ? props.test.test(value)
              ? "success"
              : "accent"
            : "primary"
        }
        id={props.name}
        label={props.label}
        value={value}
        type={props.type || "text"}
        inputProps={props.inputProps || {}}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
        }}
        onBlur={props.onBlur}
      />
    </FormControl>
  );
};
