import { useKeycloak } from "@react-keycloak/web";
import { FC } from "react";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../../constants";

export const RequireAuth: FC = ({ children }) => {
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.ROOT,
          state: { from: location }
        }}
      />
    );
  }

  return <>{children}</>;
};
