import actionCreatorFactory from "typescript-fsa";
import {
  CreateProgramParams,
  CreateProgramResponse,
  DeleteProgramParams,
  DeleteProgramResponse,
  GetProgramParams,
  GetProgramResponse,
  GetProgramsParams,
  GetProgramsResponse,
  UpdateProgramParams,
  UpdateProgramResponse
} from "./types";

const actionCreator = actionCreatorFactory("PROGRAMS");

export const getProgram = actionCreator.async<
  GetProgramParams,
  GetProgramResponse,
  unknown
>("GET_PROGRAM");

export const getPrograms = actionCreator.async<
  GetProgramsParams,
  GetProgramsResponse,
  unknown
>("GET_PROGRAMS");

export const createProgram = actionCreator.async<
  CreateProgramParams,
  CreateProgramResponse,
  unknown
>("CREATE_PROGRAM");

export const updateProgram = actionCreator.async<
  UpdateProgramParams,
  UpdateProgramResponse,
  unknown
>("UPDATE_PROGRAM");

export const deleteProgram = actionCreator.async<
  DeleteProgramParams,
  DeleteProgramResponse,
  unknown
>("DELETE_PROGRAM");

export const clear = actionCreator<void>("CLEAR");
