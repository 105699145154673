import actionCreatorFactory from "typescript-fsa";
import {
  AddCreditParams,
  AddCreditResponse,
  CreatePaymentMethodParams,
  CreatePaymentMethodResponse,
  CreateVoucherParams,
  CreateVoucherResponse,
  DeletePaymentMethodParams,
  DeletePaymentMethodResponse,
  GetPaymentMethodParams,
  GetPaymentMethodResponse,
  GetPaymentMethodsParams,
  GetPaymentMethodsResponse,
  UpdatePaymentMethodParams,
  UpdatePaymentMethodResponse
} from "./types";

const actionCreator = actionCreatorFactory("PAY_METHODS");

export const getPaymentMethod = actionCreator.async<
  GetPaymentMethodParams,
  GetPaymentMethodResponse,
  unknown
>("GET_PAYMENT_METHOD");

export const getPaymentMethods = actionCreator.async<
  GetPaymentMethodsParams,
  GetPaymentMethodsResponse,
  unknown
>("GET_PAYMENT_METHODS");

export const createPaymentMethod = actionCreator.async<
  CreatePaymentMethodParams,
  CreatePaymentMethodResponse,
  unknown
>("CREATE_PAYMENT_METHOD");

export const createVoucher = actionCreator.async<
  CreateVoucherParams,
  CreateVoucherResponse,
  unknown
>("CREATE_VOUCHER");

export const updatePaymentMethod = actionCreator.async<
  UpdatePaymentMethodParams,
  UpdatePaymentMethodResponse,
  unknown
>("UPDATE_PAYMENT_METHOD");

export const deletePaymentMethod = actionCreator.async<
  DeletePaymentMethodParams,
  DeletePaymentMethodResponse,
  unknown
>("DELETE_PAYMENT_METHOD");

export const addCredit = actionCreator.async<
  AddCreditParams,
  AddCreditResponse,
  unknown
>("ADD_CREDIT");

export const clear = actionCreator<void>("CLEAR");
