import { RootReducer } from "../../reducers";
import { NestedStations, StationMap } from "./types";

export const stationsSelector = (
    state: RootReducer
): StationMap => {
    const map: StationMap = {};

    for (const station of state.stations.stationsResponse ?? []) {
        map[station.id] = station;
    }

    return map;
}

export const nestedStationsSelector = (
    state: RootReducer
): NestedStations | null => state.stations.nestedStationsResponse;
