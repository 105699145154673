export type PayMethodTypeKeyNameMap = {
    fleetcard: string;
    voucher: string;
    license_plate: string;
    cash: string;
    fingerprint: string;
    mobile: string;
    card: string;
}


export enum Status {
    DONE = "DONE",
    PENDING = "PENDING",
    CANCELLED = "CANCELLED",
    FAILED = "FAILED"
}


export enum DIALOG_TYPES {
    SHOW_DETAILS
}