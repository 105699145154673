import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from "../src/translations/en/translation.json";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import de from "../src/translations/de/translation.json";

export const defaultNS = "en";
export const resources = {
  en: { translation: en },
  de: { translation: de }
} as const;

void i18n.use(initReactI18next).init({
  resources,
  lng: "en"
});

export default i18n;
