import { ButtonGroup } from '@mui/material';
import { Calendar as CalendarComp } from 'react-big-calendar';
import styled from 'styled-components';

import "react-big-calendar/lib/css/react-big-calendar.css";

export const Calendar = styled(CalendarComp)`
  .rbc-month-view, .rbc-time-view {
    border-radius: 6px;
    border-color: ${({ theme }) => theme.palette.calendar.border};
    * {
      border-color: ${({ theme }) => theme.palette.calendar.border} !important;
    }
  }

  .rbc-off-range-bg {
    background-color: ${({ theme }) => theme.palette.calendar.secondary};
  }

  .rbc-off-range-bg.rbc-selected-cell {
    background-color: ${({ theme }) => theme.palette.calendar.secondarySelected};
  }

  .rbc-selected-cell {
    background-color: ${({ theme }) => theme.palette.calendar.primarySelected};
  }

  .rbc-event {
    background-color: rgba(0,0,0,0);
    padding: 0px;
  }

  .rbc-event-content {
    white-space: pre-wrap;
  }

  .rbc-today {
    background-color: ${({ theme }) => theme.palette.calendar.today};
  }

  .rbc-today.rbc-selected-cell {
    background-color: ${({ theme }) => theme.palette.calendar.todaySelected};
  }

  .rbc-time-content > .rbc-today {
    background-color: inherit;
  }

  .rbc-event.rbc-selected {
    background-color: inherit;
  }

  .rbc-event:focus {
    background-color: ${({ theme }) => theme.palette.calendar.events.focus};
    outline: 2px dashed ${({ theme }) => theme.palette.calendar.events.focusOutline};
  }
`


export const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const EventWrapper = styled.div`
  overflow: wrap;
  padding: 7px;
  border-radius: 5px;
  height: 100%
`

export const NavButtonGroup = styled(ButtonGroup)`
  background-color: ${({ theme }) => theme.palette.primary.main};

  button {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    border-color: ${({ theme }) => theme.palette.secondary.main};

    &:hover {
      border-color: ${({ theme }) => theme.palette.secondary.lighter};
    }
  }
  
  .today-btn {
    svg {
      fill: #f46835;
    }
  }
`

