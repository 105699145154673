import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch
} from "@mui/material";
import React, { FC } from "react";
import { Buffer } from "buffer";

import { InputField, RestoreConfigContext } from "./tools";

interface Network {
  name: string;
  metric: number;
  dhcp: boolean;
  netmask: string;
  dns: [string, string];
  ip_address: string;
  gateway: string;
}

export const GeneralSelection: FC = () => {
  return (
    <Box sx={{ width: "95%" }}>
      <h2>General Selection</h2>
      <NetworkInterfaces />
      <CCPServerSettings />
      <LCCServerSettings />
      <LanguageFlagSettings />
    </Box>
  );
};

const NetworkInterfaces: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }

  const [config, setConfig] = restoreConfig.config;

  const networkSwitch = config["device.network_interfaces"] ? true : false;

  const newInterface: () => Network = () => ({
    name: "",
    metric: 0,
    dhcp: true,
    netmask: "",
    dns: ["", ""],
    ip_address: "",
    gateway: ""
  });

  const mainInterface: Network = Array.isArray(
    config["device.network_interfaces"]
  )
    ? config["device.network_interfaces"][0]
    : newInterface();

  const secondaryInterfaces: Network[] = Array.isArray(
    config["device.network_interfaces"]
  )
    ? config["device.network_interfaces"].slice(1) // get subarray from second element to end
    : [];

  const NetworkInterfaceInput: FC<{
    name: string;
    network: Network;
    onChange: () => void;
  }> = (props) => {
    const [dhcp, setDhcp] = React.useState(props.network.dhcp);

    return (
      <>
        <h5 style={{ marginBottom: "0px" }}>{props.name}</h5>
        <InputField
          name="network-name"
          label="Name"
          value={props.network.name}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            props.network.name = event.target.value;
            props.onChange();
          }}
        />
        <InputField
          name="network-metric"
          label="Metric"
          value={
            props.network.metric == 0 ? "" : props.network.metric.toString()
          }
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            if (event.target.value == "") {
              props.network.metric = 0;
            } else {
              props.network.metric = parseInt(event.target.value);
            }
            props.onChange();
          }}
        />
        <FormGroup>
          <FormControlLabel
            name="network-dhcp"
            label="DHCP"
            control={
              <Switch
                color="success"
                checked={dhcp}
                onChange={() => {
                  props.network.dhcp = !dhcp;
                  setDhcp(!dhcp);
                  props.onChange();
                }}
              />
            }
          />
        </FormGroup>
        {!dhcp && (
          <>
            <InputField
              name="network-ip"
              label="IP"
              value={props.network.ip_address}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                props.network.ip_address = event.target.value;
                props.onChange();
              }}
            />
            <InputField
              name="network-netmask"
              label="Netmask"
              value={props.network.netmask}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                props.network.netmask = event.target.value;
                props.onChange();
              }}
            />
            <InputField
              name="network-gateway"
              label="Gateway"
              value={props.network.gateway}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                props.network.gateway = event.target.value;
                props.onChange();
              }}
            />
            <InputField
              name="network-primary-dns"
              label="Primary DNS"
              value={props.network.dns[0]}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                props.network.dns[0] = event.target.value;
                props.onChange();
              }}
            />
            <InputField
              name="network-secondary-dns"
              label="Secondary DNS"
              value={props.network.dns[1]}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                props.network.dns[1] = event.target.value;
                props.onChange();
              }}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          label={<h3>Network</h3>}
          control={
            <Switch
              color="success"
              checked={networkSwitch}
              onChange={() => {
                if (networkSwitch) {
                  delete config["device.network_interfaces"];

                  setConfig({ ...config });
                } else {
                  onChange();
                }
              }}
            />
          }
        />
      </FormGroup>
      {networkSwitch && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          <NetworkInterfaceInput
            name="Main Interface"
            network={mainInterface}
            onChange={onChange}
          />

          {secondaryInterfaces.map((item, index) => (
            <Box key={index}>
              <NetworkInterfaceInput
                name={"Secondary Interface " + (index + 1).toString()}
                network={item}
                onChange={onChange}
              />
              <Button
                sx={{ marginTop: "10px" }}
                color="accent"
                variant="outlined"
                onClick={() => {
                  removeSecondaryInterface(index);
                }}
              >
                Remove Interface
              </Button>
            </Box>
          ))}
          <br />
          <Button
            sx={{ marginTop: "10px" }}
            color="success"
            variant="outlined"
            onClick={addSecondaryInterface}
          >
            Create Secondary Interface
          </Button>
        </Box>
      )}
    </>
  );

  // function used in this component

  function onChange() {
    config["device.network_interfaces"] = [
      mainInterface,
      ...secondaryInterfaces
    ];

    setConfig({ ...config });
  }

  function addSecondaryInterface() {
    secondaryInterfaces.push(newInterface());
    onChange();
  }

  function removeSecondaryInterface(index: number) {
    secondaryInterfaces.splice(index, 1);
    onChange();
  }
};

const CCPServerSettings: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }

  const [config, setConfig] = restoreConfig.config;

  const serverSwitch =
    typeof config["server.api_key"] === "string" ? true : false;

  const api_key =
    typeof config["server.api_key"] === "string"
      ? config["server.api_key"]
      : "";

  const charlieAPI =
    typeof config["server.charlie_api_url"] === "string"
      ? config["server.charlie_api_url"]
      : "";

  const charlieApiDepending = {
    license_plate_fueling: "api/v1/licenseplate",
    voucher: "api/v1/voucher",
    safedrop: "api/v1/safedrop/withdraw",
    voucher_verify: "api/v1/voucher/verify",
    fingerprint_check: "api/v1/fingerprint/check",
    charliepay_voucher: "api/v1/charliepay/voucher",
    cancel_transaction: "api/v1/transaction/cancel",
    sensor_alert: "api/v1/sensorhub/alert",
    voucher_release: "api/v1/voucher/release",
    finish_transaction: "api/v1/transaction/finish",
    device_notification: "api/v2/notification",
    mail: "api/v1/email/send",
    get_voucher: "api/v2/getvoucher",
    voucher_discounts: "api/v1/voucher/discounts",
    link_license_plate: "api/v1/voucher/link/licenseplate",
    fingerprint_register: "api/v1/fingerprint/register",
    notification: "api/v1/notification",
    heartbeat: "api/v2/heartbeat",
    get_voucher_definitions: "api/v1/voucher/definitions",
    create_voucher_from_definition: "api/v1/voucher/create",
    deactivate_voucher: "api/v1/voucher/deactivate"
  };

  function deleteServerConfig() {
    Object.keys(config)
      .filter((key) => key.split(".")[0] === "server")
      .map((key) => delete config[key]);

    setConfig({ ...config });
  }

  function changeServerConfig(serverConfig: Record<string, unknown>) {
    for (const [key, value] of Object.entries(serverConfig)) {
      config["server." + key] = value;
    }

    setConfig({ ...config });
  }

  return (
    <>
      <FormGroup>
        <FormControlLabel
          label={<h3>CCPServer</h3>}
          control={
            <Switch
              color="success"
              checked={serverSwitch}
              onChange={() => {
                if (serverSwitch) {
                  deleteServerConfig();
                } else {
                  changeServerConfig({
                    api_key: "",
                    charlie_api_url: ""
                  });
                }
              }}
            />
          }
        />
      </FormGroup>
      {serverSwitch && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          <InputField
            name="api-key"
            label="API Key"
            value={api_key}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              const serverConfig = {};

              serverConfig["api_key"] = event.target.value;

              changeServerConfig(serverConfig);
            }}
          />
          <InputField
            name="chalie-api-url"
            label="Charlie Api Url"
            value={charlieAPI}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              const serverConfig = {};

              let charlie_api_url: string = event.target.value;

              serverConfig["charlie_api_url"] = charlie_api_url;

              if (charlie_api_url[charlie_api_url.length - 1] != "/") {
                charlie_api_url += "/";
              }

              // change all setting that need this url...
              for (const [key, value] of Object.entries(charlieApiDepending)) {
                serverConfig[key] = charlie_api_url + value;
              }

              changeServerConfig(serverConfig);
            }}
          />
        </Box>
      )}
    </>
  );
};

interface LCCConfig {
  base_url: string;
  headers: {
    Authorization: string;
    [x: string]: string;
  };
  external_comit_types: string[];
  ignore_fleetcards: string[];
  article_ids: Record<string, string>;
}

const lccCredentials = { username: "", password: "" };

const LCCServerSettings: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }

  const [config, setConfig] = restoreConfig.config;

  const serverSwitch: boolean = "external_server" in config;

  const lccServerConfig = config["external_server"] as LCCConfig;

  function createAuthorizationHeader() {
    const encodedCredentials = Buffer.from(
      `${lccCredentials["username"]}:${lccCredentials["password"]}`
    ).toString("base64");
    lccServerConfig["headers"]["Authorization"] = `Basic ${encodedCredentials}`;

    setConfig({ ...config });
  }

  return (
    <>
      <FormGroup>
        <FormControlLabel
          label={<h3>LCCServer</h3>}
          control={
            <Switch
              color="success"
              checked={serverSwitch}
              onChange={() => {
                if (serverSwitch) {
                  delete config["external_server"];
                  lccCredentials["username"] = "";
                  lccCredentials["password"] = "";
                } else {
                  config["external_server"] = {
                    base_url: "https://terminal-api.mytsg.digital/",
                    headers: {
                      Authorization: "Basic xxxxxxxxxx"
                    },
                    external_commit_types: ["card", "voucher"],
                    ignore_fleetcards: ["0801", "0102"],
                    article_ids: {
                      "1": "125",
                      "2": "251"
                    }
                  };
                }
                setConfig({ ...config });
              }}
            />
          }
        />
      </FormGroup>
      {serverSwitch && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          <InputField
            name="lcc-username"
            label="Username"
            value={lccCredentials["username"]}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              lccCredentials["username"] = event.target.value;
              createAuthorizationHeader();
            }}
          />
          <InputField
            name="lcc-password"
            label="Password"
            type="password"
            value={lccCredentials["password"]}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              lccCredentials["password"] = event.target.value;

              createAuthorizationHeader();
            }}
          />
          <InputField
            name="lcc-url"
            label="LCC Terminal Url"
            value={lccServerConfig["base_url"] || ""}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              lccServerConfig["base_url"] = event.target.value;

              setConfig({ ...config });
            }}
          />
        </Box>
      )}
    </>
  );
};

const LanguageFlagSettings: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.carwashConfig) {
    throw TypeError("CarwashConfig not defined in context");
  }

  const [carwashConfig, setCarwashConfig] = restoreConfig.carwashConfig;

  const languageFlagSwitch = "location" in carwashConfig;

  return (
    <>
      <FormGroup>
        <FormControlLabel
          label={<h3>Language Flag selection</h3>}
          control={
            <Switch
              color="success"
              checked={languageFlagSwitch}
              onChange={() => {
                if (languageFlagSwitch) {
                  delete carwashConfig["location"];
                } else {
                  carwashConfig["location"] = {};
                }
                setCarwashConfig({ ...carwashConfig });
              }}
            />
          }
        />
      </FormGroup>
      {languageFlagSwitch && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          There is still the need to select this setting manually. If you want
          to add it, look{" "}
          <a
            href="https://tsg-germany.atlassian.net/wiki/spaces/TSG/pages/598867981/Available+Configurations#--Use-different-flag-for-language-(example-Austrian-flag-for-German)"
            target="blank"
          >
            here
          </a>
          .
        </Box>
      )}
    </>
  );
};
