import {
  Box,
  SelectChangeEvent,
  FormGroup,
  FormControlLabel,
  Switch
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import { FC } from "react";
import { InputField, RestoreConfigContext } from "../tools";
import { Dropdown } from "components/common/Dropdown";

export function SelfwashUnmount(
  config: Record<string, unknown>,
  carwashConfig: Record<string, unknown>
) {
  const configKeyDelete = [
    "selfwash",
    "i2c.fake_inputs",
    "i2c.external_program_credit", // bock or christ device
    "siemens", // aircontrol
    // credit
    "i2c.credit_on_duration",
    "i2c.credit_off_duration",
    "i2c.credit_multiplier",
    "i2c.credit_pulse_value",
    "i2c.pause_between_programs",
    "i2c.max_led_to_accept_coin" // bock exclusive
  ];
  const carwashConfigKeyDelete = [
    "base_config.blackscreen_screensaver", // blackscreen when out of service
    "base_config.ticket_enabled", // ticket and delivery note settings
    "base_config.delivery_note_url" // ticket and delivery note settings
  ];

  for (const key of configKeyDelete) {
    if (key in config) {
      delete config[key];
    }
  }

  for (const key of carwashConfigKeyDelete) {
    if (key in carwashConfig) {
      delete carwashConfig[key];
    }
  }
}

export const SelfwashDeviceOptions: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  if (!restoreConfig.carwashConfig) {
    throw TypeError("CarwashConfig not defined in context");
  }
  const [carwashConfig, setCarwashConfig] = restoreConfig.carwashConfig;

  const manufacturerLabel = t(
    "restoreConfigToolPage.dropdowns.selectManufacturer"
  );

  const modeLabel = t("restoreConfigToolPage.selfwash.mode.selectMode");

  const selfwashConfig = config["selfwash"] as Record<string, string>;

  const mode = selfwashConfig["mode"] || "";
  const manufacturer = selfwashConfig["manufacturer"] || "";
  const inductionLine = selfwashConfig["induction_line"] || "";
  const afterPayPage = selfwashConfig["after_pay_page"] || "";

  const blackscreenScreensaver =
    (carwashConfig["base_config.blackscreen_screensaver"] as boolean) || false;

  const ticketNote = carwashConfig["base_config.ticket_enabled"] ? true : false;

  React.useEffect(() => {
    let changedConfig = false;

    // manufaturer is either bock or christ
    if (["bock", "christ"].filter((el) => manufacturer == el).length === 0) {
      if ("i2c.external_program_credit" in config) {
        delete config["i2c.external_program_credit"];
        changedConfig = true;
      }
    } else {
      if (!("i2c.external_program_credit" in config)) {
        config["i2c.external_program_credit"] = true;
        changedConfig = true;
      }
    }

    if (manufacturer === "siemens") {
      if (selfwashConfig["mode"] !== "vacuum") {
        selfwashConfig["mode"] = "vacuum";
        changedConfig = true;
      }

      if (!config["siemens"]) {
        config["siemens"] = {};
        changedConfig = true;
      }
    } else {
      if (selfwashConfig["mode"] === "vacuum") {
        delete selfwashConfig["mode"];
        changedConfig = true;
      }

      if (config["siemens"]) {
        delete config["siemens"];
        changedConfig = true;
      }
    }

    if (changedConfig) {
      setConfig({ ...config });
    }
  }, [manufacturer]);

  // on unmount
  React.useEffect(
    () => () => {
      SelfwashUnmount(config, carwashConfig);
    },
    []
  );

  return (
    <Box sx={{ width: "95%", marginLeft: "5%" }}>
      <Dropdown
        name="manufacturer"
        label={manufacturerLabel}
        value={manufacturer}
        onChange={(event: SelectChangeEvent) => {
          (config["selfwash"] as Record<string, unknown>)["manufacturer"] =
            event.target.value;

          setConfig({ ...config });
        }}
        items={{
          kärcher: {
            name: "Kärcher"
          },
          bock: {
            name: "Bock"
          },
          christ: {
            name: "Christ"
          },
          siemens: {
            name: "Aircontrol"
          }
        }}
      />
      {manufacturer === "kärcher" && <KaercherOptions />}
      {manufacturer === "bock" && <BockOptions />}
      {manufacturer === "christ" && <ChristOptions />}
      {manufacturer === "siemens" && config["siemens"] && <SiemensOptions />}
      {manufacturer !== "siemens" && (
        <Dropdown
          name="mode"
          label={modeLabel}
          value={mode}
          onChange={(event: SelectChangeEvent) => {
            (config["selfwash"] as Record<string, unknown>)["mode"] =
              event.target.value;

            setConfig({ ...config });
          }}
          items={{
            default: {
              name: t("restoreConfigToolPage.selfwash.mode.default")
            },
            preauth: {
              name: t("restoreConfigToolPage.selfwash.mode.preauth.name")
            },
            vacuum: {
              name: "Vacuum (only available with AirControl)"
            }
          }}
        />
      )}
      {mode == "preauth" && <PreAuthAmounts />}

      <FormGroup>
        <FormControlLabel
          label="Blackscreen Screensaver"
          control={
            <Switch
              color="success"
              checked={blackscreenScreensaver}
              onChange={() => {
                if (blackscreenScreensaver == false) {
                  carwashConfig["base_config.blackscreen_screensaver"] = true;
                } else {
                  delete carwashConfig["base_config.blackscreen_screensaver"];
                }

                setCarwashConfig({ ...carwashConfig });
              }}
            />
          }
        />
      </FormGroup>
      <InputField
        name={"base_config.interface_check_timeout"}
        test={/^\d+$/}
        label={"Checking interface status regularly"}
        value={
          carwashConfig["base_config.interface_check_timeout"]
            ? (
              carwashConfig["base_config.interface_check_timeout"] as number
            ).toString()
            : ""
        }
        type="number"
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          const intValue = parseInt(event.target.value);

          if (isNaN(intValue) === false && intValue > 0) {
            carwashConfig["base_config.interface_check_timeout"] = intValue;
          } else {
            delete carwashConfig["base_config.interface_check_timeout"];
          }
          setCarwashConfig({ ...carwashConfig });
        }}
      />
      <FormGroup>
        <FormControlLabel
          label="Ticket and Delivery Note"
          control={
            <Switch
              color="success"
              checked={ticketNote}
              onChange={() => {
                if (ticketNote == false) {
                  carwashConfig["base_config.ticket_enabled"] = {
                    nfc: "ticket",
                    voucher: "ticket",
                    fleetcard: "note",
                    coin: "ticket" //Attention, here is coin, not cash!
                  };
                  carwashConfig["base_config.delivery_note_url"] =
                    "https://mycharlie.com/transaction/{ticket}/note/";
                } else {
                  delete carwashConfig["base_config.ticket_enabled"];
                  delete carwashConfig["base_config.delivery_note_url"];
                }

                setCarwashConfig({ ...carwashConfig });
              }}
            />
          }
        />
      </FormGroup>
      {ticketNote && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          The Ticket and Delivery Note setting was set to a default value, if
          you want to change it, you have to change it manually for reference
          look{" "}
          <a
            href="https://tsg-germany.atlassian.net/wiki/spaces/TSG/pages/598867981/Available+Configurations#--Ticket-and-Delivery-Note"
            target="blank"
          >
            here
          </a>
          .
        </Box>
      )}
      <Dropdown
        name="induction_line"
        label={"Induction Line"}
        value={inductionLine}
        onChange={(event: SelectChangeEvent) => {
          if (event.target.value === "") {
            delete selfwashConfig["induction_line"];
          } else {
            selfwashConfig["induction_line"] = event.target.value;
          }

          setConfig({ ...config });
        }}
        items={{
          "": {
            name: "None"
          },
          simple: {
            name: "Simple"
          },
          presence: {
            name: "Presence"
          }
        }}
      />
      <CreditOptions />
      <Dropdown
        name="after_pay_page"
        label={"After Pay Page"}
        value={afterPayPage}
        onChange={(event: SelectChangeEvent) => {
          if (event.target.value === "") {
            delete selfwashConfig["after_pay_page"];
          } else {
            selfwashConfig["after_pay_page"] = event.target.value;
          }

          setConfig({ ...config });
        }}
        items={{
          "": {
            name: "None"
          },
          "enter-email": {
            name: "Enter Email"
          },
          "vehicle-selection": {
            name: "Vehicle Selection"
          },
          ticket: {
            name: "Ticket"
          }
        }}
      />
    </Box>
  );
};

function PreAuthAmounts() {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  const keyPrefix = "auth_amount_";

  const preAuthOptions = ["voucher", "nfc", "license_plate"];

  const labels = {
    voucher: t("restoreConfigToolPage.selfwash.mode.preauth.amounts.voucher"),
    nfc: t("restoreConfigToolPage.selfwash.mode.preauth.amounts.nfc"),
    license_plate: t(
      "restoreConfigToolPage.selfwash.mode.preauth.amounts.license_plate"
    )
  };

  return (
    <Box sx={{ width: "95%", marginLeft: "5%" }}>
      {preAuthOptions.map((item, index) => {
        return (
          <InputField
            key={index}
            name={keyPrefix + item}
            test={/^\d+$/}
            label={labels[item]}
            value={
              (config["selfwash"] as Record<string, number>)[keyPrefix + item]
                ? (config["selfwash"] as Record<string, number>)[
                  keyPrefix + item
                ].toString()
                : ""
            }
            type="number"
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              const key = keyPrefix + item;
              const intValue = parseInt(event.target.value);

              const c = { ...config };

              if (isNaN(intValue) === false) {
                (c["selfwash"] as Record<string, unknown>)[key] = intValue;
              } else {
                delete (c["selfwash"] as Record<string, unknown>)[key];
              }
              setConfig(c);
            }}
          />
        );
      })}
    </Box>
  );
}

const KaercherOptions: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  return <></>;
};

const BockOptions: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  const fakeInputs = (config["i2c.fake_inputs"] as boolean) || false;

  return (
    <Box sx={{ width: "95%", marginLeft: "5%" }}>
      <FormGroup>
        <FormControlLabel
          label="Fake Inputs"
          control={
            <Switch
              color="success"
              checked={fakeInputs}
              onChange={() => {
                if (fakeInputs == false) {
                  config["i2c.fake_inputs"] = true;
                } else {
                  delete config["i2c.fake_inputs"];
                }

                setConfig({ ...config });
              }}
            />
          }
        />
      </FormGroup>
      <InputField
        name={"i2c.max_led_to_accept_coin"}
        test={/^\d+$/}
        label={"Credit Max LED to accept coin"}
        value={
          config["i2c.max_led_to_accept_coin"]
            ? (config["i2c.max_led_to_accept_coin"] as number).toString()
            : ""
        }
        type="number"
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          const intValue = parseInt(event.target.value);

          if (isNaN(intValue) === false && intValue > 0) {
            config["i2c.max_led_to_accept_coin"] = intValue;
          } else {
            delete config["i2c.max_led_to_accept_coin"];
          }
          setConfig({ ...config });
        }}
      />
    </Box>
  );
};

const ChristOptions: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  return <></>;
};

interface SiemensConfig {
  mock_sps?: boolean;
  sps_version?: number;
  address: {
    ip: string;
  };
  database: number;
  program_types: {
    [name: string]: number;
  };
  wash_times: {
    [type: string]: {
      [amount: string]: number;
    };
  };
  pulse_amount?: boolean;
  input_bytes: {
    [x: string]: unknown;
  };
  output_bytes: {
    [x: string]: unknown;
  };
}

const siemensVersion2DefaultConfig: SiemensConfig = {
  address: {
    ip: "10.198.2.237"
  },
  database: 2,
  program_types: {
    default: 1,
    program_4: 2
  },
  wash_times: {
    type_1: {
      amount_50: 130,
      amount_100: 190,
      amount_150: 250,
      amount_200: 330
    },
    type_2: {
      amount_50: 120,
      amount_100: 240,
      amount_150: 480,
      amount_200: 560
    }
  },
  input_bytes: {
    last_byte: 14,
    program_1: {
      amount_50: {
        byte_index: 0,
        bit_index: 0
      },
      amount_100: {
        byte_index: 4,
        bit_index: 0
      },
      amount_150: {
        byte_index: 8,
        bit_index: 0
      },
      amount_200: {
        byte_index: 12,
        bit_index: 0
      }
    },
    program_2: {
      amount_50: {
        byte_index: 0,
        bit_index: 1
      },
      amount_100: {
        byte_index: 4,
        bit_index: 1
      },
      amount_150: {
        byte_index: 8,
        bit_index: 1
      },
      amount_200: {
        byte_index: 12,
        bit_index: 1
      }
    },
    program_3: {
      amount_50: {
        byte_index: 0,
        bit_index: 2
      },
      amount_100: {
        byte_index: 4,
        bit_index: 2
      },
      amount_150: {
        byte_index: 8,
        bit_index: 2
      },
      amount_200: {
        byte_index: 12,
        bit_index: 2
      }
    },
    program_4: {
      amount_50: {
        byte_index: 0,
        bit_index: 3
      },
      amount_100: {
        byte_index: 4,
        bit_index: 3
      },
      amount_150: {
        byte_index: 8,
        bit_index: 3
      },
      amount_200: {
        byte_index: 12,
        bit_index: 3
      }
    }
  },
  output_bytes: {
    last_byte: 26,
    places: {
      program_1: {
        byte_index: 16,
        bit_index: 0
      },
      program_2: {
        byte_index: 16,
        bit_index: 1
      },
      program_3: {
        byte_index: 16,
        bit_index: 2
      },
      program_4: {
        byte_index: 16,
        bit_index: 3
      }
    },
    accept: {
      program_1: {
        byte_index: 24,
        bit_index: 0
      },
      program_2: {
        byte_index: 24,
        bit_index: 1
      },
      program_3: {
        byte_index: 24,
        bit_index: 2
      },
      program_4: {
        byte_index: 24,
        bit_index: 3
      }
    },
    error: {
      program_1: {
        byte_index: 20,
        bit_index: 0
      },
      program_2: {
        byte_index: 20,
        bit_index: 1
      },
      program_3: {
        byte_index: 20,
        bit_index: 2
      },
      program_4: {
        byte_index: 20,
        bit_index: 3
      }
    }
  }
};

const siemensSWP5DefaultConfig: SiemensConfig = {
  sps_version: 1,
  address: {
    ip: "10.198.2.237"
  },
  database: 2,
  program_types: {
    default: 1,
    program_7: 2,
    program_8: 2
  },
  wash_times: {
    type_1: {
      amount_50: 120,
      amount_100: 240,
      amount_150: 360,
      amount_200: 480
    },
    type_2: {
      amount_50: 120,
      amount_100: 240,
      amount_150: 360,
      amount_200: 480
    }
  },
  pulse_amount: false,
  input_bytes: {
    last_byte: 1,
    program_4: {
      amount_50: {
        byte_index: 2,
        bit_index: 0
      },
      amount_100: {
        byte_index: 2,
        bit_index: 4
      },
      amount_150: {
        byte_index: 3,
        bit_index: 0
      },
      amount_200: {
        byte_index: 3,
        bit_index: 4
      }
    },
    program_5: {
      amount_50: {
        byte_index: 2,
        bit_index: 1
      },
      amount_100: {
        byte_index: 2,
        bit_index: 5
      },
      amount_150: {
        byte_index: 3,
        bit_index: 1
      },
      amount_200: {
        byte_index: 3,
        bit_index: 5
      }
    },
    program_6: {
      amount_50: {
        byte_index: 2,
        bit_index: 2
      },
      amount_100: {
        byte_index: 2,
        bit_index: 6
      },
      amount_150: {
        byte_index: 3,
        bit_index: 2
      },
      amount_200: {
        byte_index: 3,
        bit_index: 6
      }
    },
    program_8: {
      amount_50: {
        byte_index: 2,
        bit_index: 3
      },
      amount_100: {
        byte_index: 2,
        bit_index: 7
      },
      amount_150: {
        byte_index: 3,
        bit_index: 3
      },
      amount_200: {
        byte_index: 3,
        bit_index: 7
      }
    }
  },
  output_bytes: {
    last_byte: 5,
    all_ok: {
      byte_index: 5,
      bit_index: 0
    },
    hour_ok: {
      byte_index: 5,
      bit_index: 1
    },
    program_4: {
      byte_index: 4,
      bit_index: 4
    },
    program_5: {
      byte_index: 4,
      bit_index: 5
    },
    program_6: {
      byte_index: 4,
      bit_index: 6
    },
    program_8: {
      byte_index: 4,
      bit_index: 7
    }
  }
};

const siemensSWP6DefaultConfig: SiemensConfig = {
  sps_version: 1,
  address: {
    ip: "10.198.2.237"
  },
  database: 2,
  program_types: {
    default: 1,
    program_7: 2,
    program_8: 2
  },
  wash_times: {
    type_1: {
      amount_50: 120,
      amount_100: 240,
      amount_150: 360,
      amount_200: 480
    },
    type_2: {
      amount_50: 120,
      amount_100: 240,
      amount_150: 360,
      amount_200: 480
    }
  },
  pulse_amount: false,
  input_bytes: {
    last_byte: 1,
    program_4: {
      amount_50: {
        byte_index: 2,
        bit_index: 0
      },
      amount_100: {
        byte_index: 2,
        bit_index: 4
      },
      amount_150: {
        byte_index: 3,
        bit_index: 0
      },
      amount_200: {
        byte_index: 3,
        bit_index: 4
      }
    },
    program_5: {
      amount_50: {
        byte_index: 2,
        bit_index: 1
      },
      amount_100: {
        byte_index: 2,
        bit_index: 5
      },
      amount_150: {
        byte_index: 3,
        bit_index: 1
      },
      amount_200: {
        byte_index: 3,
        bit_index: 5
      }
    },
    program_6: {
      amount_50: {
        byte_index: 2,
        bit_index: 2
      },
      amount_100: {
        byte_index: 2,
        bit_index: 6
      },
      amount_150: {
        byte_index: 3,
        bit_index: 2
      },
      amount_200: {
        byte_index: 3,
        bit_index: 6
      }
    },
    program_8: {
      amount_50: {
        byte_index: 2,
        bit_index: 3
      },
      amount_100: {
        byte_index: 2,
        bit_index: 7
      },
      amount_150: {
        byte_index: 3,
        bit_index: 3
      },
      amount_200: {
        byte_index: 3,
        bit_index: 7
      }
    }
  },
  output_bytes: {
    last_byte: 5,
    all_ok: {
      byte_index: 5,
      bit_index: 0
    },
    hour_ok: {
      byte_index: 5,
      bit_index: 1
    },
    program_4: {
      byte_index: 4,
      bit_index: 4
    },
    program_5: {
      byte_index: 4,
      bit_index: 5
    },
    program_6: {
      byte_index: 4,
      bit_index: 6
    },
    program_8: {
      byte_index: 4,
      bit_index: 7
    }
  }
};

const SiemensOptions: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  const siemensConfig = config["siemens"] as SiemensConfig;

  const version: string = siemensConfig["sps_version"]
    ? siemensConfig["sps_version"].toString()
    : "";

  const device =
    siemensConfig === siemensVersion2DefaultConfig
      ? ""
      : siemensConfig === siemensSWP6DefaultConfig
        ? "swp6"
        : siemensConfig === siemensSWP5DefaultConfig
          ? "swp5"
          : "";

  React.useEffect(() => {
    let configChanged;
    if (!siemensConfig["sps_version"]) {
      if (config["siemens"] !== siemensVersion2DefaultConfig) {
        config["siemens"] = siemensVersion2DefaultConfig;
        configChanged = true;
      }
    } else {
      if (config["siemens"] === siemensVersion2DefaultConfig) {
        config["siemens"] = {
          version: siemensConfig["sps_version"]
        };
        configChanged = true;
      }
    }
    if (configChanged) {
      setConfig({ ...config });
    }
  }, []);

  return (
    <Box sx={{ width: "95%", marginLeft: "5%" }}>
      <Dropdown
        name="siemens-version"
        label="Version"
        value={version}
        onChange={(event: SelectChangeEvent) => {
          const intValue = parseInt(event.target.value);
          if (isNaN(intValue)) {
            config["siemens"] = siemensVersion2DefaultConfig;
          } else {
            config["siemens"] = {};
            (config["siemens"] as SiemensConfig)["sps_version"] = intValue;
          }
          setConfig({ ...config });
        }}
        items={{
          1: {
            name: "Version 1"
          },
          "": {
            name: "Version 2 (default)"
          }
        }}
      />
      {siemensConfig["sps_version"] === 1 && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          <Dropdown
            name="siemens-swp-version"
            label="Device"
            value={device}
            onChange={(event: SelectChangeEvent) => {
              if (event.target.value === "swp5") {
                config["siemens"] = siemensSWP5DefaultConfig;
              } else if (event.target.value === "swp6") {
                config["siemens"] = siemensSWP6DefaultConfig;
              }
              setConfig({ ...config });
            }}
            items={{
              swp5: {
                name: "SWP 5"
              },
              swp6: {
                name: "SWP 6"
              }
            }}
          />
        </Box>
      )}
      <FormGroup>
        <FormControlLabel
          label="Simulate"
          control={
            <Switch
              color="success"
              checked={siemensConfig["mock_sps"]}
              onChange={() => {
                // on change the value of state is the same as before so I have to check if the new state, that is set afterwards will have the correct value
                if (siemensConfig["mock_sps"]) {
                  delete siemensConfig["mock_sps"];
                } else {
                  siemensConfig["mock_sps"] = true;
                }

                setConfig({ ...config });
              }}
            />
          }
        />
      </FormGroup>
    </Box>
  );
};

const CreditOptions: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  return (
    <>
      <h4 style={{ marginBottom: 0 }}>
        Credit(
        <a
          target="blank"
          href="https://tsg-germany.atlassian.net/wiki/spaces/TSG/pages/598867981/Available+Configurations#--Credit-Options"
        >
          Documentation
        </a>
        )
      </h4>
      <Box sx={{ width: "95%", marginLeft: "5%" }}>
        <InputField
          fullWidth={false}
          sx={{ width: "50%" }}
          name={"i2c.credit_on_duration"}
          test={/^\d+$/}
          label={"Credit Pulse ON duration (milliseconds)"}
          value={
            config["i2c.credit_on_duration"]
              ? (config["i2c.credit_on_duration"] as number).toString()
              : ""
          }
          type="number"
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            const intValue = parseInt(event.target.value);

            if (isNaN(intValue) === false && intValue > 0) {
              config["i2c.credit_on_duration"] = intValue;
            } else {
              delete config["i2c.credit_on_duration"];
            }
            setConfig({ ...config });
          }}
        />
        <InputField
          fullWidth={false}
          sx={{ width: "50%" }}
          name={"i2c.credit_off_duration"}
          test={/^\d+$/}
          label={"Credit Pulse OFF duration (milliseconds)"}
          value={
            config["i2c.credit_off_duration"]
              ? (config["i2c.credit_off_duration"] as number).toString()
              : ""
          }
          type="number"
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            const intValue = parseInt(event.target.value);

            if (isNaN(intValue) === false && intValue > 0) {
              config["i2c.credit_off_duration"] = intValue;
            } else {
              delete config["i2c.credit_off_duration"];
            }
            setConfig({ ...config });
          }}
        />
        <br />
        <InputField
          name={"i2c.credit_multiplier"}
          test={/^\d+$/}
          label={"Credit Multiplier"}
          value={
            config["i2c.credit_multiplier"]
              ? (config["i2c.credit_multiplier"] as number).toString()
              : ""
          }
          type="number"
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            const intValue = parseInt(event.target.value);

            if (isNaN(intValue) === false && intValue > 0) {
              config["i2c.credit_multiplier"] = intValue;
            } else {
              delete config["i2c.credit_multiplier"];
            }
            setConfig({ ...config });
          }}
        />
        <InputField
          name={"i2c.credit_pulse_value"}
          test={/^\d+$/}
          label={"Credit Pulse Value"}
          value={
            config["i2c.credit_pulse_value"]
              ? (config["i2c.credit_pulse_value"] as number).toString()
              : ""
          }
          type="number"
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            const intValue = parseInt(event.target.value);

            if (isNaN(intValue) === false && intValue > 0) {
              config["i2c.credit_pulse_value"] = intValue;
            } else {
              delete config["i2c.credit_pulse_value"];
            }
            setConfig({ ...config });
          }}
        />
        <FormGroup>
          <FormControlLabel
            label="Pause Between Programs"
            control={
              <Switch
                color="success"
                checked={
                  (config["i2c.pause_between_programs"] as boolean) || false
                }
                onChange={() => {
                  if (!config["i2c.pause_between_programs"]) {
                    config["i2c.pause_between_programs"] = true;
                  } else {
                    delete config["i2c.pause_between_programs"];
                  }

                  setConfig({ ...config });
                }}
              />
            }
          />
        </FormGroup>
        This are not all credit configurations, the rest is needed to be
        included manually, at the moment. Look{" "}
        <a
          target="blank"
          href="https://tsg-germany.atlassian.net/wiki/spaces/TSG/pages/598867981/Available+Configurations#--Credit-Options"
        >
          here
        </a>{" "}
        for reference.
      </Box>
    </>
  );
};
