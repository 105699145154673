import { reducerWithInitialState } from "typescript-fsa-reducers";
import { GetNestedStationsResponse, GetStationsResponse } from "./types";
import * as actions from "./actions";


export type Reducer = {
    stationsResponse: GetStationsResponse | null;
    nestedStationsResponse: GetNestedStationsResponse | null;
    areStationsLoading: boolean;
    areNestedStationsLoading: boolean;
};


const initialState: Reducer = {
    stationsResponse: null,
    nestedStationsResponse: null,
    areStationsLoading: false,
    areNestedStationsLoading: false
};

export const reducer = reducerWithInitialState(initialState)
    .case(
        actions.getStations.started,
        (state): Reducer => ({
            ...state,
            areStationsLoading: true
        })
    )
    .case(
        actions.getStations.done,
        (state, payload): Reducer => ({
            ...state,
            stationsResponse: payload.result,
            areStationsLoading: false
        })
    )
    .case(
        actions.getStations.failed,
        (state): Reducer => ({
            ...state,
            areStationsLoading: false
        })
    )
    .case(
        actions.getNestedStations.started,
        (state): Reducer => ({
            ...state,
            areNestedStationsLoading: true
        })
    )
    .case(
        actions.getNestedStations.done,
        (state, payload): Reducer => ({
            ...state,
            nestedStationsResponse: payload.result,
            areNestedStationsLoading: false
        })
    )
    .case(
        actions.getNestedStations.failed,
        (state): Reducer => ({
            ...state,
            areNestedStationsLoading: false
        })
    )
    .case(actions.clear, (): Reducer => initialState);
