import { Pagination } from "@mui/material";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem,
  TableRowComponents
} from "components/common/Table/types";
import { TransactionsTableFilterForm } from "components/common/TableFilterForm";
import { TransactionsTableFilterParams } from "components/common/TableFilterForm/types";
import { useMount } from "hooks/useMount";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { nestedStationsSelector } from "../../../store/modules/stations/selectors";
import * as stationsActions from "../../../store/modules/stations/actions";
import {
  tableTransactionsSelector,
  transactionsTotalPagesSelector
} from "../../../store/modules/transactions/selectors";
import { TableTransaction } from "../../../store/modules/transactions/types";
import { GetTransactionsParams } from "../../../store/modules/transactions/types";
import * as transactionsActions from "../../../store/modules/transactions/actions";
import { useUnmount } from "hooks/useUnmount";
import {
  AmountComponent,
  DateComponent,
  PaymentIdComponent,
  PaymentTypeComponent,
  StationComponent,
  StatusComponent,
  TerminalComponent,
  TimeComponent
} from "./tableComponents";
import { DIALOG_TYPES } from "./types";
import { DialogProps, FIELD_TYPES } from "components/common/Dialog/types";
import { Dialog } from "components/common/Dialog";
import { StationTerminals } from "src/store/modules/stations/types";
import { Terminal } from "src/store/modules/terminals/types";

export const Transactions: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const transactions = useSelector(tableTransactionsSelector);
  const totalPages = useSelector(transactionsTotalPagesSelector);
  const nestedStations = useSelector(nestedStationsSelector);

  const tableTransactions = () => {
    if (!transactions) {
      return null;
    }

    const transactionTable: { id: string;[key: string]: string }[] = [];

    transactions.forEach((tableRow) => {
      transactionTable.push({
        id: tableRow.id
      });
    });

    return transactionTable;
  };

  const getStationFromId = (stationId: string): StationTerminals | undefined =>
    nestedStations?.[stationId];
  const getTerminalFromStationAndId = (
    station: StationTerminals | undefined,
    terminalId: string
  ): Terminal | undefined => station?.terminals[terminalId];

  const tableComponentTransactions = () => {
    if (!transactions) {
      return null;
    }

    const transactionComponentsTable: TableRowComponents = [];

    transactions.forEach((tableRow) => {
      const station = getStationFromId(tableRow.station_id);
      const terminal = getTerminalFromStationAndId(
        station,
        tableRow.terminal_id
      );

      transactionComponentsTable.push({
        date: <DateComponent date={tableRow.create_date} />,
        time: <TimeComponent date={tableRow.create_date} />,
        station: <StationComponent station={station} />,
        terminal: <TerminalComponent terminal={terminal} />,
        pay_method_type: (
          <PaymentTypeComponent type={tableRow.pay_method_type} />
        ),
        pay_method_id: <PaymentIdComponent id={tableRow.pay_method_id} />,
        amount: <AmountComponent amount={tableRow.amount} />,
        status: <StatusComponent status={tableRow.status} />
      });
    });

    return transactionComponentsTable;
  };

  const [searchConfiguration, setSearchConfiguration] =
    useState<TransactionsTableFilterParams>({
      page: 1,
      station_id: "",
      terminal_id: ""
    });

  const tableColumns: TableColumn[] = [
    {
      key: "date",
      label: t("transaction.uiComponents.table.headers.date")
    },
    {
      key: "time",
      label: t("transaction.uiComponents.table.headers.time")
    },
    {
      key: "station",
      label: t("transaction.uiComponents.table.headers.station")
    },
    {
      key: "terminal",
      label: t("transaction.uiComponents.table.headers.terminal")
    },
    {
      key: "pay_method_type",
      label: t("transaction.uiComponents.table.headers.pay_method_type")
    },
    {
      key: "pay_method_id",
      label: t("transaction.uiComponents.table.headers.pay_method_id")
    },
    {
      key: "amount",
      label: t("transaction.uiComponents.table.headers.amount")
    },
    {
      key: "status",
      label: t("transaction.uiComponents.table.headers.status")
    }
  ];

  const backlightTableProperties = {
    active: {
      conditionFn: (value) => (value ? "success.main" : "error")
    }
  };

  const addFilter = (
    searchConfiguration: TransactionsTableFilterParams
  ): GetTransactionsParams => ({
    page: searchConfiguration.page,
    station_id: searchConfiguration.station_id,
    terminal_id: searchConfiguration.terminal_id
  });

  const getTransactions = () => {
    dispatch(
      transactionsActions.getTransactions.started(
        addFilter(searchConfiguration)
      )
    );
  };

  const getStations = () => {
    dispatch(stationsActions.getNestedStations.started({}));
  };

  useMount(() => {
    getTransactions();
    getStations();
  });

  useUnmount(() => {
    dispatch(transactionsActions.clear());
    dispatch(stationsActions.clear());
  });

  useEffect(() => {
    getTransactions();
  }, [searchConfiguration, dispatch]);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  let selectedItem: TableTransaction | undefined;

  const getSelectedTransaction = () => {
    if (selectedItem === undefined || selectedItemId !== selectedItem.id) {
      selectedItem = transactions?.find(
        (transaction) => transaction.id === selectedItemId
      );
    }
    return selectedItem;
  };

  const [dialog, setDialog] = useState({
    type: DIALOG_TYPES.SHOW_DETAILS,
    isOpened: false
  });

  const closeDialogHandler = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const showDetailsHandler = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.SHOW_DETAILS,
      isOpened: true
    });
  }, []);

  const tableActions: TableRowActionsMenuItem[] = [
    {
      label: t("transaction.uiComponents.table.actions.show_details"),
      handler: showDetailsHandler
    }
  ];

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<DialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.SHOW_DETAILS]: {
      title: t("transaction.form.show-details.title"),
      onClose: closeDialogHandler,
      fields: [
        {
          name: "date",
          label: t("transaction.uiComponents.table.headers.date"),
          type: FIELD_TYPES.DATE_TIME,
          value: getSelectedTransaction()?.create_date || ""
        },
        {
          name: "external",
          label: t("transaction.uiComponents.table.headers.external"),
          type: FIELD_TYPES.BOOLEAN,
          value: getSelectedTransaction()?.external || ""
        },
        {
          name: "station",
          label: t("transaction.uiComponents.table.headers.station"),
          type: FIELD_TYPES.TEXT,
          value:
            getStationFromId(getSelectedTransaction()?.station_id ?? "")
              ?.name ?? ""
        },
        {
          name: "terminal",
          label: t("transaction.uiComponents.table.headers.terminal"),
          type: FIELD_TYPES.TEXT,
          value:
            getTerminalFromStationAndId(
              getStationFromId(getSelectedTransaction()?.station_id ?? ""),
              getSelectedTransaction()?.terminal_id ?? ""
            )?.name ?? ""
        },
        {
          name: "status",
          label: t("transaction.uiComponents.table.headers.status"),
          type: FIELD_TYPES.TEXT,
          value: getSelectedTransaction()?.status || ""
        },
        {
          name: "amount",
          label: t("transaction.uiComponents.table.headers.amount"),
          type: FIELD_TYPES.TEXT,
          value: getSelectedTransaction()?.amount || ""
        },
        {
          name: "currency",
          label: t("transaction.uiComponents.table.headers.currency"),
          type: FIELD_TYPES.TEXT,
          value: "EUR cents"
        },
        {
          name: "tax_rate",
          label: t("transaction.uiComponents.table.headers.tax_rate"),
          type: FIELD_TYPES.TEXT,
          value: getSelectedTransaction()?.tax_rate.toString() + "%" || ""
        },
        {
          name: "pay_method_type",
          label: t("transaction.uiComponents.table.headers.pay_method_type"),
          type: FIELD_TYPES.TEXT,
          value: getSelectedTransaction()?.pay_method_type || ""
        },
        {
          name: "pay_method_id",
          label: t("transaction.uiComponents.table.headers.pay_method_id"),
          type: FIELD_TYPES.TEXT,
          value: getSelectedTransaction()?.pay_method_id || ""
        }
      ]
    }
  };

  return (
    <>
      <Table
        isSearchEnabled={false}
        isSortingEnabled={false}
        isPaginationEnabled={false}
        rows={tableTransactions() || []}
        rowsData={transactions}
        columns={tableColumns}
        actions={tableActions}
        isLoading={!transactions}
        backlightTableProperties={backlightTableProperties}
        tableRowActions={{}}
        tableRowComponents={tableComponentTransactions() || []}
        toolbarItems={[
          <h3>Filter:</h3>,
          <TransactionsTableFilterForm
            key="filter"
            tableFilterParams={searchConfiguration}
            nestedStations={nestedStations ?? {}}
            onTableShouldUpdate={function (
              tableFilterParams: TransactionsTableFilterParams
            ): void {
              setSearchConfiguration(tableFilterParams);
            }}
          />
        ]}
      />
      <Pagination
        count={totalPages ?? 1}
        page={searchConfiguration.page}
        onChange={(event: React.ChangeEvent<unknown>, page: number) => {
          setSearchConfiguration({
            ...searchConfiguration,
            page
          });
        }}
      />
      <Dialog
        isOpened={dialog.isOpened}
        onClose={closeDialogHandler}
        fields={dialogProps[dialog.type].fields}
        title={dialogProps[dialog.type].title}
      />
    </>
  );
};
