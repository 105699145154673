import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const withProps = () => (fn) => fn;

export const DRAWER_WIDTH = 256; // in pixels

export const Container = styled.div`
  display: flex;
  min-height: 100%;
`;

export const DrawerHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.drawer.main};
`;

export const LogoImgContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.spacing(16)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const LogoLinkContainer = styled.span`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacing(8)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const Drawer = styled(MuiDrawer)`
  display: flex;
  & .MuiDrawer-paper {
    width: ${DRAWER_WIDTH}px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.drawer.main}
  }
`;

export const DrawerMenuList = styled(List)`
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing(0)};
  background-color: ${({ theme }) => theme.palette.drawer.main};
`;

export const IconContainer = withProps()(styled.span)`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${(props) => props.active ? props.theme.palette.accent.main : "#fff"};
`;

export const ListItem = styled.li`
  padding: 0;
`;

export const Link = styled(RouterLink)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  color: #fff;
  width: 100%;
`;
