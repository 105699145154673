export const ELEMENT_IDS = {
  REACT_ROOT: "root",
  CONTENT_CONTAINER: "content-container"
};

export const ROUTES = {
  ROOT: "/",
  PAY_METHODS: "/pay-methods",
  PLANS: "/plans",
  PROGRAMS: "/programs",
  TRANSACTIONS: "/transactions",
  CALENDAR: "/calendar",
  RESTORE_CONFIG_TOOL: "/restore-config-tool",
};

export const ERROR_MESSAGES = {
  NAME_DOES_NOT_START_WITH_WHITESPACE: "Name must not start with whitespace.",
  NAME_DOES_NOT_END_WITH_WHITESPACE: "Name must not end with whitespace.",
  NAME_DOES_NOT_CONTAIN_SEQUENTIAL_WHITESPACES:
    "Name must not contain sequential whitespaces.",
  NAME_DOES_NOT_CONTAIN_COLON: "Name must not contain colon.",
  NAME_DOES_NOT_CONTAIN_NUMBER_SIGN: "Name must not contain number sign.",
  NAME_STARTS_WITH_LETTER: "Name must start with Latin letter (a-z, A-Z).",
  REQUIRED: "Field is required.",
  PASSWORD:
    "Password must contain at least one Latin letter in upper case (A-Z), one Latin letter in lower case (a-z), digit (0-9) and special character ( !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~) and must be at least 8 characters long.",
  DATABASE_NAME:
    "Name must contain only Latin letters (a-z, A-Z), digits (0-9), hyphen (-) and must be at most 16 characters long.",
  CLUSTER_NAME:
    "Name must contain only Latin letters (a-z, A-Z), digits (0-9), hyphen (-), underscore (_) and dot(.) and must be at most 255 characters long.",
  KEYPAIR_NAME:
    "Name must contain only Latin letters (a-z, A-Z), digits (0-9), hyphen (-), underscore (_) and whitespace.",
  EMAIL_ADDRESS: "E-mail address has not valid format.",
  PASSWORDS_MUST_MATCH: "Passwords must match.",
  SERVER_TAG:
    "Server tag must not contain comma (,) or slash (/) and must be at most 60 characters long.",
  MAX_SERVER_TAG_COUNT: "Server can have up to 50 tags.",
  ORGANIZATION_NAME: "Name must not contain number sign (#).",
  GROUP_NAME: "Name must not contain number sign (#).",
  PROJECT_NAME: "Name must not contain number sign (#) or colon (:).",
  HTTP_HTTPS_ADDRESS: "Endpoint must be http or https address."
};
