import Button from "@mui/material/Button";
import { FormDialog } from "components/common/Dialog/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/Dialog/FormDialog/types";
import { Program } from "../../../store/modules/programs/types";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as plansActions from "../../../store/modules/plans/actions";
import {
  isPlanCreatingSelector,
  isPlanDeletingSelector,
  isPlanUpdatingSelector,
  tablePlansSelector
} from "../../../store/modules/plans/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { number, string, array } from "yup";
import { DIALOG_TYPES } from "./types";
import { useTranslation } from "react-i18next";
import { tableProgramsSelector } from "../../../store/modules/programs/selectors";
import * as programsActions from "../../../store/modules/programs/actions";

export const Plans: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const plans = useSelector(tablePlansSelector);
  const programs = useSelector(tableProgramsSelector);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const isPlanCreating = useSelector(isPlanCreatingSelector);
  const isPlanUpdating = useSelector(isPlanUpdatingSelector);
  const isPlanDeleting = useSelector(isPlanDeletingSelector);
  const isOperationInProgress =
    isPlanCreating || isPlanUpdating || isPlanDeleting;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleCreatePlanButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE,
      isOpened: true
    });
  }, []);

  const handleEditPlanMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleDeletePlanMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const tableActions: TableRowActionsMenuItem[] = [
    {
      label: t("uiComponents.table.actions.edit"),
      handler: handleEditPlanMenuItemClick
    },
    {
      label: t("uiComponents.table.actions.delete"),
      handler: handleDeletePlanMenuItemClick,
      isDisabled: (plan) => plan?.default
    }
  ];

  const tableColumns: TableColumn[] = [
    { key: "name", label: t("uiComponents.table.headers.name") },
    { key: "type", label: t("uiComponents.table.headers.type") },
    { key: "value_type", label: t("uiComponents.table.headers.value_type") },
    { key: "value", label: t("uiComponents.table.headers.amount") }
  ];

  const typeOptions: SelectOption[] = [
    {
      label: t("uiComponents.form.options.planTypeOptions.unlimited"),
      value: "unlimited"
    },
    {
      label: t("uiComponents.form.options.planTypeOptions.flat_rate"),
      value: "flat-rate"
    }
  ];

  const definePlanValueLabel = ({ type, value_type, value }) => {
    const isUnlimited = type === "unlimited";
    const isCurrency = value_type === "currency";
    if (isUnlimited) return "-";
    else {
      return isCurrency ? `${value / 100} €` : value;
    }
  };

  const tableRowsPlansList = () => {
    if (plans) {
      const copy = JSON.parse(JSON.stringify(plans));
      return copy.map((plan) => {
        return {
          ...plan,
          ...{
            type:
              {
                unlimited: t(
                  "uiComponents.form.options.planTypeOptions.unlimited"
                ),
                "flat-rate": t(
                  "uiComponents.form.options.planTypeOptions.flat_rate"
                )
              }[plan.type] || "",
            value: definePlanValueLabel(plan),
            value_type:
              {
                currency: t(
                  "uiComponents.form.options.planValueTypes.currency"
                ),
                count: t("uiComponents.form.options.planValueTypes.count")
              }[plan.value_type] || "",
            highlighted: plan.default
          }
        };
      });
    }
  };

  const valueTypes: SelectOption[] = [
    {
      label: t("uiComponents.form.options.planValueTypes.currency"),
      value: "currency"
    },
    {
      label: t("uiComponents.form.options.planValueTypes.count"),
      value: "count"
    }
  ];
  const getValueType = (id) => {
    return valueTypes.find((option) => id === option.value);
  };

  const disabledIfUnlimited = (target: { [key: string]: string }) => {
    return target.type === "unlimited";
  };

  const disabledIfCurrency = (target: { [key: string]: string }) => {
    return target.value_type === "currency";
  };

  const typeNumberIfAmountOfWashes = (target: {
    [key: string]: string | any;
  }) => {
    if (
      (target && target?.value_type === "count") ||
      (target && target?.value_type?.value === "count")
    )
      return FIELD_TYPES.NUMBER;
    else return FIELD_TYPES.CURRENCY_AMOUNT;
  };

  useMount(() => {
    dispatch(plansActions.getPlans.started({}));
    dispatch(programsActions.getPrograms.started({}));
  });

  useUnmount(() => {
    dispatch(plansActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(plansActions.getPlans.started({}));
    }
  }, [previousIsOperationInProgress, isOperationInProgress, dispatch]);

  const handleConfirmCreatePlan = useCallback(
    (data: {
      created_at: string;
      default_amount: number;
      id: string;
      name: string;
      programs: Program[];
      type: string;
      value: number;
      value_type: string;
    }) => {
      dispatch(
        plansActions.createPlan.started({
          data: { ...data }
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog]
  );

  const handleConfirmEditPlan = useCallback(
    (data: {
      created_at: string;
      default_amount: number;
      id: string;
      name: string;
      programs: Program[];
      type: string;
      value: number;
      value_type: string;
    }) => {
      if (selectedItemId) {
        dispatch(
          plansActions.updatePlan.started({
            id: selectedItemId,
            data: { ...data }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmDeletePlan = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        plansActions.deletePlan.started({
          id: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [dispatch, selectedItemId, handleCloseDialog]);

  const programsListAsValueLabel = (programs) => {
    return programs?.map((program) => ({
      label: program.name,
      value: program.id
    }));
  };

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreatePlan,
      title: t("uiComponents.form.titles.create_plan"),
      confirmButtonLabel: t("uiComponents.form.actions.create"),
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: t("uiComponents.form.fields.name"),
          rules: string()
        },
        {
          name: "type",
          type: FIELD_TYPES.SELECT,
          label: t("uiComponents.form.fields.type_id"),
          options: typeOptions,
          rules: string().required()
        },
        {
          name: "value_type",
          type: FIELD_TYPES.SELECT,
          label: t("uiComponents.form.fields.value_type"),
          options: valueTypes,
          rules: string().required(),
          invisible: disabledIfUnlimited,
          payloadValueOfCondition: {
            fn: disabledIfUnlimited,
            payload: { label: "Currency", value: "currency" }
          }
        },
        {
          name: "value",
          type: typeNumberIfAmountOfWashes,
          label: t("uiComponents.form.fields.amount"),
          rules: number().required(),
          invisible: disabledIfUnlimited,
          payloadValueOfCondition: {
            fn: disabledIfUnlimited,
            payload: -1
          }
        },
        {
          name: "default_amount",
          type: FIELD_TYPES.CURRENCY_AMOUNT,
          label: t("uiComponents.form.fields.default_wash_amount"),
          rules: number().required(),
          explanation: t("uiComponents.form.info.plan_default_amount"),
          invisible: disabledIfCurrency,
          payloadValueOfCondition: {
            fn: disabledIfCurrency,
            payload: -1
          }
        },
        {
          name: "programs",
          type: FIELD_TYPES.MULTISELECT,
          label: t("uiComponents.form.fields.programs"),
          options: programsListAsValueLabel(programs),
          rules: array()
        }
      ]
    },
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditPlan,
      title: t("uiComponents.form.titles.edit_plan"),
      confirmButtonLabel: t("uiComponents.form.actions.save"),
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: t("uiComponents.form.fields.name"),
          rules: string(),
          defaultValue:
            plans?.find((plan) => plan.id === selectedItemId)?.name || ""
        },
        {
          name: "type",
          type: FIELD_TYPES.SELECT,
          label: t("uiComponents.form.fields.type_id"),
          rules: string().required(),
          options: typeOptions,
          invisible: (_) => true,
          defaultValue: plans?.find((plan) => plan.id === selectedItemId)?.type
        },
        {
          name: "value_type",
          type: FIELD_TYPES.SELECT,
          label: t("uiComponents.form.fields.value_type"),
          options: valueTypes,
          rules: string().required(),
          invisible: (_) => true,
          defaultValue: plans?.find((plan) => plan.id === selectedItemId)?.value_type,
          payloadValueOfCondition: {
            fn: disabledIfUnlimited,
            payload: { label: "Currency", value: "currency" }
          }
        },
        {
          name: "value",
          type: typeNumberIfAmountOfWashes,
          label: t("uiComponents.form.fields.amount"),
          rules: number().required(),
          defaultValue:
            plans?.find((plan) => plan.id === selectedItemId)?.value || "",
          invisible: disabledIfUnlimited,
          payloadValueOfCondition: {
            fn: disabledIfUnlimited,
            payload: -1
          }
        },
        {
          name: "default_amount",
          type: FIELD_TYPES.CURRENCY_AMOUNT,
          label: t("uiComponents.form.fields.default_amount"),
          rules: number().required(),
          explanation: t("uiComponents.form.info.plan_default_amount"),
          defaultValue:
            plans?.find((plan) => plan.id === selectedItemId)?.default_amount ||
            "",
          invisible: disabledIfCurrency,
          payloadValueOfCondition: {
            fn: disabledIfCurrency,
            payload: 0.0
          }
        },
        {
          name: "programs",
          type: FIELD_TYPES.MULTISELECT,
          label: t("uiComponents.form.fields.programs"),
          options: programsListAsValueLabel(programs),
          rules: array(),
          defaultValue:
            programsListAsValueLabel(
              plans?.find((plan) => plan.id === selectedItemId)?.programs
            ) || ""
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeletePlan,
      title: t("uiComponents.deleteForm.titles.plan"),
      confirmButtonLabel: t("uiComponents.deleteForm.actions.delete")
    }
  };

  return (
    <>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={tableRowsPlansList() || []}
        columns={tableColumns}
        actions={tableActions}
        isLoading={!plans}
        toolbarItems={
          <Button
            onClick={handleCreatePlanButtonClick}
            variant={"contained"}
            color={"accent"}
            sx={{ color: "#fff" }}
          >
            {t("plansPage.buttons.create_plan")}
          </Button>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
