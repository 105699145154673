import actionCreatorFactory from "typescript-fsa";
import {
  CreatePlanParams,
  CreatePlanResponse,
  DeletePlanParams,
  DeletePlanResponse,
  GetPlanParams,
  GetPlanResponse,
  GetPlansParams,
  GetPlansResponse,
  UpdatePlanParams,
  UpdatePlanResponse
} from "./types";

const actionCreator = actionCreatorFactory("PLANS");

export const getPlan = actionCreator.async<
  GetPlanParams,
  GetPlanResponse,
  unknown
>("GET_PLAN");

export const getPlans = actionCreator.async<
  GetPlansParams,
  GetPlansResponse,
  unknown
>("GET_PLANS");

export const createPlan = actionCreator.async<
  CreatePlanParams,
  CreatePlanResponse,
  unknown
>("CREATE_PLAN");

export const updatePlan = actionCreator.async<
  UpdatePlanParams,
  UpdatePlanResponse,
  unknown
>("UPDATE_PLAN");

export const deletePlan = actionCreator.async<
  DeletePlanParams,
  DeletePlanResponse,
  unknown
>("DELETE_PLAN");

export const clear = actionCreator<void>("CLEAR");
