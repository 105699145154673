import DateAdapter from "@mui/lab/AdapterDateFns";
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { FC, useMemo, useState, createContext, useEffect } from "react";
import { Provider } from "react-redux";
import { initOptions, keycloak } from "../../keycloak";
import { store } from "../../store/store";
import { palette } from "../../themes/palette";
import { MainView } from "components/MainView";
import { GlobalStyles } from "./styles";
import { createTheme } from "@mui/material/styles";
import { useMount } from "hooks/useMount";
import { useTranslation } from "react-i18next";
import { SnackbarProvider } from 'notistack';
import { CloseSnackbar } from "components/common/Notification/CloseSnackbar";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const App: FC = () => {
  const [localeObj, setLocaleObj] = useState(undefined);
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const { i18n } = useTranslation();
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const localeMap = {
    en: enLocale,
    de: deLocale,
  };
  useEffect(() => {
    const locale = localeMap[i18n.language];
    setLocaleObj(locale)
  }, [i18n.language])

  useMount(() => {
    const savedTheme: any = localStorage.getItem("theme");
    if (savedTheme) setMode(savedTheme);
  })
  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode])

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...palette[mode],
        },
      }),
    [mode],
  );

  return (
    <StyledEngineProvider injectFirst>
      <Head/>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <GlobalStyles/>
          <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={initOptions}
            LoadingComponent={<Loader text={"Initializing..."}/>}
          >
            <Provider store={store}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                autoHideDuration={4000}
                action={(key: string) => (<CloseSnackbar id={key}/>)}
              >
                <LocalizationProvider dateAdapter={DateAdapter} locale={localeObj}>
                  <MainView/>
                </LocalizationProvider>
              </SnackbarProvider>
            </Provider>
          </ReactKeycloakProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
};
