import { EventType, EventCategory } from "./types";

export const EVENT_STYLES: Record<EventType, EventCategory> = {
    [EventType.UNKNOWN]: {
        label: "UNKNOWN",
        color: {
            background: "#aaaaaa88",
            chip: "#aaaaaa",
            text: "#000"
        }
    },
    [EventType.HAPPY_HOUR]: {
        label: "HAPPY HOUR",
        color: {
            background: "#f2b60088",
            chip: "#f2b600",
            text: "#000"
        }
    },
    [EventType.HOLIDAY]: {
        label: "HOLIDAY",
        color: {
            background: "#22dd2288",
            chip: "#22dd22",
            text: "#000"
        }
    },
}