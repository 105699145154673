import AccountCircle from "@mui/icons-material/AccountCircle";
import Language from "@mui/icons-material/Language";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { ToggleTheme } from "components/common/ToggleTheme";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { Menu } from "components/common/Menu";
import * as authActions from "../../store/modules/auth/actions";
import { userSelector } from "../../store/modules/auth/selectors";
import { FC, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ELEMENT_IDS, ROUTES } from "../../constants";
import * as s from "./styles";
import { useMount } from "hooks/useMount";
import { useLocation } from "react-router-dom";

export const Content: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [isUserMenuOpened, setIsUserMenuOpened] = useState<boolean>(false);
  const [isLocalisationMenuOpened, setIsLocalisationMenuOpened] =
    useState<boolean>(false);
  const user = useSelector(userSelector);
  const location = useLocation();

  const pagesTitles = {
    [ROUTES.PAY_METHODS]: t("uiComponents.navigation.list.pay_methods"),
    [ROUTES.PLANS]: t("uiComponents.navigation.list.plans"),
    [ROUTES.PROGRAMS]: t("uiComponents.navigation.list.programs"),
    [ROUTES.RESTORE_CONFIG_TOOL]: t(
      "uiComponents.navigation.list.restore_config_tool"
    ),
    [ROUTES.TRANSACTIONS]: t("uiComponents.navigation.list.transactions"),
    [ROUTES.CALENDAR]: t("uiComponents.navigation.list.calendar")
  };

  const handleUserMenuButtonClick = useCallback(() => {
    setIsUserMenuOpened(!isUserMenuOpened);
  }, [isUserMenuOpened]);

  const handleLocalisationMenuButtonClick = useCallback(() => {
    setIsLocalisationMenuOpened(!isLocalisationMenuOpened);
  }, [isLocalisationMenuOpened]);

  const handleUserMenuClose = useCallback(() => {
    setIsUserMenuOpened(false);
  }, []);

  const handleLocalisationMenuClose = useCallback(() => {
    setIsLocalisationMenuOpened(false);
  }, []);

  const userMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const localisationMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleSettingsMenuItemClick = useCallback(() => {
    void keycloak.accountManagement();
  }, [keycloak]);

  const handleLogoutMenuItemClick = useCallback(() => {
    dispatch(authActions.logout.started());
    handleUserMenuClose();
  }, [handleUserMenuClose, dispatch]);

  const handleTranslation = (lang: string) => i18n.changeLanguage(lang);

  useMount(() => {
    const savedLang = localStorage.getItem("lang");
    if (savedLang) void handleTranslation(savedLang).then((r) => r);
  });

  return (
    <s.Container>
      <s.Header>
        <Box sx={{ display: "flex" }}>
          <Typography
            variant={"h4"}
            component={"h2"}
            sx={{ alignSelf: "flex-end", color: "#fff" }}
          >
            {pagesTitles[location.pathname]}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <s.Toolbar>
            {user && (
              <s.UsernameContainer>
                <Typography sx={{ color: "#fff" }}>
                  {[user.firstName, user.lastName].filter(Boolean).join(" ") ||
                    String(user.username)}
                </Typography>
              </s.UsernameContainer>
            )}
            <Tooltip title={t("uiComponents.user.tooltip")}>
              <span style={{ color: "#fff" }}>
                <IconButton
                  onClick={handleUserMenuButtonClick}
                  color={"inherit"}
                  ref={userMenuButtonRef}
                  title={"User menu"}
                >
                  <AccountCircle />
                </IconButton>
              </span>
            </Tooltip>
            <Menu
              isOpened={isUserMenuOpened}
              onClose={handleUserMenuClose}
              anchorEl={userMenuButtonRef.current}
              items={[
                {
                  label: t("uiComponents.user.list.settings"),
                  onClick: handleSettingsMenuItemClick
                },
                {
                  label: t("uiComponents.user.list.log_out"),
                  onClick: handleLogoutMenuItemClick
                }
              ]}
            />
          </s.Toolbar>
          <s.Toolbar>
            <Tooltip
              title={t("uiComponents.localisation.tooltip")}
              placement={"left-start"}
            >
              <span style={{ color: "#fff" }}>
                <IconButton
                  onClick={handleLocalisationMenuButtonClick}
                  color={"inherit"}
                  ref={localisationMenuButtonRef}
                  title={"Localisation"}
                >
                  <Language />
                </IconButton>
              </span>
            </Tooltip>
            <Menu
              isOpened={isLocalisationMenuOpened}
              onClose={handleLocalisationMenuClose}
              anchorEl={localisationMenuButtonRef.current}
              items={[
                {
                  label: "EN",
                  onClick: () => {
                    setIsLocalisationMenuOpened(false);
                    localStorage.setItem("lang", "en");
                    void handleTranslation("en").then((r) => r);
                  }
                },
                {
                  label: "DE",
                  onClick: () => {
                    setIsLocalisationMenuOpened(false);
                    localStorage.setItem("lang", "de");
                    void handleTranslation("de").then((r) => r);
                  }
                }
              ]}
            />
          </s.Toolbar>
          <s.Toolbar>
            <ToggleTheme />
          </s.Toolbar>
        </Box>
      </s.Header>
      <s.ContentContainer id={ELEMENT_IDS.CONTENT_CONTAINER}>
        {children}
      </s.ContentContainer>
    </s.Container>
  );
};
