import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
  Switch,
  useTheme
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { t } from "i18next";
import React, { FC } from "react";
import { InputField, isIpv4, RestoreConfigContext } from "./tools";
import { Dropdown } from "components/common/Dropdown";

interface FeigTerminalConfig {
  device: string;
  protocol: string;
  communication: {
    type: string;
    settings: {
      address: string;
      port: number;
      use_ssl: boolean;
    };
  };
}

interface BigBrotherLicensePlateConfig {
  active: boolean;
  api_url_prefix: string;
  license_plate_verify?: string;
  license_plate_discounts?: string;
  camera_check?: string;
  inform_status?: string;
  heartbeat?: string;
  simulator?: {
    active: boolean;
    license_plate: string;
    license_plate_country: string;
    license_plate_confidence: number;
    license_plate_country_confidence: number;
  };
}

interface DropdownItems {
  [key: string]: { name: string; value: unknown };
}

interface ExtendedCoinAcceptor {
  tokens: Record<string, number>;
  remove_coins: string[];
}

export const PaymentSelection: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }

  const [config, setConfig] = restoreConfig.config;

  const paymentItems: Record<string, DropdownItems> = {
    coin_acceptor: {
      "": {
        name: "None",
        value: undefined
      },
      simple: {
        name: "Simple",
        value: true
      },
      extended: {
        name: "Extended",
        value: {
          tokens: {},
          remove_coins: []
        }
      }
    },
    nfc: {
      "": {
        name: "None",
        value: undefined
      },
      payter: {
        name: "Payter",
        value: true
      },
      feigcvend: {
        name: "Feig Reader",
        value: {
          device: "feigcvend",
          protocol: "ZVT",
          communication: {
            type: "TCP",
            settings: {
              address: "",
              port: 22001,
              use_ssl: true
            }
          }
        }
      }
    },
    voucher: {
      "": {
        name: "None",
        value: undefined
      },
      voucher: {
        name: "Voucher",
        value: true
      }
    },
    license_plate: {
      "": {
        name: "None",
        value: undefined
      },
      license_plate: {
        name: t("restoreConfigToolPage.payment.license_plate"),
        value: {
          active: true,
          license_plate_verify: "",
          license_plate_discounts: "",
          api_url_prefix: ""
        }
      }
    },
    fleetcards: {
      "": {
        name: "None",
        value: undefined
      },
      fleetcards: {
        name: "Fleetcards",
        value: {}
      }
    }
  };

  function handleOnChange(event: SelectChangeEvent, deviceTyp: string) {
    delete config["payment." + deviceTyp];
    if (event.target.value != "") {
      config["payment." + deviceTyp] =
        paymentItems[deviceTyp][event.target.value].value;
    }

    setConfig({ ...config });
  }

  return (
    <>
      <Box sx={{ width: "95%" }}>
        <h2>Payment Selection</h2>
        <CoinAcceptorOption
          dropdownItems={paymentItems["coin_acceptor"]}
          onChange={(event: SelectChangeEvent) =>
            handleOnChange(event, "coin_acceptor")
          }
        />
        <ContactlessPaymentOption
          dropdownItems={paymentItems["nfc"]}
          onChange={(event: SelectChangeEvent) => handleOnChange(event, "nfc")}
        />
        <FleetcardsPaymentOption
          dropdownItems={paymentItems["fleetcards"]}
          onChange={(event: SelectChangeEvent) => {
            delete config["fleetcards"];
            if (event.target.value != "") {
              config["fleetcards"] =
                paymentItems["fleetcards"][event.target.value].value;
            }

            setConfig({ ...config });
          }}
        />
        <VoucherPaymentOption
          dropdownItems={paymentItems["voucher"]}
          onChange={(event: SelectChangeEvent) =>
            handleOnChange(event, "voucher")
          }
        />
        <LicensePlateOption
          dropdownItems={paymentItems["license_plate"]}
          onChange={(event: SelectChangeEvent) =>
            handleOnChange(event, "license_plate")
          }
        />
      </Box>
      <PaymentOrder />
    </>
  );
};

const CoinAcceptorOption: FC<{
  onChange: (event: SelectChangeEvent) => void;
  dropdownItems: DropdownItems;
}> = (props) => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  let initCoinAcceptor = "";
  if (config["payment.coin_acceptor"]) {
    if (config["payment.coin_acceptor"] === true) {
      initCoinAcceptor = "simple";
    } else {
      initCoinAcceptor = "extended";
    }
  }

  const [coinAcceptor, setCoinAcceptor] = React.useState(initCoinAcceptor);

  const coinAcceptorConfig: ExtendedCoinAcceptor = config[
    "payment.coin_acceptor"
  ] as ExtendedCoinAcceptor;

  return (
    <>
      <Dropdown
        name="coin_acceptor"
        label={t("restoreConfigToolPage.payment.coin_acceptor")}
        value={coinAcceptor}
        onChange={(event: SelectChangeEvent) => {
          setCoinAcceptor(event.target.value);

          props.onChange(event);
        }}
        items={props.dropdownItems}
      />
      {/* Extended CoinAcceptor config */}
      {coinAcceptor === "extended" && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          <a
            target="blank"
            href="https://tsg-germany.atlassian.net/wiki/spaces/TSG/pages/598867981/Available+Configurations#%E2%80%93-Coin-Acceptor-configuration"
          >
            Confluence Page
          </a>

          {/* TOKENS */}
          <>
            <h5 style={{ marginBottom: 0 }}>Tokens</h5>
            {Object.entries(coinAcceptorConfig["tokens"]).map(
              ([key, value], index) => {
                return (
                  <div key={key} style={{ display: "flex" }}>
                    <InputField
                      key={key + "_key"}
                      value={key}
                      name={"token_key_" + (index + 1).toString()}
                      label={"Token Name " + (index + 1).toString()}
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        if (event.target.value === key) {
                          // key didn't get changed so there is nothing todo
                          return;
                        }
                        if (
                          event.target.value in coinAcceptorConfig["tokens"]
                        ) {
                          alert(
                            "This token is already configured choose an other name or change the other one."
                          );
                          return;
                        }

                        coinAcceptorConfig["tokens"][event.target.value] =
                          coinAcceptorConfig["tokens"][key];
                        delete coinAcceptorConfig["tokens"][key];

                        setConfig({ ...config });
                      }}
                    />
                    <InputField
                      key={key + "_value"}
                      value={value.toString()}
                      test={/^\d+$/}
                      type="number"
                      name={"token_value_" + (index + 1).toString()}
                      label={"Token Value " + (index + 1).toString()}
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        const intValue = parseInt(event.target.value);
                        if (isNaN(intValue) || intValue < 0) {
                          alert(
                            "This item has to be a positive integer number"
                          );
                          return;
                        }

                        if (intValue === coinAcceptorConfig["tokens"][key]) {
                          // value didn't get changed so there is nothing todo
                          return;
                        }

                        coinAcceptorConfig["tokens"][key] = intValue;

                        setConfig({ ...config });
                      }}
                    />
                    <Button
                      sx={{ marginTop: "10px" }}
                      color="accent"
                      variant="outlined"
                      onClick={() => {
                        delete coinAcceptorConfig["tokens"][key];
                        setConfig({ ...config });
                      }}
                    >
                      Remove Token
                    </Button>
                    <br key={key + "_br"} />
                  </div>
                );
              }
            )}
            <InputField
              fullWidth={false}
              label={"New Token Name"}
              name="new_token_name"
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value in coinAcceptorConfig["tokens"]) {
                  alert(
                    "This token is already configured choose an other name or change the other one."
                  );
                  return;
                }

                if (event.target.value !== "") {
                  coinAcceptorConfig["tokens"][event.target.value] = 0;
                }

                setConfig({ ...config });
              }}
            />
          </>

          {/* REMOVE COINS */}
          <>
            <h5 style={{ marginBottom: 0 }}>Remove Coins</h5>
            {coinAcceptorConfig["remove_coins"].map((value, index) => {
              return (
                <div key={index} style={{ display: "flex" }}>
                  <InputField
                    key={`${index}_value`}
                    value={value.toString()}
                    name={"remove_coin_name_" + (index + 1).toString()}
                    label={"Remove Coin " + (index + 1).toString()}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                      if (
                        event.target.value ===
                        coinAcceptorConfig["remove_coins"][index]
                      ) {
                        // value didn't get changed so there is nothing todo
                        return;
                      }

                      coinAcceptorConfig["remove_coins"][index] =
                        event.target.value;

                      setConfig({ ...config });
                    }}
                  />
                  <Button
                    sx={{ marginTop: "10px" }}
                    color="accent"
                    variant="outlined"
                    onClick={() => {
                      coinAcceptorConfig["remove_coins"].splice(index, 1);
                      setConfig({ ...config });
                    }}
                  >
                    Remove from Removed Coins
                  </Button>
                  <br key={`${index}_br`} />
                </div>
              );
            })}
            <InputField
              fullWidth={false}
              label={"New Remove Coin"}
              name="new_remove_coin"
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (
                  coinAcceptorConfig["remove_coins"].filter(
                    (item) => item === event.target.value
                  ).length > 0
                ) {
                  alert("This coin is already removed");
                  return;
                }

                if (event.target.value !== "") {
                  coinAcceptorConfig["remove_coins"].push(event.target.value);
                }

                setConfig({ ...config });
              }}
            />
          </>
        </Box>
      )}
    </>
  );
};

const ContactlessPaymentOption: FC<{
  onChange: (event: SelectChangeEvent) => void;
  dropdownItems: DropdownItems;
}> = (props) => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  let initNfc = "";
  if (config["payment.nfc"]) {
    if (config["payment.nfc"] === true) {
      initNfc = "payter";
    } else {
      initNfc = "feigcvend";
    }
  }

  const [nfc, setNfc] = React.useState(initNfc);

  return (
    <>
      <Dropdown
        name="contactless"
        label={t("restoreConfigToolPage.payment.nfc")}
        value={nfc}
        onChange={(event: SelectChangeEvent) => {
          setNfc(event.target.value);

          props.onChange(event);
        }}
        items={props.dropdownItems}
      />
      {/* Feig IP input */}
      {nfc === "feigcvend" && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          <InputField
            name="feig-ip-input"
            test={isIpv4}
            label={"Feig IP input"}
            value={
              (config["payment.nfc"] as FeigTerminalConfig)["communication"][
              "settings"
              ]["address"]
            }
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              // validating a ipv4 adress with regex
              // https://stackoverflow.com/questions/5284147/validating-ipv4-addresses-with-regexp
              if (isIpv4.test(event.target.value)) {
                (config["payment.nfc"] as FeigTerminalConfig)["communication"][
                  "settings"
                ]["address"] = event.target.value;

                setConfig({ ...config });
              }
            }}
          />
          <InputField
            name="feig-port-input"
            test={/^\d+$/}
            label={"Feig Port input"}
            value={(config["payment.nfc"] as FeigTerminalConfig)[
              "communication"
            ]["settings"]["port"].toString()}
            type="number"
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              let intValue: number;
              if (event.target.value == "") {
                intValue = 22001; // default feig terminal port
              } else {
                intValue = parseInt(event.target.value);
              }

              if (isNaN(intValue) === false) {
                (config["payment.nfc"] as FeigTerminalConfig)["communication"][
                  "settings"
                ]["port"] = intValue;

                setConfig({ ...config });
              }
            }}
          />
        </Box>
      )}
    </>
  );
};

const VoucherPaymentOption: FC<{
  onChange: (event: SelectChangeEvent) => void;
  dropdownItems: DropdownItems;
}> = (props) => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const config = restoreConfig.config[0];

  let initVoucher = "";
  if (config["payment.voucher"]) {
    if (config["payment.voucher"] === true) {
      initVoucher = "voucher";
    }
  }
  const [voucher, setVoucher] = React.useState(initVoucher);
  return (
    <>
      <Dropdown
        name="voucher"
        label={t("restoreConfigToolPage.payment.voucher")}
        value={voucher}
        onChange={(event: SelectChangeEvent) => {
          setVoucher(event.target.value);

          props.onChange(event);
        }}
        items={props.dropdownItems}
      />
    </>
  );
};

const LicensePlateOption: FC<{
  onChange: (event: SelectChangeEvent) => void;
  dropdownItems: DropdownItems;
}> = (props) => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }
  const [config, setConfig] = restoreConfig.config;

  let initLicensePlate = "";
  if (config["payment.license_plate"]) {
    if (
      typeof config["payment.license_plate"] === "object" &&
      !Array.isArray(config["payment.license_plate"])
    ) {
      initLicensePlate = "license_plate";
    }
  }
  const [licensePlate, setLicensePlate] = React.useState(initLicensePlate);

  const licensePlateConfig: BigBrotherLicensePlateConfig = config[
    "payment.license_plate"
  ] as BigBrotherLicensePlateConfig;

  return (
    <>
      <Dropdown
        name="license_plate"
        label={t("restoreConfigToolPage.payment.license_plate")}
        value={licensePlate}
        onChange={(event: SelectChangeEvent) => {
          setLicensePlate(event.target.value);

          props.onChange(event);
        }}
        items={props.dropdownItems}
      />
      {licensePlate != "" && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          <SimulatorSwitch
            licensePlateConfig={licensePlateConfig}
            setConfig={setConfig}
            value={
              (config["payment.license_plate"] as Record<string, unknown>)[
                "simulator"
              ]
                ? true
                : false
            }
          />
          <InputField
            name="feig-ip-input"
            label={"License Plate Api Url Input"}
            value={licensePlateConfig["api_url_prefix"]}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              let api_url_prefix = event.target.value;

              (config["payment.license_plate"] as Record<string, unknown>)[
                "api_url_prefix"
              ] = api_url_prefix;

              if (event.target.value[event.target.value.length - 1] != "/") {
                api_url_prefix += "/";
              }

              (config["payment.license_plate"] as Record<string, unknown>)[
                "camera_check"
              ] = api_url_prefix + "version/1/carwash/1/check";

              (config["payment.license_plate"] as Record<string, unknown>)[
                "inform_status"
              ] = api_url_prefix + "version/1/carwash/1/status";

              (config["payment.license_plate"] as Record<string, unknown>)[
                "heartbeat"
              ] = api_url_prefix + "version/1/recorder/status";

              setConfig({ ...config });
            }}
          />
        </Box>
      )}
    </>
  );
};

const SimulatorSwitch: FC<{
  licensePlateConfig: BigBrotherLicensePlateConfig;
  setConfig: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  value: boolean;
}> = (props) => {
  const licensePlateSimulator: {
    active: boolean;
    license_plate: string;
    [x: string]: unknown;
  } = props.licensePlateConfig["simulator"] || {
    active: true,
    license_plate: "",
    license_plate_country: "",
    license_plate_confidence: 999,
    license_plate_country_confidence: 1
  };

  const simulatedLicensePlate: string =
    licensePlateSimulator["license_plate"] || "";

  const [state, setState] = React.useState(props.value);

  function deleteSimulatorConfig() {
    props.setConfig((prevConfig) => {
      delete (
        prevConfig["payment.license_plate"] as BigBrotherLicensePlateConfig
      )["simulator"];

      return { ...prevConfig };
    });
  }

  function updateSimulatorConfig() {
    props.setConfig((prevConfig) => {
      (prevConfig["payment.license_plate"] as Record<string, unknown>)[
        "simulator"
      ] = licensePlateSimulator;

      return { ...prevConfig };
    });
  }

  return (
    <>
      <FormGroup>
        <FormControlLabel
          label="Simulator"
          control={
            <Switch
              color="success"
              checked={state}
              onChange={() => {
                // on change the value of state is the same as before so I have to check if the new state, that is set afterwards will have the correct value
                if (state == false) {
                  updateSimulatorConfig();
                } else {
                  deleteSimulatorConfig();
                }

                setState(!state);
              }}
            />
          }
        />
      </FormGroup>
      {state == true && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          <InputField
            name="lp-simulator-license-plate"
            label={"Simulating License Plate"}
            value={simulatedLicensePlate}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              licensePlateSimulator["license_plate"] = event.target.value;

              updateSimulatorConfig();
            }}
          />
        </Box>
      )}
    </>
  );
};

const FleetcardsPaymentOption: FC<{
  onChange: (event: SelectChangeEvent) => void;
  dropdownItems: DropdownItems;
}> = (props) => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }

  const config = restoreConfig.config[0];

  let initFleetcards = "";
  if (config["payment.fleetcards"]) {
    initFleetcards = "fleetcards";
  }

  const [fleetcards, setFleetcards] = React.useState(initFleetcards);

  return (
    <>
      <Dropdown
        name="fleetcards"
        label={"Fleetcards"}
        value={fleetcards}
        onChange={(event: SelectChangeEvent) => {
          setFleetcards(event.target.value);

          props.onChange(event);
        }}
        items={props.dropdownItems}
      />
      {fleetcards !== "" && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          Currently there is no way to select fleetcard settings. This means you
          have to add them yourself, you can find the settings{" "}
          <a
            href="https://tsg-germany.atlassian.net/wiki/spaces/TSG/pages/598867981/Available+Configurations#Fleetcards"
            target="blank"
          >
            here
          </a>
        </Box>
      )}
    </>
  );
};

const PaymentOrder: FC = () => {
  const restoreConfig = React.useContext(RestoreConfigContext);

  if (!restoreConfig.config) {
    throw TypeError("Config not defined in context");
  }

  if (!restoreConfig.carwashConfig) {
    throw TypeError("CarwashConfig not defined in context");
  }

  const config = restoreConfig.config[0];
  const [carwashConfig, setCarwashConfig] = restoreConfig.carwashConfig;

  const theme = useTheme();

  const defaultList: string[] = [
    "cash",
    "nfc",
    "fleetcard",
    "voucher",
    "license_plate",
    "mobile"
  ];

  const [paymentOrder, setPaymentOrder] = React.useState<string[]>(defaultList);

  const [paymentOrderSwitch, setPaymentOrderSwitch] = React.useState(false);

  React.useEffect(() => {
    if (paymentOrderSwitch === false) {
      deletePaymentOrder();
      return;
    }

    const c: {
      payment_order?: string[];
      [x: string]: unknown;
    } = { ...carwashConfig };

    if (c["payment_order"]) {
      if (paymentOrder.length == 0) {
        // delete payment_order config
        delete c["payment_order"];
        setCarwashConfig(c);
      } else if (
        c["payment_order"].length !== paymentOrder.length || // new element in paymentOrder
        !c["payment_order"].every((val, index) => val === paymentOrder[index]) // paymentOrder changed order
      ) {
        // update the payment_order config
        c["payment_order"] = paymentOrder;
        setCarwashConfig(c);
      }
    } else {
      if (paymentOrder.length != 0) {
        // create payment order config
        c["payment_order"] = paymentOrder;
        setCarwashConfig(c);
      }
    }
  }, [paymentOrder, paymentOrderSwitch]);

  React.useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(config, "selfwash") === false) {
      deletePaymentOrder();
      return;
    }
  }, [config]);

  // Function to update list on drop
  const handleDrop = (droppedItem: {
    destination?: {
      index: number;
    };
    source: {
      index: number;
    };
  }) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;

    if (!Array.isArray(carwashConfig["payment_order"])) return;

    const updatedList = [...carwashConfig["payment_order"]];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setPaymentOrder(updatedList);
  };

  return (
    <>
      {Object.prototype.hasOwnProperty.call(config, "selfwash") && (
        <Box sx={{ width: "95%", marginLeft: "5%" }}>
          <FormGroup>
            <FormControlLabel
              label={<h3>Payment Order (only selfwash)</h3>}
              control={
                <Switch
                  color="success"
                  checked={paymentOrderSwitch}
                  onChange={() => {
                    setPaymentOrderSwitch(!paymentOrderSwitch);
                  }}
                />
              }
            />
          </FormGroup>
          {defaultList.length > 0 && paymentOrderSwitch && (
            <>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                  {(provided: {
                    innerRef: () => void;
                    placeholder: React.Component;
                    droppableProps: Record<string, unknown>;
                  }) => (
                    <div
                      className="list-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {paymentOrder.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided: {
                            innerRef: () => void;
                            draggableProps: Record<string, unknown>;
                            dragHandleProps: Record<string, unknown>;
                          }) => (
                            <div
                              className="item-container"
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <Box
                                sx={{
                                  margin: "10px 10px 10px 5%",
                                  width: "25%",
                                  padding: "14px 16.5px",
                                  border: 1,
                                  borderRadius: "4px",
                                  borderColor: theme.palette["borderColor"]
                                }}
                              >
                                {item}
                              </Box>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </Box>
      )}
    </>
  );

  function deletePaymentOrder() {
    delete carwashConfig["payment_order"];

    setCarwashConfig({ ...carwashConfig });
  }
};
