import actionCreatorFactory from "typescript-fsa";
import { GetUserDetailsParams, GetUserDetailsResponse } from "./types";

const actionCreator = actionCreatorFactory("AUTH");

export const logout = actionCreator.async<void, void, unknown>("LOGOUT");

export const getUserDetails = actionCreator.async<
  GetUserDetailsParams,
  GetUserDetailsResponse,
  unknown
>("GET_USER_DETAILS");
