import { AxiosResponse } from "axios";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { axiosInstance } from "../../../axios";
import { keycloak } from "../../../keycloak";
import * as actions from "./actions";
import {
  GetTransactionsParams,
  Transaction
} from "./types";
import { GetPaymentMethodResponse } from "../paymentMethods/types";

export function* getTransactionsSaga(
  action: Action<GetTransactionsParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<{
      total: number;
      transactions: Transaction[];
    }> = yield call(
      axiosInstance.get,
      `transactions`,
      {
        params: action.payload
      }
    );

    for (const transaction of response.data.transactions) {
      if (transaction.external) {
        continue;
      }

      const paymentMethodResponse: AxiosResponse<GetPaymentMethodResponse> = yield call(
        axiosInstance.get,
        `pay-methods/${transaction.pay_method_id}`
      )
      
      transaction.pay_method = paymentMethodResponse.data;
    }

    yield put(
      actions.getTransactions.done({
        params: action.payload,
        result: {
          data: response.data,
          totalPages: Math.ceil(response.data.total / 50)
        }
      })
    );
  } catch (e) {
    yield put(
      actions.getTransactions.failed({ params: action.payload, error: e })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getTransactions.started, getTransactionsSaga)
  ]);
}
