import { ChangeEvent, MouseEvent, ReactElement, ReactNode } from "react";

export enum TABLE_SORTING_TYPES {
  STRING,
  NUMBER,
  DATE
}

export type TableColumn = {
  key: string;
  label: string;
  sortingType?: TABLE_SORTING_TYPES;
};

export type TypeName = {
  key: string;
  label: string;
}

export type TableRowActionsMenuItem = {
  label: string;
  handler: (id: string) => void;
  isDisabled?: (row: any) => boolean;
};

export type BacklightTableProperties = {
  [key: string]: {
    conditionFn: (value: any) => string;
  }
}

export type TableRowActions = {
  [key: string]: {
    component: string;
    actionFn: any;
  }
}

export type TableRowComponents = { [key: string]: ReactElement }[]


export type TableProps<T> = {
  rows: T[];
  columns: TableColumn[];
  totalRow?: { [key in keyof T]: string };
  actions?: TableRowActionsMenuItem[];
  toolbarItems?: ReactNode;
  isLoading?: boolean;
  itemLink?: {
    column: keyof T;
    getURL?: (id: string) => string | undefined;
    isEnabled?: (item: T) => boolean;
  };
  isSearchEnabled?: boolean;
  isSortingEnabled?: boolean;
  isPaginationEnabled?: boolean;
  page?: number;
  rowsPerPage?: number;
  count?: number;
  onChangePage?: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onChangeRowsPerPage?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  searchString?: string;
  onChangeSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  backlightTableProperties?: BacklightTableProperties;
  tableRowActions?: TableRowActions;
  tableRowComponents?: TableRowComponents;
  rowsData?: any[] | null;
  defaultSortingColumn?: null | { column: string, order: string };
};

export enum ORDERS {
  ASC = "asc",
  DESC = "desc"
}
