import { DRAWER_WIDTH } from "components/Container/styles";
import styled from "styled-components";



export const Header = styled.header`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => theme.palette?.accent?.main};
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Toolbar = styled.div`
  align-self: flex-start;
  display: flex;
`;

export const UsernameContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing(1)};
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: ${({ theme }) => theme.spacing(12.5)} 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "header" "content";
  width: calc(100% - ${DRAWER_WIDTH}px);
  height: 100vh;
  margin-left: ${DRAWER_WIDTH}px;
`;

export const ContentContainer = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.primary.main};
`;
