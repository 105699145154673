import { Box, Button } from "@mui/material";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { GeneralSelection } from "./GeneralSelection";
import { DeviceSelection } from "./DeviceSelection/DeviceSelection";
import { PaymentSelection } from "./PaymentSelection";
import { RestoreConfigContext } from "./tools";

function download(filename: string, text: string) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const RestoreConfigTool: FC = () => {
  const { t } = useTranslation();

  const [config, setConfig] = React.useState({});
  const [carwashConfig, setCarwashConfig] = React.useState({});
  const [languages, setLanguages] = React.useState({});

  const [device, setDevice] = React.useState("");

  return (
    <>
      <RestoreConfigContext.Provider
        value={{
          config: [config, setConfig],
          carwashConfig: [carwashConfig, setCarwashConfig],
          languages: [languages, setLanguages]
        }}
      >
        <GeneralSelection />
        <DeviceSelection device={[device, setDevice]} />
        <PaymentSelection />

        <ShowConfig />
        <DownloadConfigButton />
      </RestoreConfigContext.Provider>
    </>
  );

  function ShowConfig() {
    const [renderConfig, setRenderConfig] = React.useState({
      relative_path: "../",
      languages: languages,
      carwash_config: carwashConfig,
      config: config
    });

    return (
      <Box sx={{ minWidth: "95%" }}>
        <h2>Restore Config</h2>
        <pre id="config">{JSON.stringify(renderConfig, null, 2)}</pre>
      </Box>
    );
  }

  function DownloadConfigButton() {
    return (
      <Button
        color="success"
        variant="outlined"
        onClick={() => {
          download(
            "restore-config.json",
            JSON.stringify(
              {
                relative_path: "../",
                languages: languages,
                carwash_config: carwashConfig,
                config: config
              },
              null,
              2
            )
          );
        }}
      >
        {t("restoreConfigToolPage.downloadButton")}
      </Button>
    );
  }
};
