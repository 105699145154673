import { FC, useEffect, useState } from 'react'
import { EventCalendar } from 'components/common/Calendar';
import { CalendarEvent, DisplayEvent, EventType, SlotInfo } from 'components/common/Calendar/types';
import { FormDialog } from 'components/common/Dialog/FormDialog';
import { DialogEvent, DIALOG_TYPES, ImportEvent, SelectedEvent, CheckedDialogEvent } from './types';
import { FIELD_TYPES, FormDialogProps } from 'components/common/Dialog/FormDialog/types';

import moment from 'moment';
import { bool, boolean, date, number, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useMount } from 'hooks/useMount';
import { deleteIcsEvent, eventsFromIcs, updateIcsEvents } from './ics';
import { useDispatch, useSelector } from 'react-redux';
import { stationsSelector } from '../../../store/modules/stations/selectors';
import { Station } from '../../../store/modules/stations/types';
import { stationCalendarSelector } from '../../../store/modules/calendar/selectors';

import * as calendarActions from "../../../store/modules/calendar/actions";
import * as stationActions from "../../../store/modules/stations/actions";
import { useUnmount } from 'hooks/useUnmount';
import { Dropdown } from 'components/common/Dropdown';
import { SelectChangeEvent } from '@mui/material';
import { enqueueSnackbar } from '../../../store/modules/notifications/actions';
import { put } from 'redux-saga/effects';
import { EnvironmentPlugin } from 'webpack';



export const CalendarPage: FC = () => {
    const [t, i18n] = useTranslation();

    const dispatch = useDispatch();

    const stations: Station[] = Object.values(useSelector(stationsSelector));
    const [selectedCalendar, setSelectedCalendar] = useState<string>('default');
    const calendars: StationCalendarMap = useSelector(stationCalendarSelector);

    const [selectedEvent, setSelectedEvent] = useState<SelectedEvent | null>(null);
    const [events, setEvents] = useState<CalendarEvent[]>([]);

    useMount(() => {
        dispatch(stationActions.getStations.started({}));
    });

    useEffect(() => {
        if (stations.length > 0 && stations.length !== Object.keys(calendars).length - 1) {
            dispatch(calendarActions.getStationCalendar.started({ stationIds: stations.map(station => station.id) }));
        }
    }, [stations]);

    useEffect(() => {
        if (calendars[selectedCalendar] === undefined) {
            return;
        }

        const ics = calendars[selectedCalendar];
        const events = eventsFromIcs(ics);
        setEvents(events);
    }, [calendars, selectedCalendar]);


    useUnmount(() => {
        dispatch(calendarActions.clear());
    });

    const getCalendarSelectOptions: () => Record<string, { name: string }> = () => {
        const items = {};

        items["default"] = {
            name: t('calendar.dropdown.default')
        };

        stations.forEach(station => {
            items[station.id] = {
                name: station.name
            };
        });

        return items;
    };

    const storeIcs = (action: (string, any) => string, argument) => {
        if (selectedCalendar === 'default') {
            stations.forEach(station => {
                const ics = calendars[station.id];
                const newIcs = action(ics, argument);
                dispatch(calendarActions.putStationCalendar.started({ stationId: station.id, Calendar: newIcs }));
                calendars[station.id] = newIcs;
            });

            const ics = calendars[selectedCalendar];
            const newIcs = action(ics, argument);
            dispatch(calendarActions.putCalendar.started({ Calendar: newIcs }));
            calendars[selectedCalendar] = newIcs;
            return;
        }

        const ics = calendars[selectedCalendar];
        const newIcs = action(ics, argument);
        dispatch(calendarActions.putStationCalendar.started({ stationId: selectedCalendar, Calendar: newIcs }));
        calendars[selectedCalendar] = newIcs;
    }


    const [dialog, setDialog] = useState<{
        type: DIALOG_TYPES;
        isOpened: boolean;
    }>({ type: DIALOG_TYPES.CREATE, isOpened: false });

    const closeDialog = () => {
        setDialog({ ...dialog, isOpened: false });
        setSelectedEvent(null);
    }

    const checkEvent = (event: DialogEvent): boolean => {
        if (event.title == null || event.title.length === 0) {
            dispatch(enqueueSnackbar({ message: 'calendar.dialogs.error.title', variant: 'error' }));
            return false;
        }

        if (event.start == null) {
            dispatch(enqueueSnackbar({ message: 'calendar.dialogs.error.start', variant: 'error' }));
            return false;
        }

        if (event.end == null) {
            dispatch(enqueueSnackbar({ message: 'calendar.dialogs.error.end', variant: 'error' }));
            return false;
        }

        if (event.start > event.end) {
            dispatch(enqueueSnackbar({ message: 'calendar.dialogs.error.start-before-end', variant: 'error' }));
            return false;
        }

        if (event.type == null || isNaN(parseInt(event.type.toString())) || event.type === EventType.UNKNOWN) {
            dispatch(enqueueSnackbar({ message: 'calendar.dialogs.error.type', variant: 'error' }));
            return false;
        }

        if (event.type === EventType.HAPPY_HOUR &&
            (event.percentage == null || isNaN(parseInt(event.percentage.toString())) || event.percentage > 100 || event.percentage < 0)) {
            dispatch(enqueueSnackbar({ message: 'calendar.dialogs.error.percentage', variant: 'error' }));
            return false;
        }

        if (event.recurrency !== false) {
            if (event.count != null && event.count <= 0) {
                dispatch(enqueueSnackbar({ message: 'calendar.dialogs.error.count.positive-value', variant: 'error' }));
                return false;
            }


            if (event.interval == null || isNaN(parseInt(event.interval.toString())) || event.interval < 1) {
                dispatch(enqueueSnackbar({ message: 'calendar.dialogs.error.interval', variant: 'error' }));
                return false;
            }

            if (event.frequency == null || event.frequency.length === 0) {
                dispatch(enqueueSnackbar({ message: 'calendar.dialogs.error.frequency', variant: 'error' }));
                return false;
            }
        }


        return true;
    }

    const createEvent = (newEvent: DialogEvent) => {
        if (!checkEvent(newEvent)) {
            return;
        }

        const checkedEvent = newEvent as CheckedDialogEvent;

        const start = moment(checkedEvent.start).toDate();
        const end = moment(checkedEvent.end).toDate();

        if (checkedEvent.allDay) {
            start.setHours(12, 0, 0, 0);
            end.setHours(12, 0, 0, 0);
        }

        const event: CalendarEvent = {
            title: checkedEvent.title,
            allDay: checkedEvent.allDay,
            start: start,
            end: end,
            resource: {
                id: crypto["randomUUID"](),
                type: checkedEvent.type,
                percentage: checkedEvent.percentage
            }
        };

        if (checkedEvent.recurrency !== false) {

            event.recurrency = {
                interval: checkedEvent.interval,
                frequency: checkedEvent.frequency
            }

            if (checkedEvent.until !== undefined) {
                event.recurrency.until = checkedEvent.until;
            }

            if (checkedEvent.count !== undefined) {
                event.recurrency.count = checkedEvent.count;
            }
        }


        storeIcs(updateIcsEvents, [event]);
        setEvents([...events, event]);
        closeDialog();
    }

    const editEvent = (event: DialogEvent) => {
        if (!selectedEvent) {
            return;
        }

        if (!checkEvent(event)) {
            return;
        }
        const checkedEvent = event as CheckedDialogEvent;

        const start = moment(checkedEvent.start).toDate();
        const end = moment(checkedEvent.end).toDate();

        if (checkedEvent.allDay) {
            start.setHours(0, 0, 0, 0);
            end.setHours(23, 59, 59, 999);
        }

        const oldEvent: CalendarEvent = selectedEvent.calendarEvent;

        let uid = oldEvent.resource.id;

        if (oldEvent.recurrency !== undefined) {
            oldEvent.recurrency.until = moment(selectedEvent.start).add(-1, oldEvent.recurrency.frequency).toDate();
            uid = crypto["randomUUID"]();
        }

        const newEvent: CalendarEvent = {
            title: checkedEvent.title,
            allDay: checkedEvent.allDay,
            start: start,
            end: end,
            resource: {
                id: uid,
                type: checkedEvent.type,
                percentage: checkedEvent.percentage
            }
        };

        if (checkedEvent.recurrency !== false) {
            newEvent.recurrency = {
                interval: checkedEvent.interval,
                frequency: checkedEvent.frequency
            };

            if (checkedEvent.count !== undefined) {
                newEvent.recurrency.count = checkedEvent.count;
            }

            if (checkedEvent.until !== undefined) {
                newEvent.recurrency.until = checkedEvent.until;
            }
        }


        const idx = events.findIndex(e => e.resource.id === oldEvent.resource.id)

        if (uid === oldEvent.resource.id) {
            events[idx] = newEvent;
        } else if (idx !== -1) {
            events[idx] = oldEvent;
            events.push(newEvent);
        }

        storeIcs(updateIcsEvents, [oldEvent, newEvent]);

        setEvents([...events]);
        closeDialog();
    }

    const deleteAllSelectedEvent = () => {
        if (!selectedEvent) {
            return;
        }

        const calendarEventId = selectedEvent.calendarEvent.resource.id;

        storeIcs(deleteIcsEvent, selectedEvent.calendarEvent);
        setEvents(events.filter(e => e.resource.id !== calendarEventId));
        closeDialog();
    }

    const deleteFollowingSelectedEvent = () => {
        if (!selectedEvent) {
            return;
        }

        const calendarEvent = selectedEvent.calendarEvent;

        if (calendarEvent.recurrency === undefined) {
            return;
        }

        calendarEvent.recurrency.until = moment(selectedEvent.start).add(-1, calendarEvent.recurrency.frequency).toDate();

        const idx = events.findIndex(e => e.resource.id === calendarEvent.resource.id);

        if (idx !== -1) {
            events[idx] = calendarEvent;
            storeIcs(updateIcsEvents, [calendarEvent]);
        } else {
            console.error("Error: event not found");
        }

        setEvents([...events]);
        closeDialog();
    }

    const deleteSelectedEvent = () => {
        if (!selectedEvent) {
            return;
        }

        const calendarEvent = selectedEvent.calendarEvent;

        if (calendarEvent.recurrency === undefined) {
            storeIcs(deleteIcsEvent, calendarEvent);
            setEvents(events.filter(e => e.resource.id !== calendarEvent.resource.id));
            closeDialog();
            return;
        }

        if (calendarEvent.start === selectedEvent.start) {
            calendarEvent.start = moment(selectedEvent.start)
                .add(calendarEvent.recurrency.interval, calendarEvent.recurrency.frequency)
                .toDate();

            storeIcs(updateIcsEvents, [calendarEvent]);
            setEvents([...events]);
            closeDialog();
            return;
        }

        if (calendarEvent.recurrency.exceptions === undefined) {
            calendarEvent.recurrency.exceptions = [];
        }

        calendarEvent.recurrency.exceptions.push(selectedEvent.start);

        const idx = events.findIndex(e => e.resource.id === calendarEvent.resource.id);

        if (idx !== -1) {
            events[idx] = calendarEvent;
        } else {
            console.error("Error: event not found");
        }

        storeIcs(updateIcsEvents, [calendarEvent]);
        setEvents([...events]);
        closeDialog();
    }

    const importEvents = (importEvent: ImportEvent) => {
        if (importEvent.file.length === 0) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target?.result;
            if (!text) {
                return;
            }

            const newEvents = eventsFromIcs(text.toString(), importEvent.type);
            if (importEvent.type === EventType.HAPPY_HOUR) {
                for (const event of newEvents) {
                    event.resource.percentage = importEvent.percentage;
                }
            }

            storeIcs(updateIcsEvents, newEvents);
            setEvents([...events, ...newEvents]);
        };

        for (let i = 0; i < importEvent.file.length; i++) {
            const file = importEvent.file[i];
            reader.readAsText(file);
        }

        closeDialog();
    }

    const eventTypeSelectOptions = [
        { value: EventType.HAPPY_HOUR, label: "Happy Hour" },
        { value: EventType.HOLIDAY, label: "Holiday" },
    ];

    const endConditionDefaultValue = () => {
        const recurrency = selectedEvent?.calendarEvent.recurrency;

        if (recurrency?.until) {
            return 'date';
        }

        if (recurrency?.count) {
            return 'count';
        }

        return 'never';
    }

    const dialogProps: {
        [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
    } = {
        [DIALOG_TYPES.CREATE]: {
            title: t("calendar.dialogs.create-menu.title"),
            confirmButtonLabel: t("calendar.dialogs.create-menu.actions.confirm"),
            cancelButtonLabel: t("calendar.dialogs.create-menu.actions.cancel"),
            onConfirm: createEvent,
            fields: [
                {
                    name: "title",
                    label: t("calendar.dialogs.create-edit-menu.name"),
                    rules: date().required(),
                    type: FIELD_TYPES.TEXT,
                    defaultValue: selectedEvent?.title,
                },
                {
                    name: "allDay",
                    label: t("calendar.dialogs.create-edit-menu.all-day"),
                    rules: boolean().required(),
                    type: FIELD_TYPES.SWITCH,
                    defaultValue: selectedEvent?.allDay,
                },
                {
                    name: "start",
                    label: t("calendar.dialogs.create-edit-menu.start"),
                    rules: date().required(),
                    type: FIELD_TYPES.DATE_TIME_PICKER,
                    defaultValue: selectedEvent?.start,
                    isHidden: (fieldValues) => fieldValues.allDay as boolean
                },
                {
                    name: "end",
                    label: t("calendar.dialogs.create-edit-menu.end"),
                    rules: date().required(),
                    type: FIELD_TYPES.DATE_TIME_PICKER,
                    defaultValue: selectedEvent?.end,
                    minDate: (fieldValues) => fieldValues.start,
                    isHidden: (fieldValues) => fieldValues.allDay as boolean
                },
                {
                    name: "start",
                    label: t("calendar.dialogs.create-edit-menu.start"),
                    rules: date().required(),
                    type: FIELD_TYPES.DATE_PICKER,
                    isStartOfTheDay: true,
                    defaultValue: selectedEvent?.start,
                    isHidden: (fieldValues) => !fieldValues.allDay
                },
                {
                    name: "end",
                    label: t("calendar.dialogs.create-edit-menu.end"),
                    rules: date().required(),
                    type: FIELD_TYPES.DATE_PICKER,
                    isEndOfTheDay: true,
                    defaultValue: selectedEvent?.end,
                    minDate: (fieldValues) => fieldValues.start,
                    isHidden: (fieldValues) => !fieldValues.allDay
                },
                {
                    name: "type",
                    label: t("calendar.dialogs.create-edit-menu.type"),
                    rules: string().required(),
                    type: FIELD_TYPES.SELECT,
                    options: eventTypeSelectOptions,
                    defaultValue: selectedEvent?.calendarEvent.resource.type,
                },
                {
                    name: "percentage",
                    label: t("calendar.dialogs.create-edit-menu.percentage"),
                    rules: number().required(),
                    type: FIELD_TYPES.NUMBER,
                    isHidden: (fieldValues) => fieldValues.type !== EventType.HAPPY_HOUR
                },
                {
                    name: "recurrency",
                    label: t("calendar.dialogs.create-edit-menu.recurring"),
                    rules: bool().required(),
                    type: FIELD_TYPES.SWITCH,
                    defaultValue: selectedEvent?.calendarEvent.recurrency != null ?? false,
                },
                {
                    name: "endCondition",
                    label: t("calendar.dialogs.create-edit-menu.end-condition"),
                    rules: string().required(),
                    type: FIELD_TYPES.SELECT,
                    isHidden: (fieldValues) => !fieldValues.recurrency,
                    options: [
                        { value: "count", label: t("calendar.dialogs.create-edit-menu.end-condition-options.count") },
                        { value: "date", label: t("calendar.dialogs.create-edit-menu.end-condition-options.date") },
                        { value: "never", label: t("calendar.dialogs.create-edit-menu.end-condition-options.never") }
                    ],
                },
                {
                    name: "count",
                    label: t("calendar.dialogs.create-edit-menu.end-condition-options.count"),
                    rules: number().required(),
                    type: FIELD_TYPES.NUMBER,
                    isHidden: (fieldValues) => !fieldValues.recurrency || fieldValues.endCondition !== "count",
                    defaultValue: selectedEvent?.calendarEvent.recurrency?.count,
                },
                {
                    name: "until",
                    label: t("calendar.dialogs.create-edit-menu.end-condition-options.date"),
                    rules: date().required(),
                    type: FIELD_TYPES.DATE_PICKER,
                    isHidden: (fieldValues) => !fieldValues.recurrency || fieldValues.endCondition !== "date",
                    defaultValue: selectedEvent?.calendarEvent.recurrency?.until,
                },
                {
                    name: "interval",
                    label: t("calendar.dialogs.create-edit-menu.interval"),
                    rules: number().required(),
                    type: FIELD_TYPES.NUMBER,
                    isHidden: (fieldValues) => !fieldValues.recurrency,
                    defaultValue: selectedEvent?.calendarEvent.recurrency?.interval,
                },
                {
                    name: "frequency",
                    label: t("calendar.dialogs.create-edit-menu.frequency"),
                    rules: string().required(),
                    type: FIELD_TYPES.SELECT,
                    isHidden: (fieldValues) => !fieldValues.recurrency,
                    options: [
                        { value: "day", label: t("calendar.dialogs.create-edit-menu.frequency-options.day") },
                        { value: "week", label: t("calendar.dialogs.create-edit-menu.frequency-options.week") },
                        { value: "month", label: t("calendar.dialogs.create-edit-menu.frequency-options.month") },
                        { value: "year", label: t("calendar.dialogs.create-edit-menu.frequency-options.year") }
                    ],
                }
            ],
        },
        [DIALOG_TYPES.IMPORT]: {
            title: t("calendar.dialogs.import-menu.title"),
            confirmButtonLabel: t("calendar.dialogs.import-menu.actions.confirm"),
            cancelButtonLabel: t("calendar.dialogs.import-menu.actions.cancel"),
            onConfirm: importEvents,
            fields: [
                {
                    name: "file",
                    label: t("calendar.dialogs.import-menu.file"),
                    type: FIELD_TYPES.FILE,
                    acceptFileTypes: ".ics"
                },
                {
                    name: "type",
                    label: t("calendar.dialogs.import-menu.type"),
                    rules: string().required(),
                    type: FIELD_TYPES.SELECT,
                    options: eventTypeSelectOptions,
                },
                {
                    name: "percentage",
                    label: t("calendar.dialogs.create-edit-menu.percentage"),
                    rules: number().required(),
                    type: FIELD_TYPES.NUMBER,
                    isHidden: (fieldValues) => fieldValues.type !== EventType.HAPPY_HOUR,
                }
            ]
        },
        [DIALOG_TYPES.EDIT]: {
            title: t("calendar.dialogs.edit-menu.title"),
            confirmButtonLabel: t("calendar.dialogs.edit-menu.actions.confirm"),
            cancelButtonLabel: t("calendar.dialogs.edit-menu.actions.cancel"),
            onConfirm: editEvent,
            extraButtons: [
                {
                    label: t("calendar.dialogs.edit-menu.actions.delete-all"),
                    handler: deleteAllSelectedEvent,
                    color: {
                        background: "red",
                        text: "white"
                    },
                    locked: () => selectedEvent?.calendarEvent.recurrency == null
                },
                {
                    label: t("calendar.dialogs.edit-menu.actions.delete-future"),
                    handler: deleteFollowingSelectedEvent,
                    color: {
                        background: "red",
                        text: "white"
                    },
                    locked: () => selectedEvent?.calendarEvent.recurrency == null
                },
                {
                    label: t("calendar.dialogs.edit-menu.actions.delete-this"),
                    handler: deleteSelectedEvent,
                    color: {
                        background: "red",
                        text: "white"
                    }
                }
            ],
            fields: [
                {
                    name: "title",
                    label: t("calendar.dialogs.create-edit-menu.name"),
                    rules: string().required(),
                    type: FIELD_TYPES.TEXT,
                    defaultValue: selectedEvent?.title,
                },
                {
                    name: "allDay",
                    label: t("calendar.dialogs.create-edit-menu.all-day"),
                    rules: boolean().required(),
                    type: FIELD_TYPES.SWITCH,
                    defaultValue: selectedEvent?.allDay,
                },
                {
                    name: "start",
                    label: t("calendar.dialogs.create-edit-menu.start"),
                    rules: string().required(),
                    type: FIELD_TYPES.DATE_TIME_PICKER,
                    defaultValue: selectedEvent?.start,
                    isHidden: (fieldValues) => fieldValues.allDay as boolean
                },
                {
                    name: "end",
                    label: t("calendar.dialogs.create-edit-menu.end"),
                    rules: string().required(),
                    type: FIELD_TYPES.DATE_TIME_PICKER,
                    defaultValue: selectedEvent?.end,
                    minDate: (fieldValues) => fieldValues.start,
                    isHidden: (fieldValues) => fieldValues.allDay as boolean
                },
                {
                    name: "start",
                    label: t("calendar.dialogs.create-edit-menu.start"),
                    rules: string().required(),
                    type: FIELD_TYPES.DATE_PICKER,
                    defaultValue: selectedEvent?.start,
                    isStartOfTheDay: true,
                    isHidden: (fieldValues) => !fieldValues.allDay
                },
                {
                    name: "end",
                    label: t("calendar.dialogs.create-edit-menu.end"),
                    rules: date().required(),
                    type: FIELD_TYPES.DATE_PICKER,
                    defaultValue: selectedEvent?.end,
                    isEndOfTheDay: true,
                    minDate: (fieldValues) => fieldValues.start,
                    isHidden: (fieldValues) => !fieldValues.allDay
                },
                {
                    name: "type",
                    label: t("calendar.dialogs.create-edit-menu.type"),
                    rules: string().required(),
                    type: FIELD_TYPES.SELECT,
                    options: eventTypeSelectOptions,
                    defaultValue: selectedEvent?.calendarEvent.resource.type,
                },
                {
                    name: "percentage",
                    label: t("calendar.dialogs.create-edit-menu.percentage"),
                    rules: number().required(),
                    type: FIELD_TYPES.NUMBER,
                    isHidden: (fieldValues) => fieldValues.type !== EventType.HAPPY_HOUR,
                    defaultValue: selectedEvent?.calendarEvent.resource.percentage
                },
                {
                    name: "recurrency",
                    label: t("calendar.dialogs.create-edit-menu.recurring"),
                    rules: bool().required(),
                    type: FIELD_TYPES.SWITCH,
                    defaultValue: selectedEvent?.calendarEvent.recurrency != null ?? false,
                },
                {
                    name: "endCondition",
                    label: t("calendar.dialogs.create-edit-menu.end-condition"),
                    rules: string().required(),
                    type: FIELD_TYPES.SELECT,
                    isHidden: (fieldValues) => !fieldValues.recurrency,
                    options: [
                        { value: "count", label: t("calendar.dialogs.create-edit-menu.end-condition-options.count") },
                        { value: "date", label: t("calendar.dialogs.create-edit-menu.end-condition-options.date") },
                        { value: "never", label: t("calendar.dialogs.create-edit-menu.end-condition-options.never") }
                    ],
                    defaultValue: endConditionDefaultValue,
                },
                {
                    name: "count",
                    label: t("calendar.dialogs.create-edit-menu.end-condition-options.count"),
                    rules: number().required(),
                    type: FIELD_TYPES.NUMBER,
                    isHidden: (fieldValues) => !fieldValues.recurrency || fieldValues.endCondition !== "count",
                    defaultValue: selectedEvent?.calendarEvent.recurrency?.count,
                },
                {
                    name: "until",
                    label: t("calendar.dialogs.create-edit-menu.end-condition-options.date"),
                    rules: date().required(),
                    type: FIELD_TYPES.DATE_PICKER,
                    isHidden: (fieldValues) => !fieldValues.recurrency || fieldValues.endCondition !== "date",
                    defaultValue: selectedEvent?.calendarEvent.recurrency?.until,
                },
                {
                    name: "interval",
                    label: t("calendar.dialogs.create-edit-menu.interval"),
                    rules: number().required(),
                    type: FIELD_TYPES.NUMBER,
                    isHidden: (fieldValues) => !fieldValues.recurrency,
                    defaultValue: selectedEvent?.calendarEvent.recurrency?.interval,
                },
                {
                    name: "frequency",
                    label: t("calendar.dialogs.create-edit-menu.frequency"),
                    rules: string().required(),
                    type: FIELD_TYPES.SELECT,
                    isHidden: (fieldValues) => !fieldValues.recurrency,
                    options: [
                        { value: "day", label: t("calendar.dialogs.create-edit-menu.frequency-options.day") },
                        { value: "week", label: t("calendar.dialogs.create-edit-menu.frequency-options.week") },
                        { value: "month", label: t("calendar.dialogs.create-edit-menu.frequency-options.month") },
                        { value: "year", label: t("calendar.dialogs.create-edit-menu.frequency-options.year") }
                    ],
                    defaultValue: selectedEvent?.calendarEvent.recurrency?.frequency,
                }
            ],
        },
    }

    const onSelectEvent = (event: DisplayEvent) => {
        const id = event.resource.id;
        const selectedCalendarEvent: CalendarEvent | undefined = events.find((event) => event.resource.id === id);

        if (selectedCalendarEvent == null) {
            console.error("Event not found", event);
            return;
        }

        const selectEvent: SelectedEvent = {
            title: event.title,
            start: event.start,
            end: event.end,
            allDay: event.allDay,
            calendarEvent: selectedCalendarEvent,
        }

        setSelectedEvent(selectEvent);
        setDialog({ type: DIALOG_TYPES.EDIT, isOpened: true })
    };

    const onSelectSlot = (slot: SlotInfo) => {
        if (slot.action === "click") {
            return;
        }

        console.log(slot);

        const start = moment(slot.start);
        const end = moment(slot.end);


        const startMillisDay = (parseInt(start.format("x"))) % (24 * 60 * 60 * 1000);
        const endMillisDay = (parseInt(end.format("x"))) % (24 * 60 * 60 * 1000);

        const allDay = startMillisDay === 0 && endMillisDay === 0;

        if (allDay) {
            end.subtract(1, "millisecond");
        }

        const newEvent: CalendarEvent = {
            title: "",
            allDay: allDay,
            start: start.toDate(),
            end: end.toDate(),
            resource: {
                id: crypto["randomUUID"](),
                type: EventType.UNKNOWN
            }
        };

        const selectedEvent: SelectedEvent = {
            title: newEvent.title,
            allDay: newEvent.allDay,
            start: newEvent.start,
            end: newEvent.end,
            calendarEvent: newEvent,
        }

        console.log(selectedEvent);

        setSelectedEvent(selectedEvent);
        setDialog({ type: DIALOG_TYPES.CREATE, isOpened: true })
    };

    return (<>

        <Dropdown
            name="selectedCalendar"
            label={t("calendar.dropdown.label")}
            value={selectedCalendar}
            onChange={(event: SelectChangeEvent) => setSelectedCalendar(event.target.value)}
            items={getCalendarSelectOptions()}
        />

        <EventCalendar
            events={events}
            createEvent={() => setDialog({ type: DIALOG_TYPES.CREATE, isOpened: true })}
            importEvents={() => setDialog({ type: DIALOG_TYPES.IMPORT, isOpened: true })}
            onSelectEvent={onSelectEvent}
            onSelectSlot={onSelectSlot}
        />

        <FormDialog
            isOpened={dialog.isOpened}
            onCancel={closeDialog}
            extraButtons={dialogProps[dialog.type].extraButtons}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
        />
    </>
    )
}