import { useState, FC, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { DateTimePickerInterface } from "./types";
import { useMount } from "hooks/useMount";
import { useTranslation } from "react-i18next";
import DateTimePicker from "@mui/lab/DateTimePicker";

export const BasicDateTimePicker: FC<DateTimePickerInterface> = ({
  label,
  handleValue,
  initValue,
  minValue,
  maxValue,
  validationMessage,
  error,
}: DateTimePickerInterface) => {
  const [value, setValue] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState<Date | undefined | any>(undefined);
  const [maxDate, setMaxDate] = useState<Date | undefined | any>(undefined);
  const { i18n } = useTranslation();

  const maskMap = {
    en: '__/__/____ __:__',
    de: '__.__.____ __:__',
  };

  const isValidDate = (date) => date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);

  useMount(() => {
    if (initValue) {
      setValue(new Date(initValue));
    }
  });
  useEffect(() => {
    if (minValue) setMinDate(minValue);
  }, [minValue])
  useEffect(() => {
    if (maxValue) setMaxDate(maxValue);
  }, [maxValue])

  return (
    <DateTimePicker
      label={label}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      mask={maskMap[i18n.language]}
      disableHighlightToday={true}
      onChange={(newValue: Date | null) => {
        setValue(newValue);
        if (isValidDate(newValue)) {
          handleValue(newValue);
        } else {
          handleValue(null);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={"standard"}
          fullWidth={true}
          color={"accent"}
          style={{ marginTop: "16px" }}
          helperText={validationMessage}
          error={error}
          autoComplete={"off"}
        />
      )}
    />
  );
};
