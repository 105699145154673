import { useState, FC, ChangeEvent, useEffect } from "react";
import { SimpleSelectInterface } from "components/common/FormFields/SimpleSelect/types";
import { SelectOption } from "components/common/Dialog/FormDialog/types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useMount } from "hooks/useMount";

export const SimpleSelect: FC<SimpleSelectInterface> = ({
  label,
  options = [],
  handleValue,
  autocomplete,
  initValue = null,
  payloadValue,
  error,
  invisible = false,
  validationMessage,
}) => {
  const [option, setOption] = useState<SelectOption | null>(options.find((option) => option.value === initValue) ?? null);

  useEffect(() => {
    if (payloadValue) {
      setOption((payloadValue as SelectOption));
      handleValue((payloadValue as SelectOption).value)
    }
  }, [payloadValue])

  return (
    <Autocomplete
      options={options}
      value={option}
      onChange={(e, option) => {
        setOption(option);
        handleValue((option as SelectOption)?.value);
      }}
      getOptionLabel={(option: SelectOption) =>
        option.label
      }
      renderOption={(
        props,
        option: SelectOption
      ) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      fullWidth={true}
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value;
      }}
      style={invisible ? { height: "0", opacity: 0 } : { marginTop: "16px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={"standard"}
          label={label}
          fullWidth={true}
          autoComplete={autocomplete}
          error={error}
          color={"accent"}
          helperText={validationMessage}
        />
      )}
    />
  );
}
