import ClickAwayListener from "@mui/base/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import { usePrevious } from "hooks/usePrevious";
import { FC, KeyboardEvent, useEffect } from "react";
import { ELEMENT_IDS } from "../../../constants";
import * as s from "./styles";
import { MenuProps } from "./types";

export const Menu: FC<MenuProps> = ({
  items,
  onClose,
  anchorEl,
  isOpened,
  boundariesElement,
  placement
}: MenuProps) => {
  const previousIsOpened = usePrevious(isOpened);

  const handleClose = (e: MouseEvent | TouchEvent) => {
    if (anchorEl && anchorEl?.contains(e.target as HTMLElement)) {
      return;
    }

    if (onClose) {
      onClose();
    }
  };

  const handlePaperKeyDown = (e: KeyboardEvent) => {
    if (["Escape", "Tab"].includes(e.key) && onClose) {
      e.preventDefault();
      onClose();
    }
  };

  useEffect(() => {
    if (previousIsOpened && !isOpened && anchorEl) {
      anchorEl?.focus();
    }
  }, [anchorEl, previousIsOpened, isOpened]);

  return (
    <s.Popper
      placement={placement || "bottom-start"}
      open={isOpened}
      anchorEl={anchorEl}
      transition={true}
      modifiers={[
        {
          name: "flip",
          enabled: false
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            rootBoundary:
              boundariesElement ||
              document.getElementById(ELEMENT_IDS.REACT_ROOT)
          }
        }
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper onKeyDown={handlePaperKeyDown} elevation={8}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={isOpened}>
                {items?.map((item, i) => (
                  <MenuItem
                    onClick={item.onClick}
                    key={i}
                    disabled={item.isDisabled}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </s.Popper>
  );
};
