import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory("CALENDAR");

export const getStationCalendar = actionCreator.async<
    GetStationCalendarParams,
    GetStationCalendarResponse,
    unknown
>("GET_STATION_CALENDAR");

export const putCalendar = actionCreator.async<
    PutCalendarParams,
    PutCalendarResponse,
    unknown
>("PUT_CALENDAR");

export const putStationCalendar = actionCreator.async<
    PutStationCalendarParams,
    PutStationCalendarResponse,
    unknown
>("PUT_STATION_CALENDAR");


export const clear = actionCreator<void>("CLEAR");