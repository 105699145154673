import { useCallback, FC } from 'react'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button';
import { closeSnackbar } from '../../../store/modules/notifications/actions';
import { useTranslation } from "react-i18next";

type CloseSnackbarParams = {
  id: string;
}

export const CloseSnackbar: FC<CloseSnackbarParams> = ({ id }): any => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = useCallback((id) => {
    dispatch(closeSnackbar(id))
  }, [dispatch])

  return (
    <Button
      onClick={() => handleClose(id)}
      sx={{ color: '#fff' }}
    >
      {t('uiComponents.closeSnackbar.title')}
    </Button>
  )
}
