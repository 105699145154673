import { ArrowLeft, ArrowRight, Today } from '@mui/icons-material';
import { FC } from 'react';
import { NavButtonGroup, ToolbarWrapper } from './styles';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const navigateContants = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE'
};

export const views = {
  YEAR: 'year',
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda'
};

type Props = {
  onNavigate: Function
  onView: Function
  label: string
  onSetSelectedCalendarsClicked: Function
  onOpenConfigClicked: Function
  createEvent: () => void
  importEvents: () => void
}

export const CalendarToolbar: FC<Props> = (props: Props) => {
  const [t, i18n] = useTranslation();

  const { label, onNavigate, onView, createEvent, importEvents } = props
  function navigate(action: any) {
    onNavigate(action);
  }

  function view(view: any) {
    onView(view);
  }

  return (
    <ToolbarWrapper>
      <NavButtonGroup variant="outlined">
        <Button onClick={navigate.bind(null, navigateContants.PREVIOUS)}><ArrowLeft /></Button>
        <Button className="today-btn" onClick={navigate.bind(null, navigateContants.TODAY)}><Today /></Button>
        <Button onClick={navigate.bind(null, navigateContants.NEXT)}><ArrowRight /></Button>
      </NavButtonGroup>

      <span className="rbc-toolbar-label"><b>{label}</b></span>

      <NavButtonGroup variant="contained">
        <Button color="warning" onClick={createEvent}>{t("calendar.toolbar.event-creation.create_event")}</Button>
        <Button color="error" onClick={importEvents}>{t("calendar.toolbar.event-creation.import_events")}</Button>
      </NavButtonGroup>

      <NavButtonGroup variant="contained" color="success" >
        <Button onClick={view.bind(null, views.MONTH)}>{t("calendar.toolbar.view-selection.month")}</Button>
        <Button onClick={view.bind(null, views.WEEK)}>{t("calendar.toolbar.view-selection.week")}</Button>
        <Button onClick={view.bind(null, views.DAY)}>{t("calendar.toolbar.view-selection.day")}</Button>
        <Button onClick={view.bind(null, views.AGENDA)}>{t("calendar.toolbar.view-selection.agenda")}</Button>
      </NavButtonGroup>
    </ToolbarWrapper>
  );
};
