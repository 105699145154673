import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { GetTransactionsResponse, Transaction } from "./types";

export type Reducer = {
    transactionsResponse: GetTransactionsResponse | null;
    areTransactionsLoading: boolean;
};

const initialState: Reducer = {
    transactionsResponse: null,
    areTransactionsLoading: false
};

export const reducer = reducerWithInitialState(initialState)
    .case(
        actions.getTransactions.started,
        (state): Reducer => ({
            ...state,
            areTransactionsLoading: true
        })
    )
    .case(
        actions.getTransactions.done,
        (state, payload): Reducer => ({
            ...state,
            transactionsResponse: payload.result,
            areTransactionsLoading: false
        })
    )
    .case(
        actions.getTransactions.failed,
        (state): Reducer => ({
            ...state,
            areTransactionsLoading: false
        })
    )
    .case(actions.clear, (): Reducer => initialState);
