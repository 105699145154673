import Typography from "@mui/material/Typography";
import { Content } from "components/Content";
import { FC, useEffect, useState } from "react";
import { generatePath, Link, matchPath, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants";
import * as s from "./styles";
import { MatchParams, NavigationMenuItem } from "./types";
import { useTranslation } from "react-i18next";
import ListIcon from "@mui/icons-material/List";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CodeIcon from "@mui/icons-material/Code";
import EventIcon from '@mui/icons-material/Event';
import { userSelector } from "../../store/modules/auth/selectors";
import { useSelector } from "react-redux";
import { ListItemButton } from "@mui/material";

export const Container: FC = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [menuItems, setMenuItems] = useState<NavigationMenuItem[]>([]);

  const currentRoute = Object.values(ROUTES).find((route) =>
    matchPath(pathname, { path: route, exact: true })
  );

  const match = matchPath<MatchParams>(pathname, {
    path: currentRoute,
    exact: true
  });

  const user = useSelector(userSelector);

  useEffect(() => {
    setMenuItems([
      {
        route: ROUTES.PAY_METHODS,
        label: t("uiComponents.navigation.list.pay_methods"),
        icon: <TimeToLeaveIcon />
      },
      {
        route: ROUTES.PLANS,
        label: t("uiComponents.navigation.list.plans"),
        icon: <AssignmentIcon />
      },
      {
        route: ROUTES.PROGRAMS,
        label: t("uiComponents.navigation.list.programs"),
        icon: <ListIcon />
      },
      {
        route: ROUTES.TRANSACTIONS,
        label: t("uiComponents.navigation.list.transactions"),
        icon: <ListIcon />
      },
      {
        route: ROUTES.CALENDAR,
        label: t("uiComponents.navigation.list.calendar"),
        icon: <EventIcon />
      },
      {
        route: ROUTES.RESTORE_CONFIG_TOOL,
        label: t("uiComponents.navigation.list.restore_config_tool"),
        icon: <CodeIcon />,
        attributeDependency: "config_tool"
      },
    ]);
  }, [i18n.language, setMenuItems, t]);

  const ROUTES_WITHOUT_MENU: string[] = [];

  const isMenuHidden = currentRoute
    ? ROUTES_WITHOUT_MENU.includes(currentRoute)
    : false;

  const renderMenuItems = (menuItems: NavigationMenuItem[]) =>
    menuItems.map((menuItem, inx) => {
      if (menuItem["attributeDependency"]) {
        const dependency = menuItem["attributeDependency"];
        if (
          !(
            user &&
            user.attributes &&
            dependency in user.attributes &&
            user.attributes[dependency][0] === "true"
          )
        ) {
          return <></>;
        }
      }

      return (
        <ListItemButton
          component={s.ListItem}
          key={inx}
          role={"listitem"}
        >
          <s.Link to={generatePath(menuItem.route, match?.params)}>
            {menuItem.icon && (
              <s.IconContainer active={currentRoute?.includes(menuItem.route)}>
                {menuItem.icon}
              </s.IconContainer>
            )}
            <Typography
              color={
                currentRoute?.includes(menuItem.route)
                  ? "accent.main"
                  : undefined
              }
            >
              {menuItem.label}
            </Typography>
          </s.Link>
        </ListItemButton>
      );
    });

  return (
    <s.Container>
      <s.Drawer variant={"permanent"} open={true}>
        <s.DrawerHeader>
          <Link to={ROUTES.PAY_METHODS} style={{ textDecoration: "none" }}>
            <s.LogoImgContainer>
              <img src={require("/assets/logo-label.svg")} alt="TSG" />
            </s.LogoImgContainer>
          </Link>
        </s.DrawerHeader>
        <s.DrawerMenuList>
          {!isMenuHidden && <>{renderMenuItems(menuItems)}</>}
        </s.DrawerMenuList>
      </s.Drawer>
      <Content>{children}</Content>
    </s.Container>
  );
};
