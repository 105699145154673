import { AxiosResponse } from "axios";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { axiosInstance } from "../../../axios";
import { keycloak } from "../../../keycloak";
import * as actions from "./actions";
import { enqueueSnackbar } from '../notifications/actions';
import {
  CreateProgramParams,
  CreateProgramResponse,
  DeleteProgramParams,
  DeleteProgramResponse,
  GetProgramParams,
  GetProgramResponse,
  GetProgramsParams,
  GetProgramsResponse,
  UpdateProgramParams,
  UpdateProgramResponse
} from "./types";

export function* getProgramSaga(
  action: Action<GetProgramParams>
): SagaIterator<void> {
  try {
    const { id } = action.payload;
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<GetProgramResponse> = yield call(
      axiosInstance.get,
      `programs/${id}`
    );
    yield put(
      actions.getProgram.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(actions.getProgram.failed({ params: action.payload, error: e }));
  }
}

export function* getProgramsSaga(
  action: Action<GetProgramsParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<GetProgramsResponse> = yield call(
      axiosInstance.get,
      `programs`
    );
    yield put(
      actions.getPrograms.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(actions.getPrograms.failed({ params: action.payload, error: e }));
  }
}

export function* createProgramSaga(
  action: Action<CreateProgramParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { data } = action.payload;
    const response: AxiosResponse<CreateProgramResponse> = yield call(
      axiosInstance.post,
      `programs`,
      data
    );
    yield put(
      actions.createProgram.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.program.create', variant: 'success' }));
  } catch (e: any) {
    yield put(
      actions.createProgram.failed({ params: action.payload, error: e })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* updateProgramSaga(
  action: Action<UpdateProgramParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { id, data } = action.payload;
    const response: AxiosResponse<UpdateProgramResponse> = yield call(
      axiosInstance.put,
      `programs/${id}`,
      data
    );
    yield put(
      actions.updateProgram.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.program.update', variant: 'success' }));
  } catch (e: any) {
    yield put(
      actions.updateProgram.failed({ params: action.payload, error: e })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* deleteProgramSaga(
  action: Action<DeleteProgramParams>
): SagaIterator<void> {
  try {
    const token = keycloak.idToken?.toString() || "";
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { id } = action.payload;
    const response: AxiosResponse<DeleteProgramResponse> = yield call(
      axiosInstance.delete,
      `programs/${id}`
    );
    yield put(
      actions.deleteProgram.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.program.delete', variant: 'success' }));
  } catch (e: any) {
    yield put(
      actions.deleteProgram.failed({ params: action.payload, error: e })
    );
    yield put(enqueueSnackbar({ message: 'uiComponents.notifications.error.default', variant: 'error' }));
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getProgram.started, getProgramSaga),
    takeLeading(actions.getPrograms.started, getProgramsSaga),
    takeEvery(actions.createProgram.started, createProgramSaga),
    takeEvery(actions.updateProgram.started, updateProgramSaga),
    takeEvery(actions.deleteProgram.started, deleteProgramSaga)
  ]);
}
