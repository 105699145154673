import { yupResolver } from "@hookform/resolvers/yup";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Info from "@mui/icons-material/Info";
import { BasicDatePicker } from "components/common/FormFields/BasicDatePicker";
import { CurrencyAmount } from "components/common/FormFields/CurrencyAmount";
import { SimpleSelect } from "components/common/FormFields/SimpleSelect";
import { MultiSelect } from "components/common/FormFields/MultiSelect";
import { PhoneNumber } from "components/common/FormFields/PhoneNumber";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { isSelectOption } from "typeGuards/isSelectOption";
import { isUndefined } from "typeGuards/isUndefined";
import { object, SchemaOf, string } from "yup";
import { Loader } from "../../Loader";
import * as s from "../styles";
import {
  FIELD_TYPES,
  FormDialogExtraButton,
  FormDialogField,
  FormDialogProps,
  SelectOption
} from "./types";
import { useTranslation } from "react-i18next";
import { FileUpload } from "../../FormFields/FileUpload";
import { BasicDateTimePicker } from "components/common/FormFields/BasicDateTimePicker";
import { Switch } from "@mui/material";

export const selectOptionSchema: SchemaOf<SelectOption> = object({
  label: string().required(),
  value: string().required()
});

const filter = createFilterOptions<SelectOption>();

export const FormDialog: FC<FormDialogProps> = ({
  title,
  extraButtons,
  fields,
  yupNoSortEdges,
  onConfirm,
  onCancel,
  isOpened,
  isLoading,
  cancelButtonLabel = "Cancel",
  confirmButtonLabel = "OK",
  isLocked
}: FormDialogProps) => {
  const [areDividersVisible, setAreDividersVisible] = useState(false);
  const [formDialogContentNode, setFormDialogContentNode] =
    useState<HTMLDivElement | null>(null);
  const validationSchema = useMemo(() => {
    return object().shape(
      fields?.reduce(
        (schemaObj, field) => ({
          ...schemaObj,
          [field.name]: (
            typeof field.invisible == "function"
              ? field.invisible(fields)
              : field.invisible
          )
            ? field.rules
            : undefined
        }),
        {}
      ) || {},
      yupNoSortEdges
    );
  }, [fields, yupNoSortEdges]);
  const { t } = useTranslation();

  const { handleSubmit, control, errors, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema)
  });

  const fieldValues = watch();

  const handleCancelButtonClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onSubmit = useCallback(
    (data) => {
      onConfirm(data);
    },
    [onConfirm]
  );

  const handleClose = useCallback(
    (e, reason) => {
      if (reason === "escapeKeyDown" && (!fields || fields.length === 0)) {
        onCancel();
      }
    },
    [fields, onCancel]
  );

  const getErrorMessage = useCallback(
    (field: FormDialogField) => {
      let fieldError = errors[field.name] as FieldError | FieldError[];
      if (Array.isArray(fieldError)) {
        // Filter non-empty elements
        fieldError = fieldError.filter((x) => x)[0];
      }
      if (!fieldError) {
        return undefined;
      }

      switch (fieldError.type) {
        case "required":
        case "typeError":
          return t("uiComponents.form.errorMessages.required");
        default:
          return fieldError.message;
      }
    },
    [errors]
  );

  const isUsernameFieldExists = useMemo(
    () => Boolean(fields?.some((field) => field.autocomplete === "username")),
    [fields]
  );

  const passwordFields = useMemo(
    () =>
      fields?.reduce(
        (acc, cur, i) =>
          cur.type === FIELD_TYPES.PASSWORD ? [...acc, i] : acc,
        [] as number[]
      ) || [],
    [fields]
  );

  useEffect(() => {
    const handleResize = () => {
      if (formDialogContentNode) {
        formDialogContentNode.scrollHeight > formDialogContentNode.clientHeight;
        setAreDividersVisible(
          formDialogContentNode.scrollHeight >
          formDialogContentNode.clientHeight
        );
      } else {
        setAreDividersVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [formDialogContentNode]);

  const handleFormDialogContentRef = useCallback((node: HTMLDivElement) => {
    setFormDialogContentNode(node);
  }, []);

  const FORM_ID = `dialog-${title.replace(/\s/g, "-")}`;

  const renderExplanation = (field: FormDialogField) => {
    return (
      <Tooltip
        title={field?.explanation ? field?.explanation : ""}
        placement={"top"}
        sx={{ alignSelf: "flex-end" }}
      >
        <Info color={"info"} />
      </Tooltip>
    );
  };

  return (
    <s.Dialog
      open={isOpened}
      onClose={handleClose}
      scroll={"paper"}
      data-testid={"dialog"}
    >
      <DialogTitle data-testid={"dialog-title"}>{title}</DialogTitle>
      <s.FormDialogContent
        ref={handleFormDialogContentRef}
        dividers={areDividersVisible}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
          {isLoading ? (
            <s.LoaderContainer>
              <Loader text={t("uiComponents.loader.texts.loading_data")} />
            </s.LoaderContainer>
          ) : (
            <>
              {fields?.map((field, i) => {
                const visible =
                  isUndefined(field.isHidden) ||
                  (typeof field.isHidden === "function" &&
                    Object.keys(fieldValues).length > 0 &&
                    !field.isHidden(fieldValues));

                if (!visible) {
                  return;
                }

                const currentType =
                  typeof field.type === "function"
                    ? field.type(fieldValues)
                    : field.type;

                const defaultValue =
                  field.defaultValue != null && typeof field.defaultValue === "function"
                    ? field.defaultValue(fieldValues)
                    : field.defaultValue;

                const invisible =
                  typeof field.invisible === "function"
                    ? field.invisible(fieldValues)
                    : false;

                const payloadValue = field.payloadValueOfCondition?.fn(
                  fieldValues
                )
                  ? field?.payloadValueOfCondition?.payload
                  : null;

                switch (currentType) {
                  case FIELD_TYPES.SWITCH:
                    return (
                      <FormControlLabel
                        sx={{ display: invisible ? "none" : undefined }}
                        key={field.name}
                        label={typeof field.label === "function"
                          ? field.label(fieldValues)
                          : field.label}
                        control={
                          <Controller
                            name={field.name}
                            control={control}
                            defaultValue={defaultValue || false}
                            render={({ value, onChange }) => (
                              <Box sx={{ display: "flex" }}>
                                <Switch
                                  checked={value}
                                  color={"error"}
                                  onChange={(e) => onChange(e.target.checked)}
                                />
                                {field.explanation &&
                                  renderExplanation(field)}
                              </Box>
                            )}
                          />
                        }
                      />);
                  case FIELD_TYPES.CHECKBOX:
                    return (
                      <FormControlLabel
                        sx={{ display: invisible ? "none" : undefined }}
                        key={field.name}
                        label={
                          typeof field.label === "function"
                            ? field.label(fieldValues)
                            : field.label
                        }
                        control={
                          <Controller
                            name={field.name}
                            control={control}
                            defaultValue={defaultValue || false}
                            render={({ value, onChange }) => (
                              <Box sx={{ display: "flex" }}>
                                <Checkbox
                                  checked={value}
                                  color={"accent"}
                                  onChange={(e) => onChange(e.target.checked)}
                                />
                                {field.explanation &&
                                  renderExplanation(field)}
                              </Box>
                            )}
                          />
                        }
                      />
                    );
                  case FIELD_TYPES.MULTISELECT:
                    return (
                      <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={defaultValue || []}
                        render={({ onChange, value }) => {
                          return (
                            <Box sx={{ display: "flex" }}>
                              <MultiSelect
                                label={field.label}
                                initValue={value}
                                handleValue={onChange}
                                options={field.options as SelectOption[]}
                                autocomplete={field.autocomplete || "off"}
                                error={Boolean(errors[field.name])}
                                invisible={invisible}
                                validationMessage={getErrorMessage(field)}
                                payloadValue={payloadValue}
                              />
                            </Box>
                          );
                        }}
                      />
                    );
                  case FIELD_TYPES.SELECT:
                    return (
                      <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={defaultValue || ""}
                        isOptionEqualToValue={(
                          option: SelectOption,
                          value: SelectOption
                        ) =>
                          isSelectOption(option) && isSelectOption(value)
                            ? option.value === value.value
                            : option === value
                        }
                        render={({ onChange, value }) => {
                          return (
                            <Box sx={{ display: "flex" }}>
                              <SimpleSelect
                                label={field.label}
                                initValue={value}
                                handleValue={onChange}
                                options={field.options as SelectOption[]}
                                autocomplete={field.autocomplete || "off"}
                                error={Boolean(errors[field.name])}
                                invisible={invisible}
                                validationMessage={getErrorMessage(field)}
                                payloadValue={payloadValue}
                              />
                              {field.explanation &&
                                !invisible &&
                                renderExplanation(field)}
                            </Box>
                          );
                        }}
                      />
                    );
                  case FIELD_TYPES.NUMBER:
                    return (
                      <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={defaultValue || ""}
                        render={({ onChange, value }) => {
                          return (
                            <Box sx={{ display: "flex" }}>
                              <TextField
                                type={invisible ? "hidden" : "number"}
                                variant={"standard"}
                                label={field.label}
                                value={value}
                                onChange={(e) => {
                                  onChange(+e.target.value);
                                }}
                                InputProps={{ inputProps: { min: 0 } }}
                                fullWidth={true}
                                color={"accent"}
                                autoComplete={field.autocomplete || "off"}
                                style={
                                  invisible
                                    ? { height: "0", opacity: 0 }
                                    : { marginTop: "16px" }
                                }
                                helperText={getErrorMessage(field)}
                                error={Boolean(errors[field.name])}
                              />
                              {field.explanation &&
                                !invisible &&
                                renderExplanation(field)}
                            </Box>
                          );
                        }}
                      />
                    );
                  case FIELD_TYPES.CURRENCY_AMOUNT:
                    return (
                      <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={defaultValue || ""}
                        render={({ onChange, value }) => {
                          return (
                            <Box sx={{ display: "flex" }}>
                              <CurrencyAmount
                                label={field.label}
                                initValue={value}
                                handleValue={onChange}
                                validationMessage={getErrorMessage(field)}
                                error={Boolean(errors[field.name])}
                                payloadValue={payloadValue}
                                invisible={invisible}
                              />
                              {field.explanation &&
                                !invisible &&
                                renderExplanation(field)}
                            </Box>
                          );
                        }}
                      />
                    );
                  case FIELD_TYPES.PASSWORD:
                  case FIELD_TYPES.TEXT:
                    return (
                      <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={defaultValue || ""}
                        render={({ onChange, value }) => {
                          return (
                            <Box sx={{ display: "flex" }}>
                              <TextField
                                type={invisible ? "hidden" : "text"}
                                variant={"standard"}
                                label={field.label}
                                value={value}
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                fullWidth={true}
                                autoComplete={field.autocomplete || "off"}
                                color={"accent"}
                                style={
                                  invisible
                                    ? { height: "0", opacity: 0 }
                                    : { marginTop: "16px" }
                                }
                                helperText={getErrorMessage(field)}
                                error={Boolean(errors[field.name])}
                              />
                              {field.explanation &&
                                !invisible &&
                                renderExplanation(field)}
                            </Box>
                          );
                        }}
                      />
                    );
                  case FIELD_TYPES.MULTILINE_TEXT:
                  case FIELD_TYPES.LABEL:
                    return (
                      <s.LabelFieldText key={field.name}>
                        {typeof field.label === "function"
                          ? field.label(fieldValues)
                          : field.label}
                      </s.LabelFieldText>
                    );
                  case FIELD_TYPES.DATE_PICKER:
                    return (
                      <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={defaultValue || null}
                        render={({ onChange, value }) => (
                          <Box sx={{ display: "flex" }}>
                            <BasicDatePicker
                              label={field.label}
                              handleValue={onChange}
                              initValue={value}
                              isStartOfTheDay={field?.isStartOfTheDay}
                              isEndOfTheDay={field?.isEndOfTheDay}
                              validationMessage={getErrorMessage(field)}
                              error={Boolean(errors[field.name])}
                              minValue={
                                field?.minDate &&
                                  typeof field?.minDate === "function"
                                  ? field.minDate(fieldValues)
                                  : null
                              }
                              maxValue={
                                field?.maxDate &&
                                  typeof field?.maxDate === "function"
                                  ? field.maxDate(fieldValues)
                                  : null
                              }
                            />
                            {field.explanation && renderExplanation(field)}
                          </Box>
                        )}
                      />
                    );
                  case FIELD_TYPES.DATE_TIME_PICKER:
                    return (
                      <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={defaultValue || null}
                        render={({ onChange, value }) => (
                          <Box sx={{ display: "flex" }}>
                            <BasicDateTimePicker
                              label={field.label}
                              handleValue={onChange}
                              initValue={value}
                              validationMessage={getErrorMessage(field)}
                              error={Boolean(errors[field.name])}
                              minValue={
                                field?.minDate &&
                                  typeof field?.minDate === "function"
                                  ? field.minDate(fieldValues)
                                  : null
                              }
                              maxValue={
                                field?.maxDate &&
                                  typeof field?.maxDate === "function"
                                  ? field.maxDate(fieldValues)
                                  : null
                              }
                            />
                            {field.explanation && renderExplanation(field)}
                          </Box>
                        )}
                      />
                    );

                  case FIELD_TYPES.PHONE_NUMBER:
                    return (
                      <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={defaultValue || ""}
                        render={({ onChange, value }) => {
                          return (
                            <Box sx={{ display: "flex" }}>
                              <PhoneNumber
                                label={field.label}
                                initValue={value}
                                handleValue={onChange}
                                validationMessage={getErrorMessage(field)}
                                error={Boolean(errors[field.name])}
                              />
                              {field.explanation &&
                                !invisible &&
                                renderExplanation(field)}
                            </Box>
                          );
                        }}
                      />
                    );
                  case FIELD_TYPES.FILE:
                    return (
                      <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={defaultValue || ""}
                        render={({ onChange, value }) => {
                          return (
                            <Box sx={{ display: "flex" }}>
                              <FileUpload
                                label={typeof field.label === "function" ? field.label(fieldValues) : field.label}
                                name={field.name}
                                handleValue={(x) => { onChange(x) }}
                                acceptFileTypes={field.acceptFileTypes}
                              />
                            </Box>
                          );
                        }}
                      />
                    );
                }
              })}
            </>
          )}
        </form>
      </s.FormDialogContent>
      <DialogActions>
        {extraButtons &&
          extraButtons.map((buttonProps: FormDialogExtraButton) => {
            return <Button
              key={buttonProps.label}
              disabled={buttonProps.locked === undefined ? false : buttonProps.locked()}
              onClick={buttonProps.handler}
              sx={{ color: buttonProps.color.text, backgroundColor: buttonProps.color.background }}
              variant={"contained"}
            >
              {buttonProps.label}
            </Button>
          })
        }
      </DialogActions>
      <DialogActions>
        <Button
          disabled={isLocked}
          onClick={handleCancelButtonClick}
          color={"secondary"}
          data-testid={"dialog-secondary-button"}
          variant={"contained"}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          color={"accent"}
          sx={{ color: "#fff" }}
          autoFocus={!fields || fields.length === 0}
          type={"submit"}
          form={FORM_ID}
          data-testid={"dialog-submit-button"}
          variant={"contained"}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </s.Dialog>
  );
};
