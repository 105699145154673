import * as auth from "./modules/auth/reducers";
import * as stations from "./modules/stations/reducers";
import * as terminals from "./modules/terminals/reducers";
import * as paymentMethods from "./modules/paymentMethods/reducers";
import * as transactions from "./modules/transactions/reducers";
import * as plans from "./modules/plans/reducers";
import * as programs from "./modules/programs/reducers";
import * as notifications from "./modules/notifications/reducers";
import * as calendar from "./modules/calendar/reducers";
import { combineReducers } from "redux";

export type RootReducer = {
  auth: auth.Reducer;
  stations: stations.Reducer;
  terminals: terminals.Reducer;
  paymentMethods: paymentMethods.Reducer;
  transactions: transactions.Reducer;
  plans: plans.Reducer;
  programs: programs.Reducer;
  notifications: notifications.Reducer;
  calendar: calendar.Reducer;
};

export const rootReducer = combineReducers<RootReducer>({
  auth: auth.reducer,
  stations: stations.reducer,
  terminals: terminals.reducer,
  paymentMethods: paymentMethods.reducer,
  transactions: transactions.reducer,
  plans: plans.reducer,
  programs: programs.reducer,
  notifications: notifications.reducer,
  calendar: calendar.reducer,
});
