import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { GetPaymentMethodsResponse, PaymentMethod } from "./types";

export type Reducer = {
  paymentMethodsResponse: GetPaymentMethodsResponse | null;
  paymentMethod: PaymentMethod | null;
  isPaymentMethodLoading: boolean;
  arePaymentMethodsLoading: boolean;
  isPaymentMethodCreating: boolean;
  isPaymentMethodUpdating: boolean;
  isPaymentMethodDeleting: boolean;
  isAddingCredit: boolean;
};

const initialState: Reducer = {
  paymentMethodsResponse: null,
  paymentMethod: null,
  isPaymentMethodLoading: false,
  arePaymentMethodsLoading: false,
  isPaymentMethodCreating: false,
  isPaymentMethodUpdating: false,
  isPaymentMethodDeleting: false,
  isAddingCredit: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getPaymentMethod.started,
    (state): Reducer => ({
      ...state,
      isPaymentMethodLoading: true
    })
  )
  .case(
    actions.getPaymentMethod.done,
    (state, payload): Reducer => ({
      ...state,
      paymentMethod: payload.result,
      isPaymentMethodLoading: false
    })
  )
  .case(
    actions.getPaymentMethod.failed,
    (state): Reducer => ({
      ...state,
      isPaymentMethodLoading: false
    })
  )
  .case(
    actions.getPaymentMethods.started,
    (state): Reducer => ({
      ...state,
      arePaymentMethodsLoading: true
    })
  )
  .case(
    actions.getPaymentMethods.done,
    (state, payload): Reducer => ({
      ...state,
      paymentMethodsResponse: payload.result,
      arePaymentMethodsLoading: false
    })
  )
  .case(
    actions.getPaymentMethods.failed,
    (state): Reducer => ({
      ...state,
      arePaymentMethodsLoading: false
    })
  )
  .case(
    actions.createPaymentMethod.started,
    (state): Reducer => ({
      ...state,
      isPaymentMethodCreating: true
    })
  )
  .case(
    actions.createPaymentMethod.done,
    (state): Reducer => ({
      ...state,
      isPaymentMethodCreating: false
    })
  )
  .case(
    actions.createPaymentMethod.failed,
    (state): Reducer => ({
      ...state,
      isPaymentMethodCreating: false
    })
  )
  .case(
    actions.createVoucher.started,
    (state): Reducer => ({
      ...state,
      isPaymentMethodCreating: true
    })
  )
  .case(
    actions.createVoucher.done,
    (state): Reducer => ({
      ...state,
      isPaymentMethodCreating: false
    })
  )
  .case(
    actions.createVoucher.failed,
    (state): Reducer => ({
      ...state,
      isPaymentMethodCreating: false
    })
  )
  .case(
    actions.updatePaymentMethod.started,
    (state): Reducer => ({
      ...state,
      isPaymentMethodUpdating: true
    })
  )
  .case(
    actions.updatePaymentMethod.done,
    (state): Reducer => ({
      ...state,
      isPaymentMethodUpdating: false
    })
  )
  .case(
    actions.updatePaymentMethod.failed,
    (state): Reducer => ({
      ...state,
      isPaymentMethodUpdating: false
    })
  )
  .case(
    actions.deletePaymentMethod.started,
    (state): Reducer => ({
      ...state,
      isPaymentMethodDeleting: true
    })
  )
  .case(
    actions.deletePaymentMethod.done,
    (state): Reducer => ({
      ...state,
      isPaymentMethodDeleting: false,
      paymentMethod: null
    })
  )
  .case(
    actions.deletePaymentMethod.failed,
    (state): Reducer => ({
      ...state,
      isPaymentMethodDeleting: false
    })
  )
  .case(
    actions.addCredit.started,
    (state): Reducer => ({
      ...state,
      isAddingCredit: true
    })
  )
  .case(
    actions.addCredit.done,
    (state): Reducer => ({
      ...state,
      isAddingCredit: false
    })
  )
  .case(
    actions.addCredit.failed,
    (state): Reducer => ({
      ...state,
      isAddingCredit: false
    })
  )
  .case(actions.clear, (): Reducer => initialState);
