import { useState, FC, ChangeEvent, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormControl";
import { useMount } from "hooks/useMount";
import { CurrencyAmountInterface } from "components/common/FormFields/CurrencyAmount/types";
import * as s from "./styles";

export const CurrencyAmount: FC<CurrencyAmountInterface> = ({
  label,
  initValue,
  handleValue,
  invisible,
  payloadValue,
  validationMessage,
  error
}) => {
  const [value, setValue] = useState<number | string>("");

  useMount(() => {
    const euro = initValue > 0 ? initValue / 100 : initValue;
    setValue(euro);
  });

  useEffect(() => {
    if (payloadValue) {
      setValue(payloadValue as any);
      handleValue(payloadValue);
    }
  }, [handleValue, payloadValue]);

  return (
    <FormControl
      fullWidth
      variant="standard"
      style={invisible ? { height: "0", opacity: 0 } : { marginTop: "16px" }}
      color={"accent"}
      error={error}
    >
      <InputLabel htmlFor="standard-adornment-amount">{label}</InputLabel>
      <Input
        id="standard-adornment-amount"
        type="number"
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          const val: string = e.target.value;
          setValue(val);
          const cents: number | string = val ? Math.round(+val * 100) : val;
          handleValue(cents);
        }}
        inputProps={{ step: 0.1 }}
        color={"accent"}
        endAdornment={<InputAdornment position="start">€</InputAdornment>}
      />
      {validationMessage && (
        <s.HelperText id="standard-adornment-amount">
          {validationMessage}
        </s.HelperText>
      )}
    </FormControl>
  );
};
