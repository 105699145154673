import { useKeycloak } from "@react-keycloak/web";
import { Loader } from "components/common/Loader";
import { RequireAuth } from "components/common/RequireAuth";
import { Container } from "components/Container";
import * as authActions from "../../store/modules/auth/actions";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ROUTES } from "../../constants";
import { PaymentMethod } from "../Pages/PaymentMethods";
import { Plans } from "../Pages/Plans";
import { Programs } from "../Pages/Programs";
import { RestoreConfigTool } from "../Pages/RestoreConfigTool";
import { userSelector } from "../../store/modules/auth/selectors";
import { Transactions } from "components/Pages/Transactions";
import { CalendarPage } from "components/Pages/Calendar";

export const Router: FC = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const user = useSelector(userSelector);

  useEffect(() => {
    if (!keycloak.authenticated) {
      void keycloak.login();
    } else {
      dispatch(authActions.getUserDetails.started({}));
    }
  }, [keycloak, keycloak.authenticated, dispatch]);

  return keycloak.authenticated ? (
    <BrowserRouter>
      <Container>
        <Switch>
          <Route
            exact
            path={ROUTES.ROOT}
            render={() => <Redirect to={ROUTES.PAY_METHODS} />}
          />
          <Route
            exact
            path={ROUTES.PAY_METHODS}
            render={() => (
              <RequireAuth>
                <PaymentMethod />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={ROUTES.PLANS}
            render={() => (
              <RequireAuth>
                <Plans />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={ROUTES.PROGRAMS}
            render={() => (
              <RequireAuth>
                <Programs />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={ROUTES.TRANSACTIONS}
            render={() => (
              <RequireAuth>
                <Transactions />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={ROUTES.CALENDAR}
            render={() => (
              <RequireAuth>
                <CalendarPage />
              </RequireAuth>
            )}
          />
          {user?.attributes?.config_tool &&
            user.attributes.config_tool[0] === "true" && (
              <Route
                exact
                path={ROUTES.RESTORE_CONFIG_TOOL}
                render={() => (
                  <RequireAuth>
                    <RestoreConfigTool />
                  </RequireAuth>
                )}
              />
            )}
          <Route render={() => <Redirect to={ROUTES.PAY_METHODS} />} />
        </Switch>
      </Container>
    </BrowserRouter>
  ) : (
    <Loader text={"Redirecting to login..."} />
  );
};
