import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory("NOTIFICATIONS");

export const enqueueSnackbar = actionCreator<any>('ENQUEUE_SNACKBAR');

export const closeSnackbar = actionCreator<any>('CLOSE_SNACKBAR');

export const removeSnackbar = actionCreator<any>('REMOVE_SNACKBAR');

export const clear = actionCreator<void>("CLEAR");
