import actionCreatorFactory from "typescript-fsa";
import { GetAllTerminalsParams, GetAllTerminalsResponse } from "./types";

const actionCreator = actionCreatorFactory("TRANSACTIONS");


export const getAllTerminals = actionCreator.async<
    GetAllTerminalsParams,
    GetAllTerminalsResponse,
    unknown
>("GET_TERMINALS");


export const clear = actionCreator<void>("CLEAR");