import { MouseEventHandler } from "react";
import { AnySchema } from "yup";

export enum FIELD_TYPES {
  TEXT = "text",
  MULTILINE_TEXT = "multiline_text",
  PASSWORD = "password",
  SELECT = "select",
  MULTISELECT = "multiselect",
  SWITCH = "switch",
  CHECKBOX = "checkbox",
  NUMBER = "number",
  LABEL = "label",
  DATE_PICKER = "date_picker",
  DATE_TIME_PICKER = "date_time_picker",
  FILE = "file",
  CURRENCY_AMOUNT = "currency_amount",
  PHONE_NUMBER = "phone_number",
}

export type SelectOption = {
  label: string;
  value: string;
};

export type ExtendedConditions = {
  fn: (target: { [key: string]: string }) => boolean;
  defaultValue: string | number | SelectOption | null;
};

export type FieldTypesMethod = (target: any) => string;

export type PayloadValueOfCondition = {
  fn: any;
  payload: any;
}

export type FormDialogField = {
  label: ((fieldValues: Record<string, unknown>) => string) | string;
  name: string;
  type: FIELD_TYPES | FieldTypesMethod;
  defaultValue?: ((fieldValues: Record<string, unknown>) => unknown) | unknown;
  options?:
  | SelectOption[]
  | ((fieldValues: Record<string, unknown>) => SelectOption[] | undefined)
  | ({ label: string; value: number; }[] | undefined);
  suffix?: string;
  rules?: AnySchema;
  onCreate?: () => void;
  createText?: string;
  step?:
  | ((fieldValues: Record<string, unknown>) => number | undefined)
  | number;
  min?: ((fieldValues: Record<string, unknown>) => number | undefined) | number;
  max?: ((fieldValues: Record<string, unknown>) => number | undefined) | number;
  isHidden?: (fieldValues: Record<string, unknown>) => boolean;
  autocomplete?: string;
  freeSolo?: boolean;
  helperText?: string;
  explanation?: string;
  extendedConditions?: ExtendedConditions;
  payloadValueOfCondition?: PayloadValueOfCondition;
  invisible?: boolean | ((target: any) => boolean);
  isStartOfTheDay?: boolean;
  isEndOfTheDay?: boolean;
  minDate?: any;
  maxDate?: any;
  acceptFileTypes?: string;
};

export type FormDialogExtraButton = {
  color: {
    background: string;
    text: string;
  };
  handler: MouseEventHandler<HTMLButtonElement> | undefined;
  locked?: () => boolean | undefined;
  label: string;
}

export type FormDialogProps = {
  title: string;
  extraButtons?: FormDialogExtraButton[];
  fields?: FormDialogField[];
  yupNoSortEdges?: [string, string][];
  onConfirm: (data?) => void;
  onCancel: () => void;
  isOpened: boolean;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  isLocked?: boolean;
  isLoading?: boolean;
};
