import { RootReducer } from "../../reducers";
import { Transaction, TableTransaction } from "./types";


export const tableTransactionsSelector = (
  state: RootReducer
): TableTransaction[] | null =>
  state.transactions.transactionsResponse?.data.transactions.map(
    (transaction: Transaction) => {

      const tableRow: TableTransaction = {
        id: transaction.id,
        external: transaction.external,
        status: transaction.status,
        amount: transaction.amount,
        pay_method_id: transaction.pay_method_external_id,
        pay_method_type: transaction.pay_method_type,
        station_id: transaction.station_id,
        terminal_id: transaction.terminal_id,
        tax_rate: transaction.tax_rate,
        create_date: new Date(Date.parse(transaction.created_at)),
      }

      if (transaction.external) {
        return tableRow;
      }

      tableRow.pay_method_type = transaction.pay_method?.type ?? "";
      tableRow.pay_method_id = transaction.pay_method?.external_id ?? "";

      return tableRow;
    }
  ) || null;

export const transactionsTotalPagesSelector = (
  state: RootReducer
): number | null =>
  state.transactions.transactionsResponse?.totalPages || null;

export const areTransactionsLoadingSelector = (state: RootReducer): boolean =>
  state.transactions.areTransactionsLoading;
