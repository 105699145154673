export enum FIELD_TYPES {
    BOOLEAN = "boolean",
    TEXT = "text",
    DATE_TIME = "date_time",
    MULTILINE_TEXT = "multiline_text",
    PASSWORD = "password",
    SELECT = "select",
    MULTISELECT = "multiselect",
    CHECKBOX = "checkbox",
    NUMBER = "number",
    LABEL = "label",
    DATE_PICKER = "date_picker",
    CURRENCY_AMOUNT = "currency_amount",
    PHONE_NUMBER = "phone_number",
}

export type DialogField = {
    label: ((fieldValues: Record<string, unknown>) => string) | string;
    name: string;
    type: FIELD_TYPES;
    value?: ((fieldValues: Record<string, unknown>) => unknown) | unknown;
    step?:
    | ((fieldValues: Record<string, unknown>) => number | undefined)
    | number;
    min?: ((fieldValues: Record<string, unknown>) => number | undefined) | number;
    max?: ((fieldValues: Record<string, unknown>) => number | undefined) | number;
    explanation?: string;
    invisible?: boolean | ((target: any) => boolean);
};

export type DialogProps = {
    title: string;
    fields?: DialogField[];
    onClose: () => void;
    isOpened: boolean;
    isLocked?: boolean;
    isLoading?: boolean;
};

