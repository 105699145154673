import { FC, useState } from "react";
import MuiPhoneNumber from 'material-ui-phone-number-2';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { useMount } from "hooks/useMount";

export const PhoneNumber: FC<any> = ({
  initValue,
  label,
  handleValue,
  validationMessage
}) => {
  const [value, setValue] = useState<string>("");

  const handleClear = () => {
    setValue("");
    handleValue("");
  }

  useMount(() => {
    if (initValue) setValue(initValue);
  });

  const countriesList = [
    "de", "at", "be", "hr", "dk", "fi", "it", "lt", "no",
    "pt", "sk", "se", "ch", "bg", "cz", "ee", "fr", "ie",
    "lv", "nl", "pl", "ro", "es", "uk",
  ];

  return (
    <MuiPhoneNumber
      defaultCountry={"de"}
      onlyCountries={countriesList}
      value={value}
      label={label}
      countryCodeEditable={false}
      onChange={(newValue) => {
        setValue(newValue);
        const valueToSave = newValue.replace(/\s/g, "");
        handleValue(valueToSave);
      }}
      fullWidth={true}
      style={{ marginTop: "16px" }}
      color={"accent"}
      helperText={validationMessage}
      autoComplete={"off"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton size={"small"} onClick={handleClear}>
              <ClearIcon/>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
