export type SlotInfo = {
    start: Date;
    end: Date;
    slots: Date[];
    action: "select" | "click" | "doubleClick";
    bounds?: {
        bottom: number;
        left: number;
        right: number;
        top: number;
        x: number;
        y: number;
    };
    box?: {
        clientX: number;
        clientY: number;
        x: number;
        y: number;
    };
}

export type DisplayEvent = {
    title: string
    start: Date
    end: Date
    allDay: boolean
    resource: EventResource
}

export type CalendarEvent = {
    title: string
    start: Date
    end: Date
    allDay: boolean,
    recurrency?: Recurrency
    resource: EventResource
}

export type Recurrency = {
    frequency: moment.unitOfTime.DurationConstructor;
    interval: number;
    until?: Date;
    count?: number;
    exceptions?: Date[];
}

export type EventResource = {
    id: string;
    type: EventType;
    percentage?: number;
}

export type EventCategory = {
    label: string
    color: EventColor
}

export type EventColor = {
    background: string,
    chip: string,
    text: string
}

export enum EventType {
    UNKNOWN,
    HAPPY_HOUR,
    HOLIDAY
}