import { RootReducer } from "../../reducers";
import { PaymentMethod, TablePaymentMethod } from "./types";

export const tablePaymentMethodsSelector = (
  state: RootReducer
): TablePaymentMethod[] | null =>
  state.paymentMethods?.paymentMethodsResponse?.data?.map(
    (paymentMethod: PaymentMethod) => ({
      active: paymentMethod.active,
      balance: paymentMethod.balance,
      created_at: paymentMethod.created_at,
      email: paymentMethod.email,
      external_id: paymentMethod.external_id,
      id: paymentMethod.id,
      name: paymentMethod.name,
      plan_id: paymentMethod.plan_id,
      type: paymentMethod.type,
      start_at: paymentMethod.start_at,
      end_at: paymentMethod.end_at,
      mobile: paymentMethod.mobile
    })
  ) || null;

export const paymentMethodsTotalPagesSelector = (
  state: RootReducer
): number | null =>
  state.paymentMethods?.paymentMethodsResponse?.totalPages || null;

export const paymentMethodSelector = (state: RootReducer): PaymentMethod | null =>
  state.paymentMethods.paymentMethod;

export const isPaymentMethodCreatingSelector = (state: RootReducer): boolean =>
  state.paymentMethods.isPaymentMethodCreating;

export const isPaymentMethodUpdatingSelector = (state: RootReducer): boolean =>
  state.paymentMethods.isPaymentMethodUpdating;

export const isPaymentMethodDeletingSelector = (state: RootReducer): boolean =>
  state.paymentMethods.isPaymentMethodDeleting;

export const isAddingCreditSelector = (state: RootReducer): boolean =>
  state.paymentMethods.isAddingCredit;

export const isPaymentMethodLoadingSelector = (state: RootReducer): boolean =>
  state.paymentMethods.isPaymentMethodLoading;

export const arePaymentMethodsLoadingSelector = (state: RootReducer): boolean =>
  state.paymentMethods.arePaymentMethodsLoading;
