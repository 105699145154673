import Link, { LinkProps } from "@mui/material/Link";
import MuiTable from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LinkProps as RouterLinkProps } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  border-radius: 5px;
  width: 80%;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1, 2, 1, 2)};
  background-color: ${({ theme }) => theme.palette.secondary.darker};
  border-radius: 5px 5px 0 0;
`;

export const SearchTextField = styled(TextField as any)` // TODO: fix types
  margin-left: auto;
`;

export const NoDataText = styled(Typography)`
  text-align: center;
`;

export const Table = styled(MuiTable)`
  table-layout: fixed;
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 0 0 5px 5px;

  & :last-child .MuiTableRow-root:last-child .MuiTableCell-root {
    border-bottom: none;
  }
`;

export const TableCellText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableTotalCellText = styled(TableCellText)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ActionsTableCell = styled(TableCell)`
  width: ${({ theme }) => theme.spacing(6)};
`;

export const LoaderContainer = styled.div`
  height: ${({ theme }) => theme.spacing(20)};
`;

export const TableLink = styled(Link)<LinkProps & RouterLinkProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const TablePagination = styled.div`
  display: flex;
  justify-content: flex-end;
`;
