import { FC, useState } from "react"
import { EventWrapper } from "./styles"
import { Box, Chip } from "@mui/material"
import { EVENT_STYLES } from "./constants"
import { CalendarEvent, EventCategory, EventType } from "./types"

type Props = {
    event: CalendarEvent
}

export const EventComp: FC<Props> = ({ event }: Props) => {
    const [category, setCategory] = useState<EventCategory>(EVENT_STYLES[event.resource.type] || EVENT_STYLES[EventType.UNKNOWN]);

    return (
        <EventWrapper style={{ backgroundColor: category.color.background, color: category.color.text }}>
            <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {category && (
                    <Chip
                        label={category.label}
                        size="small"
                        style={{
                            marginRight: "5px",
                            backgroundColor: category.color.chip,
                            color: category.color.text
                        }} />
                )}
                {event.title}
            </Box>
        </EventWrapper>
    )
};