import Keycloak from "keycloak-js";
import {appConfig} from "./appConfig";

export const initOptions = {
    onLoad: 'login-required',
    checkLoginIframe: false
}

export const keycloak = Keycloak({
    url: `https://auth.${appConfig.domain}/auth`,
    realm: appConfig.keycloak.realm,
    clientId: appConfig.keycloak.clientId
})