import { reducerWithInitialState } from "typescript-fsa-reducers";
import { enqueueSnackbar, closeSnackbar, removeSnackbar, clear } from "./actions";
import { Notification, NotificationPayload } from './types';

export type Reducer = {
  notifications: Notification[];
};

const initialState: Reducer = {
  notifications: [],
}


export const reducer = reducerWithInitialState(initialState)
  .case(
    enqueueSnackbar,
    (state, payload: NotificationPayload): Reducer => {
      const { message, variant } = payload;
      const key = new Date().getTime() + Math.random();
      const res = {
        key,
        message,
        options: { variant }
      }
      return {
        ...state,
        notifications: [...state.notifications, res],
      }
    }
  )
  .case(
    closeSnackbar,
    (state, payload): Reducer => {
      return {
        ...state,
        notifications: state.notifications.map(notification => (
          (notification.key === payload)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )),
      }
    }
  )
  .case(
    removeSnackbar,
    (state, payload): Reducer => {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== payload,
        ),
      }
    }
  )
  .case(clear, (): Reducer => initialState);
