import { watcherSaga as authSaga } from "./modules/auth/sagas";
import { watcherSaga as stationsSaga } from "./modules/stations/sagas";
import { watcherSaga as terminalsSaga } from "./modules/terminals/sagas";
import { watcherSaga as payMethodsSaga } from "./modules/paymentMethods/sagas";
import { watcherSaga as transactionsSaga } from "./modules/transactions/sagas";
import { watcherSaga as plansSaga } from "./modules/plans/sagas";
import { watcherSaga as programsSaga } from "./modules/programs/sagas";
import { watcherSaga as calendarSaga } from "./modules/calendar/sagas";
import { all, AllEffect } from "redux-saga/effects";

export function* rootSaga(): Generator<AllEffect<unknown>, void, unknown> {
  yield all([
    authSaga(),
    stationsSaga(),
    terminalsSaga(),
    payMethodsSaga(),
    plansSaga(),
    programsSaga(),
    transactionsSaga(),
    calendarSaga(),
  ]);
}
