export const palette = {
  light: {
    primary: {
      main: "#ffffff",
      lighter: "#f5f5f5",
      contrastText: "#222"
    },
    highlight: {
      main: "rgba(0, 0, 0, 0.1)",
      lighter: "rgba(0, 0, 0, 0.15)",
    },
    accent: {
      main: "#EF414B",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#f2f2f2",
      lighter: "#ededed",
      darker: "#e1e1e1"
    },
    drawer: {
      main: "#1a1a1a",
      contrastText: "#fff"
    },
    calendar: {
      primary: "#ffffff",
      secondary: "#f2f2f2",
      primarySelected: "#dddddd",
      secondarySelected: "#e1e1e1",
      today: "#f57a47",
      todaySelected: "#f79b6a",
      border: "#f2f2f2",
      events: {
        focus: "#0003",
        focusOutline: "#000"
      }
    }
  },
  dark: {
    primary: {
      main: "#171717",
      lighter: "#252424",
      contrastText: "#fff"
    },
    highlight: {
      main: "rgba(255, 255, 255, 0.1)",
      lighter: "rgba(255, 255, 255, 0.05)",
    },
    accent: {
      main: "#eb121e",
      lighter: ""
    },
    secondary: {
      main: "#313131",
      lighter: "#363636",
      darker: "#262626",
    },
    drawer: {
      main: "#1a1a1a",
      lighter: ""
    },
    calendar: {
      primary: "#171717",
      secondary: "#252525",
      primarySelected: "#282828",
      secondarySelected: "#363636",
      today: "#f35f2d",
      todaySelected: "#d05020",
      border: "#313131",
      events: {
        focus: "#fff3",
        focusOutline: "#fff"
      }
    }
  }
};
