import MuiGlobalStyles from "@mui/material/GlobalStyles";
import { FC } from "react";
import "../../../assets/styles.css";

export const GlobalStyles: FC = () => (
  <MuiGlobalStyles
    styles={`
      html, body, #root {
        height: 100%;
      }
      * {
        scrollbar-width: auto;
        scrollbar-color: #5e5e5e rgba(0,0,0,0);
      }
    
      *::-webkit-scrollbar {
        width: 8px;
      }
    
      *::-webkit-scrollbar-track {
        background: rgba(0,0,0,0);
      }
    
      *::-webkit-scrollbar-thumb {
        background-color: #5e5e5e;
        border-radius: 10px;
        border: 3px solid rgba(0,0,0,0);
      }
    `}
  />
);
