import { ChangeEvent, FC, useState } from "react";
import { Button, Box } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export const FileUpload: FC<{
    label: string;
    name: string;
    handleValue: (event: any) => void;
    acceptFileTypes?: string;
}> = ({
    label,
    acceptFileTypes,
    name,
    handleValue
}) => {
        const [filename, setFilename] = useState("");

        const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files;
            const fileArray = Array.from(files ?? []);
            handleValue(fileArray);
            if (files) {
                let names = "";
                for (let i = 0; i < files.length; i++) {
                    names += files[0].name + ", ";
                }
                setFilename(names.substring(0, names.length - 2));
            }
        };

        return (
            <>
                <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    sx={{ marginRight: "1rem" }}
                >
                    {label}
                    <input type="file" name={name} accept={acceptFileTypes ?? "."} hidden onChange={handleFileUpload} />
                </Button>
                <Box>{filename}</Box>
            </>
        );
    }
