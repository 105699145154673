import { RootReducer } from "../../reducers";
import { Plan, TablePlan } from "./types";

export const tablePlansSelector = (state: RootReducer): TablePlan[] | null =>
  state.plans.plans?.map((plan) => ({
    created_at: plan.created_at,
    default_amount: plan.default_amount,
    id: plan.id,
    name: plan.name,
    programs: plan.programs,
    type: plan.type,
    value: plan.value,
    value_type: plan.value_type,
    default: plan.default,
  })) || null;

export const planSelector = (state: RootReducer): Plan | null =>
  state.plans.plan;

export const isPlanCreatingSelector = (state: RootReducer): boolean =>
  state.plans.isPlanCreating;

export const isPlanUpdatingSelector = (state: RootReducer): boolean =>
  state.plans.isPlanUpdating;

export const isPlanDeletingSelector = (state: RootReducer): boolean =>
  state.plans.isPlanDeleting;

export const isPlanLoadingSelector = (state: RootReducer): boolean =>
  state.plans.isPlanLoading;

export const arePlansLoadingSelector = (state: RootReducer): boolean =>
  state.plans.arePlansLoading;
