import { CalendarEvent, EventType } from "components/common/Calendar/types";
import { ICSFile, ICSFileEvent, RRule } from "./types";

import moment from "moment";
import ical from "ical/ical.js";

function stringifyIcs(ics: ICSFile): string {
    return "BEGIN:VCALENDAR\n" +
        Object.entries(ics).filter(([_, event]) => event.type == "VEVENT").map(([uid, event]: [string, ICSFileEvent]) => {
            let ics = `BEGIN:VEVENT`;
            ics += `\nUID:${uid}`;
            ics += `\nDTSTAMP:${moment().utc().format("YYYYMMDDTHHmmss")}`;
            ics += `\nDTSTART${event.allDay ? ";VALUE=DATE" : ""}:${moment(event.start).format(event.allDay ? "YYYYMMDD" : "YYYYMMDDTHHmmss")}`;
            ics += `\nDTEND${event.allDay ? ";VALUE=DATE" : ""}:${moment(event.end).format(event.allDay ? "YYYYMMDD" : "YYYYMMDDTHHmmss")}`;
            ics += `\nSUMMARY:${event.summary}`;
            ics += `${event.rrule !== undefined ? `\nRRULE:${event.rrule}` : ""}`;
            ics += event['event-type'] !== undefined ? `\nEVENT-TYPE:${event['event-type']}` : "";
            ics += event['percentage'] !== undefined ? `\nPERCENTAGE:${event['percentage']}` : "";
            ics += event.exdate !== undefined ? `\nEXDATE:${Object.values(event.exdate).map((date) => moment(date).utc().format("YYYYMMDDTHHmmss")).join(",")}` : "";
            ics += `\nEND:VEVENT`;
            return ics;
        }).join("\n") +
        "\nEND:VCALENDAR";
}

function getFreqencyFromRRule(freq: string | undefined): moment.unitOfTime.DurationConstructor {
    if (freq === undefined) {
        return "week";
    }

    freq = freq.toLowerCase();

    switch (freq) {
        case "secondly":
            return "second";
        case "minutely":
            return "minute";
        case "hourly":
            return "hour";
        case "daily":
            return "day";
        case "weekly":
            return "week";
        case "monthly":
            return "month";
        case "yearly":
            return "year";
    }

    throw new Error(`Frequency not implemented ${freq}`);
}

function frequencyToRRule(freq: moment.unitOfTime.DurationConstructor): string {
    switch (freq) {
        case "second":
            return "SECONDLY";
        case "minute":
            return "MINUTELY";
        case "hour":
            return "HOURLY";
        case "day":
            return "DAILY";
        case "week":
            return "WEEKLY";
        case "month":
            return "MONTHLY";
        case "year":
            return "YEARLY";
    }

    throw new Error(`Frequency not implemented ${freq}`);
}

function stringToEventType(type: string, alt: EventType = EventType.UNKNOWN): EventType {
    switch (type) {
        case "HOLIDAY":
            return EventType.HOLIDAY;
        case "HAPPY_HOUR":
            return EventType.HAPPY_HOUR;
        default:
            return alt;
    }
}

function eventTypeToString(type: EventType): string {
    switch (type) {
        case EventType.HOLIDAY:
            return "HOLIDAY";
        case EventType.HAPPY_HOUR:
            return "HAPPY_HOUR";
        default:
            return "UNKNOWN";
    }
}

function updateIcsEventRecurrency(icsEvent: ICSFileEvent, event: CalendarEvent): ICSFileEvent | null {
    if (event.recurrency === undefined) {
        return icsEvent;
    }

    icsEvent.rrule = `FREQ=${frequencyToRRule(event.recurrency.frequency)};`;
    icsEvent.rrule += `INTERVAL=${event.recurrency.interval};`;
    if (event.recurrency.until !== undefined) {
        if (event.recurrency.until < event.start) {
            // Event is over before it starts so it is useless in the ics
            return null;
        }

        icsEvent.rrule += `UNTIL=${moment(event.recurrency.until).toISOString()};`;
    }

    if (event.recurrency.count !== undefined && !isNaN(event.recurrency.count)) {
        if (event.recurrency.count <= 0) {
            // Event is over before it starts so it is useless in the ics
            return null;
        }

        icsEvent.rrule += `COUNT=${event.recurrency.count};`;
    }

    if (event.recurrency.exceptions !== undefined) {
        event.recurrency.exceptions.forEach((date) => {
            if (icsEvent.exdate === undefined) {
                icsEvent.exdate = {};
            }

            icsEvent.exdate[moment(date).format("YYYYMMDD")] = date;
        });
    }

    return icsEvent;
}

function updateIcs(ics: ICSFile, event: CalendarEvent): ICSFile {
    if (ics[event.resource.id] === undefined) {
        return addEventIcs(ics, event);
    }

    let ev = ics[event.resource.id];

    ev.summary = event.title;
    ev.start = event.start;
    ev.end = event.end;

    const updatedEv = updateIcsEventRecurrency(ev, event);

    if (updatedEv === null) {
        delete ics[event.resource.id];
        return ics;
    }

    ev = updatedEv;

    ev['event-type'] = eventTypeToString(event.resource.type);

    if (event.resource.type === EventType.HAPPY_HOUR) {
        ev['percentage'] = event.resource.percentage;
    }

    ev.allDay = event.allDay;

    ics[event.resource.id] = ev;

    return ics;
}

function addEventIcs(ics: ICSFile, event: CalendarEvent): ICSFile {
    let ev: ICSFileEvent = {
        type: "VEVENT",
        summary: event.title,
        start: event.start,
        end: event.end,
        'event-type': eventTypeToString(event.resource.type),
    };

    if (event.resource.type === EventType.HAPPY_HOUR) {
        ev["percentage"] = event.resource.percentage;
    }

    const updatedEv = updateIcsEventRecurrency(ev, event);
    if (updatedEv === null) {
        return ics;
    }

    ev = updatedEv;

    ics[event.resource.id] = ev;

    return ics;
}

export function updateIcsEvents(ics: string, events: CalendarEvent[]): string {
    let data: ICSFile = ical.parseICS(ics);

    for (const event of events) {
        data = updateIcs(data, event);
    }

    const str = stringifyIcs(data);

    return str;
}

export function deleteIcsEvent(ics: string, event: CalendarEvent): string {
    let data: ICSFile = ical.parseICS(ics);

    delete data[event.resource.id];

    const str = stringifyIcs(data);

    return str;
}


export function eventsFromIcs(ics: string, type: EventType = EventType.UNKNOWN): CalendarEvent[] {

    const data: ICSFile = ical.parseICS(ics);

    return Object.entries(data).filter(([_, ev]) => ev.type === "VEVENT").map(([uid, ev]) => {
        const event: CalendarEvent = {
            title: typeof ev.summary === 'string' ? ev.summary : 'TITLE NOT FOUND',
            allDay: ev.start["dateOnly"] ?? false,
            start: moment(ev.start).utc().toDate(),
            end: moment(ev.end).utc().toDate(),
            resource: {
                id: uid,
                type: stringToEventType(ev['event-type'], type),
                percentage: ev['percentage']
            },
        };

        if (ev.rrule === undefined) {
            return event;
        }


        const rrule: RRule = {}

        ev.rrule.split(";").forEach((parameter: string) => {
            const [key, value] = parameter.split("=");

            rrule[key.toLowerCase()] = value;
        });

        let interval = parseInt(rrule.interval ?? "1");

        if (isNaN(interval)) {
            console.log("Invalid interval setting it to 1");
            interval = 1;
        }

        event.recurrency = {
            interval: interval,
            frequency: getFreqencyFromRRule(rrule.freq),
            until: rrule.until !== undefined ? moment.utc(rrule.until).local().toDate() : undefined,
            count: rrule.count !== undefined ? parseInt(rrule.count) ?? 1 : undefined
        }

        if (ev.exdate !== undefined) {
            event.recurrency.exceptions = Object.entries(ev.exdate).map(([_, date]: [string, Date]) => moment.utc(date).local().toDate());
        }

        return event;
    });
}