import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import { keycloak } from "../../../keycloak";
import { axiosInstance } from "../../../axios";
import { AxiosResponse } from "axios";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import * as actions from "./actions";

export function* getStationCalendarSaga(
    action: Action<GetStationCalendarParams>
): SagaIterator<void> {
    try {
        const token = keycloak.idToken?.toString() || "";
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response: GetStationCalendarResponse = {};

        const calendarResponse: AxiosResponse<GetCalendarResponse> = yield call(
            axiosInstance.get,
            `clients/@me/calendar`
        );

        response.default = calendarResponse.data.Calendar;

        if (response.default === "") {
            response.default = "BEGIN:VCALENDAR\nEND:VCALENDAR";
        }


        for (const stationId of action.payload.stationIds) {
            const calendarResponse: AxiosResponse<GetCalendarResponse> = yield call(
                axiosInstance.get,
                `clients/@me/calendar/${stationId}`
            );

            response[stationId] = calendarResponse.data.Calendar;


            if (response[stationId] === "") {
                response[stationId] = "BEGIN:VCALENDAR\nEND:VCALENDAR";
            }
        }

        yield put(
            actions.getStationCalendar.done({
                params: action.payload,
                result: response
            })
        );
    } catch (e) {
        yield put(
            actions.getStationCalendar.failed({ params: action.payload, error: e })
        );
    }
}

export function* putCalendarSaga(
    action: Action<PutCalendarParams>
): SagaIterator<void> {
    try {
        const token = keycloak.idToken?.toString() || "";
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const response: AxiosResponse<PutCalendarResponse> = yield call(
            axiosInstance.put,
            `clients/@me/calendar`,
            action.payload
        );

        yield put(
            actions.putCalendar.done({
                params: action.payload,
                result: response.data
            })
        );
    } catch (e) {
        yield put(
            actions.putCalendar.failed({ params: action.payload, error: e })
        );
    }
}

export function* putStationCalendarSaga(
    action: Action<PutStationCalendarParams>
): SagaIterator<void> {
    try {
        const token = keycloak.idToken?.toString() || "";
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const putPayload = {
            Calendar: action.payload.Calendar
        }

        const response: AxiosResponse<PutStationCalendarResponse> = yield call(
            axiosInstance.put,
            `clients/@me/calendar/${action.payload.stationId}`,
            putPayload
        );

        yield put(
            actions.putStationCalendar.done({
                params: action.payload,
                result: response.data
            })
        );
    } catch (e) {
        yield put(
            actions.putStationCalendar.failed({ params: action.payload, error: e })
        );
    }
}

export function* watcherSaga(): SagaIterator<void> {
    yield all([
        takeLeading(actions.getStationCalendar.started, getStationCalendarSaga),
        takeEvery(actions.putCalendar.started, putCalendarSaga),
        takeEvery(actions.putStationCalendar.started, putStationCalendarSaga)
    ]);
}
