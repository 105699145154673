import { SagaIterator } from "redux-saga";
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  RaceEffect,
  TakeEffect,
  takeLatest,
  takeLeading
} from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { keycloak } from "../../../keycloak";
import * as actions from "./actions";
import { GetUserDetailsParams, KeycloakUser } from "./types";

export function* logoutSaga(): SagaIterator<void> {
  try {
    yield call(keycloak.logout);
    yield put(actions.logout.done({}));
  } catch (e) {
    yield put(actions.logout.failed({ error: e }));
  }
}

export function* getUserDetailsSaga(
  action: Action<GetUserDetailsParams>
): SagaIterator<void> {
  try {
    const user: KeycloakUser = yield call(keycloak.loadUserProfile);
    user.id = keycloak.subject;

    yield put(
      actions.getUserDetails.done({
        params: action.payload,
        result: user
      })
    );
  } catch (e) {
    yield put(
      actions.getUserDetails.failed({ params: action.payload, error: e })
    );
  }
}

export function* watcherSaga(): Generator<
  AllEffect<
    | Generator<
        TakeEffect | RaceEffect<TakeEffect | CallEffect<void>>,
        void,
        unknown
      >
    | ForkEffect<never>
  >,
  void,
  unknown
> {
  yield all([
    takeLeading(actions.getUserDetails.started, getUserDetailsSaga),
    takeLatest(actions.logout.started, logoutSaga)
  ]);
}
