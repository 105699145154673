import { yupResolver } from "@hookform/resolvers/yup";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Info from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { BasicDatePicker } from "components/common/FormFields/BasicDatePicker";
import { CurrencyAmount } from "components/common/FormFields/CurrencyAmount";
import { SimpleSelect } from "components/common/FormFields/SimpleSelect";
import { MultiSelect } from "components/common/FormFields/MultiSelect";
import { PhoneNumber } from "components/common/FormFields/PhoneNumber";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { isSelectOption } from "typeGuards/isSelectOption";
import { isUndefined } from "typeGuards/isUndefined";
import { object, SchemaOf, string } from "yup";
import { Loader } from "../Loader";
import * as s from "./styles";
import { FIELD_TYPES, DialogField, DialogProps } from "./types";
import { useTranslation } from "react-i18next";
import { IconButton, Table, TableCell, TableRow } from "@mui/material";
import { Date, Time } from "../DateTime";

export const Dialog: FC<DialogProps> = ({
  title,
  fields,
  onClose,
  isOpened,
  isLoading,
  isLocked
}: DialogProps) => {
  const { t } = useTranslation();
  const [areDividersVisible, setAreDividersVisible] = useState(false);
  const [dialogContentNode, setDialogContentNode] =
    useState<HTMLDivElement | null>(null);

  const fieldValues: Record<string, unknown> = {};
  fields?.forEach((field) => (fieldValues[field.name] = field.value));

  useEffect(() => {
    const handleResize = () => {
      if (dialogContentNode) {
        dialogContentNode.scrollHeight > dialogContentNode.clientHeight;
        setAreDividersVisible(
          dialogContentNode.scrollHeight > dialogContentNode.clientHeight
        );
      } else {
        setAreDividersVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [dialogContentNode]);

  const handleFormDialogContentRef = useCallback((node: HTMLDivElement) => {
    setDialogContentNode(node);
  }, []);

  const FORM_ID = `dialog-${title.replace(/\s/g, "-")}`;

  const renderExplanation = (field: DialogField) => {
    return (
      <Tooltip
        title={field?.explanation ? field?.explanation : ""}
        placement={"top"}
        sx={{ alignSelf: "flex-end" }}
      >
        <Info color={"info"} />
      </Tooltip>
    );
  };

  return (
    <s.Dialog
      open={isOpened}
      onClose={onClose}
      scroll={"paper"}
      data-testid={"dialog"}
    >
      <s.DialogTitle data-testid={"dialog-title"}>
        {title}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </s.DialogTitle>
      <s.DialogContent
        ref={handleFormDialogContentRef}
        dividers={areDividersVisible}
      >
        {isLoading ? (
          <s.LoaderContainer>
            <Loader text={t("uiComponents.loader.texts.loading_data")} />
          </s.LoaderContainer>
        ) : (
          <Table>
            {fields?.map((field, i) => {
              const currentType = field.type;

              const invisible =
                typeof field?.invisible === "function"
                  ? field?.invisible(fieldValues)
                  : false;

              const value =
                typeof field.value === "function"
                  ? field.value()
                  : field.value || undefined;

              if (value === undefined) {
                return <></>;
              }

              switch (currentType) {
                case FIELD_TYPES.TEXT:
                  return (
                    <TableRow sx={{ display: invisible ? "none" : undefined }}>
                      <TableCell>
                        <h4>{field.label}:</h4>
                      </TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  );
                case FIELD_TYPES.DATE_TIME:
                  return (
                    <TableRow sx={{ display: invisible ? "none" : undefined }}>
                      <TableCell>
                        <h4>{field.label}:</h4>
                      </TableCell>
                      <TableCell>
                        <Date datetime={value} /> <Time datetime={value} />
                      </TableCell>
                    </TableRow>
                  );
                case FIELD_TYPES.BOOLEAN:
                  return (
                    <TableRow sx={{ display: invisible ? "none" : undefined }}>
                      <TableCell>
                        <h4>{field.label}:</h4>
                      </TableCell>
                      <TableCell>{value ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  );
              }
            })}
          </Table>
        )}
      </s.DialogContent>
    </s.Dialog>
  );
};
