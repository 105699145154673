import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import MuiTablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { Menu } from "components/common/Menu";
import {
  ChangeEvent,
  Component,
  FunctionComponent,
  MouseEvent,
  useCallback,
  useRef,
  useState
} from "react";
import { Link as RouterLink } from "react-router-dom";
import { isNull } from "typeGuards/isNull";
import { isNumber } from "typeGuards/isNumber";
import { isString } from "typeGuards/isString";
import { isUndefined } from "typeGuards/isUndefined";
import { ELEMENT_IDS } from "../../../constants";
import { Loader } from "../Loader";
import * as s from "./styles";
import { ORDERS, TableColumn, TableProps, TABLE_SORTING_TYPES } from "./types";
import { useTranslation } from "react-i18next";
import { useMount } from "hooks/useMount";

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 100];

export const Table = <T extends { id: string }>({
  rows,
  rowsData,
  columns,
  actions,
  toolbarItems,
  isLoading,
  itemLink,
  totalRow,
  isSearchEnabled,
  isPaginationEnabled,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  count,
  isSortingEnabled,
  onChangeSearch,
  searchString,
  backlightTableProperties,
  tableRowActions,
  tableRowComponents,
  defaultSortingColumn
}: TableProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const [isActionsMenuOpened, setIsActionsMenuOpened] =
    useState<boolean>(false);
  const [sorting, setSorting] = useState<{
    column: string | null;
    order?: ORDERS;
  }>({ column: null });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [search, setSearch] = useState("");

  const selectedItem = !isNull(selectedItemId)
    ? rows.find((row) => row.id === selectedItemId)
    : undefined;

  const sortBy = useCallback(
    (column) => () => {
      setSorting({
        column,
        order:
          column !== sorting.column || sorting.order === ORDERS.DESC
            ? ORDERS.ASC
            : ORDERS.DESC
      });
    },
    [sorting]
  );

  let sortedRows = rows;
  const sortingColumn = sorting.column;

  useMount(() => {
    if (defaultSortingColumn) {
      setSorting({
        column: defaultSortingColumn.column,
        order: ORDERS[defaultSortingColumn.order]
      });
    }
  });

  if (sortingColumn) {
    sortedRows = [...rows].sort((a, b) => {
      let aValue = a[sortingColumn];
      let bValue = b[sortingColumn];

      const column = columns.find((column) => column.key === sortingColumn);
      if (
        column?.sortingType === TABLE_SORTING_TYPES.DATE &&
        isString(aValue) &&
        isString(bValue)
      ) {
        aValue = new Date(aValue).valueOf();
        bValue = new Date(bValue).valueOf();
      }

      if (
        column?.sortingType === TABLE_SORTING_TYPES.NUMBER &&
        isString(aValue) &&
        isString(bValue)
      ) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (sorting.order === ORDERS.ASC) {
        if (aValue < bValue) {
          return -1;
        }
        if (aValue > bValue) {
          return 1;
        }
      }

      if (sorting.order === ORDERS.DESC) {
        if (aValue > bValue) {
          return -1;
        }
        if (aValue < bValue) {
          return 1;
        }
      }

      return 0;
    });
  }

  const filteredRows = search
    ? sortedRows.filter((row) =>
        columns.some(
          (column) =>
            String(row[column.key])
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1
        )
      )
    : sortedRows;

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChangeSearch) {
        onChangeSearch(e);
      } else {
        setSearch(e.target.value);
      }
    },
    [onChangeSearch]
  );

  const handleActionsMenuButtonClick = useCallback(
    (id: string) => (e: MouseEvent<HTMLButtonElement>) => {
      actionsMenuButtonRef.current = e.currentTarget;
      setSelectedItemId(id);
      setIsActionsMenuOpened(!isActionsMenuOpened);
    },
    [isActionsMenuOpened]
  );

  const handleActionsMenuClose = useCallback(() => {
    setIsActionsMenuOpened(false);
  }, []);

  const handleActionsMenuItemClick = useCallback(
    (callback: (id: string) => void) => () => {
      if (selectedItemId) {
        setIsActionsMenuOpened(false);
        callback(selectedItemId);
      }
    },
    [selectedItemId]
  );

  const actionsMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const rowsPerPageOptions =
    isNumber(rowsPerPage) &&
    Number.isInteger(rowsPerPage) &&
    rowsPerPage > 0 &&
    !DEFAULT_ROWS_PER_PAGE_OPTIONS.includes(rowsPerPage)
      ? [...DEFAULT_ROWS_PER_PAGE_OPTIONS, rowsPerPage].sort((a, b) => a - b)
      : DEFAULT_ROWS_PER_PAGE_OPTIONS;

  const menuBoundariesElement = document.getElementById(
    ELEMENT_IDS.CONTENT_CONTAINER
  );

  const defineBacklightColor = (key, value) => {
    const obj = backlightTableProperties?.[key];
    if (!obj) return;
    return obj.conditionFn(value);
  };

  const getRowData = (id) => {
    if (!rowsData) return null;
    return rowsData.find((row) => row.id === id);
  };

  return (
    <s.Container>
      <s.Toolbar>
        {toolbarItems}
        {isSearchEnabled && (
          <s.SearchTextField
            color={"accent"}
            value={onChangeSearch ? searchString : search}
            label={t("uiComponents.search.label")}
            inputProps={{
              title: "Search"
            }}
            onChange={handleSearchChange}
            size={"small"}
          />
        )}
      </s.Toolbar>
      <TableContainer data-testid={"table-container"}>
        <s.Table>
          <TableHead>
            <TableRow>
              {columns.map((column: TableColumn) => (
                <TableCell key={String(column.key)}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {isSortingEnabled ? (
                      <TableSortLabel
                        active={sorting.column === column.key}
                        direction={sorting.order}
                        onClick={sortBy(column.key)}
                      >
                        <s.TableCellText title={column.label}>
                          {column.label}
                        </s.TableCellText>
                      </TableSortLabel>
                    ) : (
                      <s.TableCellText title={column.label}>
                        {column.label}
                      </s.TableCellText>
                    )}
                  </Box>
                </TableCell>
              ))}
              {actions && actions.length > 0 && <s.ActionsTableCell />}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <>
              <TableBody data-testid={"table-body"}>
                {filteredRows.length > 0 ? (
                  filteredRows.map((row: any, index: number) => (
                    <TableRow
                      key={row.id}
                      data-testid={"table-row"}
                      sx={
                        row?.highlighted ? { bgcolor: "secondary.darker" } : {}
                      }
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell
                            data-testid={`table-cell-${String(column.key)}`}
                            key={String(column.key)}
                          >
                            {itemLink &&
                            (isUndefined(itemLink.isEnabled) ||
                              itemLink.isEnabled(row)) &&
                            itemLink.getURL &&
                            itemLink.column === column.key ? (
                              <s.TableLink
                                component={RouterLink}
                                to={itemLink.getURL(row.id) || "#"}
                                title={String(row[column.key])}
                              >
                                {String(row[column.key])}
                              </s.TableLink>
                            ) : (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                {tableRowActions?.[column.key] && (
                                  <Checkbox
                                    checked={getRowData(row.id)?.[column.key]}
                                    onChange={() =>
                                      tableRowActions?.[column.key]?.actionFn(
                                        row.id
                                      )
                                    }
                                    color={"success"}
                                  />
                                )}
                                {tableRowComponents?.[index][column.key] ? (
                                  tableRowComponents?.[index][column.key]
                                ) : (
                                  <></>
                                )}
                                {row[column.key] != undefined && (
                                  <s.TableCellText
                                    color={defineBacklightColor(
                                      column.key,
                                      getRowData(row.id)?.[column.key]
                                    )}
                                    variant={"body2"}
                                    title={String(row[column.key])}
                                  >
                                    {String(row[column.key])}
                                  </s.TableCellText>
                                )}
                              </Box>
                            )}
                          </TableCell>
                        );
                      })}
                      {actions && actions.length && (
                        <s.ActionsTableCell
                          padding={"checkbox"}
                          data-testid={"table-row-actions-menu-button"}
                        >
                          {actions.some(
                            (action) =>
                              !action.isDisabled || !action.isDisabled(row)
                          ) && (
                            <Tooltip title={"Actions"}>
                              <span>
                                <IconButton
                                  onClick={handleActionsMenuButtonClick(row.id)}
                                  title={"Actions"}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        </s.ActionsTableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length + (actions ? 1 : 0)}
                      data-testid={"table-cell-no-data"}
                    >
                      <s.NoDataText>No data</s.NoDataText>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {totalRow && (
                <TableFooter>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={String(column.key)}>
                        <s.TableTotalCellText
                          title={String(totalRow[column.key])}
                        >
                          {String(totalRow[column.key])}
                        </s.TableTotalCellText>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </s.Table>
      </TableContainer>
      {isLoading && (
        <s.LoaderContainer>
          <Loader text={"Loading data..."} />
        </s.LoaderContainer>
      )}
      {onChangePage &&
        onChangeRowsPerPage &&
        isPaginationEnabled &&
        rowsPerPage &&
        isNumber(page) &&
        isNumber(count) && (
          <MuiTablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component={s.TablePagination}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        )}
      <Menu
        isOpened={isActionsMenuOpened}
        onClose={handleActionsMenuClose}
        anchorEl={actionsMenuButtonRef.current}
        boundariesElement={menuBoundariesElement}
        items={actions
          ?.filter(
            (action) =>
              (selectedItem &&
                action.isDisabled &&
                !action.isDisabled(selectedItem)) ||
              !action.isDisabled
          )
          .map((action) => ({
            label: action.label,
            onClick: handleActionsMenuItemClick(action.handler)
          }))}
      />
    </s.Container>
  );
};
