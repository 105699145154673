import { FC } from "react";
import { useTranslation } from "react-i18next";
import { dateOptions, localeMap, timeOptions } from "./options";

export const Date: FC<{ datetime: Date }> = ({ datetime }) => {
    const [t, i18n] = useTranslation();

    const dateString = datetime.toLocaleDateString(
        localeMap[i18n.language],
        dateOptions
    );

    return <>{dateString}</>
}

export const Time: FC<{ datetime: Date }> = ({ datetime }) => {
    const [t, i18n] = useTranslation();

    const timeString = datetime.toLocaleTimeString(
        localeMap[i18n.language],
        timeOptions
    );

    return <>{timeString}</>
}