import Button from "@mui/material/Button";
import { FormDialog } from "components/common/Dialog/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/Dialog/FormDialog/types";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem,
  TypeName
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as paymentMethodsActions from "../../../store/modules/paymentMethods/actions";
import * as plansActions from "../../../store/modules/plans/actions";
import {
  isAddingCreditSelector,
  isPaymentMethodCreatingSelector,
  isPaymentMethodDeletingSelector,
  isPaymentMethodUpdatingSelector,
  paymentMethodsTotalPagesSelector,
  tablePaymentMethodsSelector
} from "../../../store/modules/paymentMethods/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { boolean, date, number, string } from "yup";
import { DIALOG_TYPES } from "./types";
import { tablePlansSelector } from "../../../store/modules/plans/selectors";
import { useTranslation } from "react-i18next";
import { PayMethodTableFilterForm } from "components/common/TableFilterForm";
import { Pagination } from "@mui/material";
import { PayMethodTableFilterParams } from "components/common/TableFilterForm/types";
import {
  GetPaymentMethodsParams,
  TablePaymentMethod
} from "src/store/modules/paymentMethods/types";
import moment from "moment";

export const PaymentMethod: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const paymentMethods = useSelector(tablePaymentMethodsSelector);
  const totalPages = useSelector(paymentMethodsTotalPagesSelector);
  const plans = useSelector(tablePlansSelector);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });
  const [searchConfiguration, setSearchConfiguration] =
    useState<PayMethodTableFilterParams>({
      type: "",
      page: 1,
      order_by: "external_id",
      sorting_order: "asc",
      search: ""
    });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const isPaymentMethodCreating = useSelector(isPaymentMethodCreatingSelector);
  const isPaymentMethodUpdating = useSelector(isPaymentMethodUpdatingSelector);
  const isPaymentMethodDeleting = useSelector(isPaymentMethodDeletingSelector);
  const isPaymentMethodAddingCredit = useSelector(isAddingCreditSelector);
  const isOperationInProgress =
    isPaymentMethodCreating ||
    isPaymentMethodUpdating ||
    isPaymentMethodDeleting ||
    isPaymentMethodAddingCredit;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleCreatePayMethodButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE,
      isOpened: true
    });
  }, []);
  const handleAddCreditMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.ADD_CREDIT,
      isOpened: true
    });
  }, []);
  const handleEditPayMethodMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);
  const handleDeletePayMethodMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);
  const handleConfirmPayMethodActiveMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.IS_ACTIVE,
        isOpened: true
      });
    },
    []
  );

  const tableActions: TableRowActionsMenuItem[] = [
    {
      label: t("uiComponents.table.actions.add_credit"),
      handler: handleAddCreditMenuItemClick
    },
    {
      label: t("uiComponents.table.actions.edit"),
      handler: handleEditPayMethodMenuItemClick
    },
    {
      label: t("uiComponents.table.actions.delete"),
      handler: handleDeletePayMethodMenuItemClick
    }
  ];

  const localeMap = {
    en: "en-GB",
    de: "de-DE"
  };
  const parseDate = (utcDate) => {
    if (!utcDate) return "";
    else {
      return moment.utc(utcDate).toDate().toLocaleDateString(localeMap[i18n.language], {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
    }
  };
  const definePlanBalance = ({ type, value_type, balance }) => {
    const isUnlimited = type === "unlimited";
    const isCurrency = value_type === "currency";
    if (isUnlimited) return "-";
    else {
      return isCurrency
        ? `${balance % 100 == 0 ? balance / 100 : (balance / 100).toFixed(2)} €` // add 2 decimal places if the balance is not a flat number
        : balance;
    }
  };
  const tablePaymentMethods = () => {
    if (paymentMethods && plans) {
      const copy = JSON.parse(JSON.stringify(paymentMethods));
      return copy.map((paymentMethod) => {
        const plan = plans?.find((plan) => plan.id === paymentMethod.plan_id);
        return {
          ...paymentMethod,
          ...{
            plan_id: plan?.name,
            start_at: parseDate(paymentMethod.start_at),
            end_at: parseDate(paymentMethod.end_at),
            balance: definePlanBalance({
              type: plan?.type,
              value_type: plan?.value_type,
              balance: paymentMethod?.balance
            }),
            active: paymentMethod?.active
              ? t("uiComponents.table.body.active")
              : t("uiComponents.table.body.inactive")
          },
          // replace the database type key with the translation of the type
          type: filterableTypeNames.find(
            ({ key }) => key === paymentMethod.type
          )?.label
        };
      });
    } else return [];
  };
  const backlightTableProperties = {
    active: {
      conditionFn: (value) => (value ? "success.main" : "error")
    }
  };
  const tableRowActions = {
    active: {
      component: "checkbox", // TODO: use jsx element
      actionFn: handleConfirmPayMethodActiveMenuItemClick
    }
  };
  const tableColumns: TableColumn[] = [
    {
      key: "type",
      label: t("uiComponents.table.headers.type")
    },
    {
      key: "external_id",
      label: t("uiComponents.table.headers.external_id")
    },
    { key: "plan_id", label: t("uiComponents.table.headers.plan_id") },
    { key: "start_at", label: t("uiComponents.table.headers.start_date") },
    { key: "end_at", label: t("uiComponents.table.headers.end_date") },
    { key: "balance", label: t("uiComponents.table.headers.balance") },
    { key: "name", label: t("uiComponents.table.headers.name") },
    { key: "active", label: t("uiComponents.table.headers.is_active") }
  ];

  // adds the sorting order postfix ".asc" or ".desc" to the current order_by
  // element. Maps from a TableFilterParams (has sorting_order prop) to a
  // GetPaymentMethodsParams (has no sorting_order prop).
  const addSortingOrder = (
    tableFilterParams: PayMethodTableFilterParams
  ): GetPaymentMethodsParams => ({
    type: tableFilterParams.type,
    order_by: `${tableFilterParams.order_by}.${tableFilterParams.sorting_order}`,
    page: tableFilterParams.page,
    search: tableFilterParams.search
  });

  // table column keys/names to be displayed within the "Sort by" dropdown
  const sortableTableColumns: TableColumn[] = [
    {
      key: "external_id",
      label: t("uiComponents.table.headers.external_id")
    },
    // note that this column has an appended "_at"
    { key: "start_at", label: t("uiComponents.table.headers.start_date") },
    // note that this column has an appended "_at"
    { key: "end_at", label: t("uiComponents.table.headers.end_date") },
    { key: "balance", label: t("uiComponents.table.headers.balance") },
    { key: "name", label: t("uiComponents.table.headers.name") },
    { key: "active", label: t("uiComponents.table.headers.is_active") }
  ];

  const filterableTypeNames: TypeName[] = [
    {
      key: "fleetcard",
      label: t("uiComponents.table.headers.type_name.fleetcard")
    },
    {
      key: "voucher",
      label: t("uiComponents.table.headers.type_name.voucher")
    },
    {
      key: "license_plate",
      label: t("uiComponents.table.headers.type_name.license_plate")
    },
    {
      key: "cash",
      label: t("uiComponents.table.headers.type_name.cash")
    },
    {
      key: "fingerprint",
      label: t("uiComponents.table.headers.type_name.fingerprint")
    },
    {
      key: "mobile",
      label: t("uiComponents.table.headers.type_name.mobile")
    },
    {
      key: "card",
      label: t("uiComponents.table.headers.type_name.card")
    }
  ];

  const phoneRegExp = /[0-9]/;

  useMount(() => {
    dispatch(
      paymentMethodsActions.getPaymentMethods.started(
        addSortingOrder(searchConfiguration)
      )
    );
    dispatch(plansActions.getPlans.started({}));
  });

  useUnmount(() => {
    dispatch(paymentMethodsActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(
        paymentMethodsActions.getPaymentMethods.started(
          addSortingOrder(searchConfiguration)
        )
      );
    }
  }, [
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    searchConfiguration
  ]);

  useEffect(() => {
    dispatch(
      paymentMethodsActions.getPaymentMethods.started(
        addSortingOrder(searchConfiguration)
      )
    );
  }, [searchConfiguration, dispatch]);

  const handleConfirmCreatePayMethod = useCallback(
    (data: {
      active: boolean;
      balance: number;
      created_at: string;
      email: string;
      external_id: string;
      id: string;
      name: string;
      plan_id: number;
      type: string;
      start_at: string;
      end_at: string;
      mobile: string;
    }) => {
      if (data.type === "voucher") {
        dispatch(
          paymentMethodsActions.createVoucher.started({
            data: {
              plan_id: data.plan_id,
              start_at: moment(data.start_at).utc().toISOString(),
              end_at: moment(data.end_at).utc().toISOString(),
              email: data.email,
              mobile: data.mobile,
              name: data.name
            }
          })
        );
      } else {
        dispatch(
          paymentMethodsActions.createPaymentMethod.started({
            data: {
              ...data,
              start_at: moment(data.start_at).utc().toISOString(),
              end_at: moment(data.end_at).utc().toISOString()
            }
          })
        );
      }

      handleCloseDialog();
    },
    [dispatch, handleCloseDialog]
  );
  const handleConfirmEditPayMethod = useCallback(
    (data: {
      active: boolean;
      balance: number;
      created_at: string;
      email: string;
      external_id: string;
      id: string;
      name: string;
      plan_id: number;
      type: string;
      start_at: string;
      end_at: string;
      mobile: string;
    }) => {
      if (!selectedItemId) {
        return;
      }

      dispatch(
        paymentMethodsActions.updatePaymentMethod.started({
          id: selectedItemId,
          data: {
            ...data,
            start_at: moment(data.start_at).utc().toISOString(),
            end_at: moment(data.end_at).utc().toISOString()
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );
  const handleConfirmDeletePayMethod = useCallback(() => {
    if (!selectedItemId) {
      return;
    }

    dispatch(
      paymentMethodsActions.deletePaymentMethod.started({
        id: selectedItemId
      })
    );
    handleCloseDialog();
  }, [dispatch, selectedItemId, handleCloseDialog]);
  const handleConfirmActivePayMethod = useCallback(() => {
    if (!selectedItemId) {
      return;
    }

    const paymentMethod = paymentMethods?.find(
      (paymentMethod) => paymentMethod.id === selectedItemId
    );
    const paymentMethodCopy = JSON.parse(JSON.stringify(paymentMethod));
    paymentMethodCopy.active = !paymentMethod?.active;
    dispatch(
      paymentMethodsActions.updatePaymentMethod.started({
        id: selectedItemId,
        data: paymentMethodCopy
      })
    );
    handleCloseDialog();
  }, [dispatch, selectedItemId, handleCloseDialog]);
  const handleConfirmAddCredit = useCallback(
    (data: { add_balance: number }) => {
      if (!selectedItemId) {
        return;
      }

      const paymentMethod = paymentMethods?.find(
        (paymentMethod) => paymentMethod.id === selectedItemId
      );
      if (!paymentMethod) {
        return;
      }

      dispatch(
        paymentMethodsActions.addCredit.started({
          id: selectedItemId,
          data: {
            balance: paymentMethod.balance + data.add_balance
          }
        })
      );

      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const typeNamesAsValueLabel = () => {
    return filterableTypeNames.map((type) => ({
      label: type.label,
      value: type.key
    }));
  };

  const getTypeNameOption = (id) => {
    const plansList = typeNamesAsValueLabel() || [];
    return plansList.find((option) => id === option.value);
  };

  const plansListAsValueLabel = () => {
    return plans?.map((plan) => ({ label: plan.name, value: plan.id }));
  };
  const getPlanOption = (id) => {
    const plansList = plansListAsValueLabel() || [];
    return plansList.find((option) => id === option.value);
  };

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreatePayMethod,
      title: t("uiComponents.form.titles.create_payment_method"),
      confirmButtonLabel: t("uiComponents.form.actions.create"),
      fields: [
        {
          name: "type",
          type: FIELD_TYPES.SELECT,
          label: "Type",
          rules: string().required(),
          options: typeNamesAsValueLabel(),
          invisible: (_) => false
        },
        {
          name: "external_id",
          type: FIELD_TYPES.TEXT,
          label: t("uiComponents.form.fields.external_id"),
          rules: string().required(),
          explanation: t("uiComponents.form.info.external_id"),
          invisible: (fieldValues: any) => fieldValues.type == "voucher"
        },
        {
          name: "active",
          type: FIELD_TYPES.CHECKBOX,
          label: t("uiComponents.form.fields.is_active"),
          defaultValue: true,
          rules: boolean().required(),
          invisible: (_) => true
        },
        {
          name: "plan_id",
          type: FIELD_TYPES.SELECT,
          label: t("uiComponents.form.fields.plan_id"),
          rules: string().required(),
          options: plansListAsValueLabel()
        },
        {
          name: "start_at",
          type: FIELD_TYPES.DATE_PICKER,
          label: t("uiComponents.form.fields.start_date"),
          rules: string().required(),
          isStartOfTheDay: true,
          maxDate: (target) => target.end_at
        },
        {
          name: "end_at",
          type: FIELD_TYPES.DATE_PICKER,
          label: t("uiComponents.form.fields.end_date"),
          rules: string().required(),
          isEndOfTheDay: true,
          minDate: (target) => target.start_at
        },
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: t("uiComponents.form.fields.name"),
          rules: string()
        },
        {
          name: "email",
          type: FIELD_TYPES.TEXT,
          label: t("uiComponents.form.fields.email"),
          rules: string().email(t("uiComponents.form.errorMessages.email"))
        },
        {
          name: "mobile",
          type: FIELD_TYPES.PHONE_NUMBER,
          label: t("uiComponents.form.fields.phone_number"),
          rules: string().matches(phoneRegExp, {
            excludeEmptyString: true,
            message: t("uiComponents.form.errorMessages.phone")
          })
        }
      ]
    },
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditPayMethod,
      title: t("uiComponents.form.titles.edit_payment_method"),
      confirmButtonLabel: t("uiComponents.form.actions.save"),
      fields: [
        {
          name: "type",
          type: FIELD_TYPES.SELECT,
          label: "Type",
          defaultValue: paymentMethods?.find(
            (paymentMethod) => paymentMethod.id === selectedItemId
          )?.type,
          rules: string().required(),
          options: typeNamesAsValueLabel()
        },
        {
          name: "external_id",
          type: FIELD_TYPES.TEXT,
          label: t("uiComponents.form.fields.external_id"),
          defaultValue:
            paymentMethods?.find(
              (paymentMethod) => paymentMethod.id === selectedItemId
            )?.external_id || "",
          rules: string().required(),
          explanation: t("uiComponents.form.info.external_id"),
          invisible: (formFields: any) => formFields.type == "voucher"
        },
        {
          name: "active",
          type: FIELD_TYPES.CHECKBOX,
          label: t("uiComponents.form.fields.is_active"),
          defaultValue:
            paymentMethods?.find(
              (paymentMethod) => paymentMethod.id === selectedItemId
            )?.active || "",
          rules: boolean().required()
        },
        {
          name: "plan_id",
          type: FIELD_TYPES.SELECT,
          label: t("uiComponents.form.fields.plan_id"),
          defaultValue:
            paymentMethods?.find(
              (paymentMethod) => paymentMethod.id === selectedItemId
            )?.plan_id,
          rules: string().required(),
          options: plansListAsValueLabel()
        },
        {
          name: "start_at",
          type: FIELD_TYPES.DATE_PICKER,
          label: t("uiComponents.form.fields.start_date"),
          defaultValue:
            paymentMethods?.find(
              (paymentMethod) => paymentMethod.id === selectedItemId
            )?.start_at || null,
          rules: date().required(),
          isStartOfTheDay: true,
          maxDate: (target) => target.end_at
        },
        {
          name: "end_at",
          type: FIELD_TYPES.DATE_PICKER,
          label: t("uiComponents.form.fields.end_date"),
          defaultValue:
            paymentMethods?.find(
              (paymentMethod) => paymentMethod.id === selectedItemId
            )?.end_at || null,
          rules: date().required(),
          isEndOfTheDay: true,
          minDate: (target) => target.start_at
        },
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: t("uiComponents.form.fields.name"),
          defaultValue:
            paymentMethods?.find(
              (paymentMethod) => paymentMethod.id === selectedItemId
            )?.name || "",
          rules: string()
        },
        {
          name: "email",
          type: FIELD_TYPES.TEXT,
          label: t("uiComponents.form.fields.email"),
          defaultValue:
            paymentMethods?.find(
              (paymentMethod) => paymentMethod.id === selectedItemId
            )?.email || "",
          rules: string().email(t("uiComponents.form.errorMessages.email"))
        },
        {
          name: "mobile",
          type: FIELD_TYPES.PHONE_NUMBER,
          label: t("uiComponents.form.fields.phone_number"),
          defaultValue:
            paymentMethods?.find(
              (paymentMethod) => paymentMethod.id === selectedItemId
            )?.mobile || "",
          rules: string().matches(phoneRegExp, {
            excludeEmptyString: true,
            message: t("uiComponents.form.errorMessages.phone")
          })
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeletePayMethod,
      title: t("uiComponents.deleteForm.titles.paymentMethod"),
      confirmButtonLabel: t("uiComponents.deleteForm.actions.delete")
    },
    [DIALOG_TYPES.IS_ACTIVE]: {
      onConfirm: handleConfirmActivePayMethod,
      title: t("uiComponents.isActiveDialog.title"),
      confirmButtonLabel: t("uiComponents.isActiveDialog.actions.confirm")
    },
    [DIALOG_TYPES.ADD_CREDIT]: {
      onConfirm: handleConfirmAddCredit,
      title: t("uiComponents.addCreditForm.title"),
      confirmButtonLabel: t("uiComponents.addCreditForm.actions.confirm"),
      fields: [
        {
          name: "add_balance",
          type: FIELD_TYPES.CURRENCY_AMOUNT,
          label: t("uiComponents.form.fields.add_credit"),
          rules: number().required()
        }
      ]
    }
  };

  return (
    <>
      <Table
        isSearchEnabled={false}
        isSortingEnabled={false}
        isPaginationEnabled={false}
        rows={tablePaymentMethods() || []}
        rowsData={paymentMethods}
        columns={tableColumns}
        actions={tableActions}
        isLoading={!paymentMethods}
        backlightTableProperties={backlightTableProperties}
        tableRowActions={tableRowActions}
        searchString={searchConfiguration.search}
        toolbarItems={[
          <Button
            key="create"
            onClick={handleCreatePayMethodButtonClick}
            variant={"contained"}
            color={"accent"}
          >
            {t("paymentMethodPage.buttons.create_pay_method")}
          </Button>,
          <PayMethodTableFilterForm
            key="filter"
            tableFilterParams={searchConfiguration}
            tableColumns={sortableTableColumns}
            typeNames={filterableTypeNames}
            onTableShouldUpdate={function (
              tableFilterParams: PayMethodTableFilterParams
            ): void {
              setSearchConfiguration(tableFilterParams);
            }}
          />
        ]}
      />
      <Pagination
        count={totalPages ?? 1}
        page={searchConfiguration.page}
        onChange={(event: React.ChangeEvent<unknown>, page: number) => {
          setSearchConfiguration({
            ...searchConfiguration,
            page
          });
        }}
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
