import actionCreatorFactory from "typescript-fsa";
import { GetNestedStationsParams, GetNestedStationsResponse, GetStationsParams, GetStationsResponse } from "./types";

const actionCreator = actionCreatorFactory("STATIONS");


export const getStations = actionCreator.async<
    GetStationsParams,
    GetStationsResponse,
    unknown
>("GET_STATIONS");

export const getNestedStations = actionCreator.async<
    GetNestedStationsParams,
    GetNestedStationsResponse,
    unknown
>("GET_NESTED_STATIONS");


export const clear = actionCreator<void>("CLEAR");