import { Action } from "typescript-fsa";
import { GetAllTerminalsParams, GetAllTerminalsResponse } from "./types";
import { SagaIterator } from "redux-saga";
import { keycloak } from "../../../keycloak";
import { axiosInstance } from "../../../axios";
import { AxiosResponse } from "axios";
import { all, call, put, takeLeading } from "redux-saga/effects";
import * as actions from "./actions";

export function* getAllTerminalsSaga(
    action: Action<GetAllTerminalsParams>
): SagaIterator<void> {
    try {
        const token = keycloak.idToken?.toString() || "";
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = {};
        for (const stationId of action.payload.station_ids) {
            const partialResonse: AxiosResponse<GetAllTerminalsResponse> = yield call(
                axiosInstance.get,
                `stations/${stationId}/terminals`
            );

            response[stationId] = partialResonse;
        }
        yield put(
            actions.getAllTerminals.done({
                params: action.payload,
                result: response
            })
        );
    } catch (e) {
        yield put(
            actions.getAllTerminals.failed({ params: action.payload, error: e })
        );
    }
}

export function* watcherSaga(): SagaIterator<void> {
    yield all([
        takeLeading(actions.getAllTerminals.started, getAllTerminalsSaga)
    ]);
}
