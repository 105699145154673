import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "components/App";

export const ToggleTheme: React.FC<any> = () => {
  const theme = useTheme();

  return (
    <ColorModeContext.Consumer>
      {({ toggleColorMode }) => {
        return (
          <IconButton onClick={toggleColorMode} style={{ color: "#fff" }}>
            {theme.palette.mode === 'dark' ? <Brightness4Icon /> : <Brightness7Icon />}
          </IconButton>
        )
      }}
    </ColorModeContext.Consumer>
  )
}
