import { FC, useState } from 'react'
import { momentLocalizer } from 'react-big-calendar';
import moment from "moment";

import { CalendarToolbar } from './toolbar';
import { Calendar } from './styles';
import { EventComp } from './event';
import { CalendarEvent, DisplayEvent, Recurrency, SlotInfo } from './types';
import { useTranslation } from 'react-i18next';


type Props = {
    events: CalendarEvent[];
    createEvent: () => void;
    importEvents: () => void;
    onSelectEvent: (event: CalendarEvent) => void;
    onSelectSlot: (slotInfo: SlotInfo) => void;
}

function getFirstDateAfter(start: moment.Moment, after: moment.Moment, rec: Recurrency): moment.Moment {
    if (start.isSameOrAfter(after)) {
        return start;
    }

    const diff = after.diff(start);

    const diffRec = start.clone().add(rec.interval, rec.frequency).diff(start);

    const recSteps = Math.ceil(diff / diffRec);

    const firstDateAfter = start.clone().add(recSteps * rec.interval, rec.frequency);

    return firstDateAfter;
}

function calendarEventToDisplayEvents(event: CalendarEvent, firstDisplayableDate: Date | moment.Moment, lastDisplayableDate: Date | moment.Moment): DisplayEvent[] {
    if (event.recurrency === undefined) {
        return [event]
    }
    firstDisplayableDate = moment(firstDisplayableDate);
    lastDisplayableDate = moment(lastDisplayableDate);

    const displayEvents: DisplayEvent[] = [];
    const start = getFirstDateAfter(moment(event.start), firstDisplayableDate, event.recurrency);

    // length of a single event in milliseconds
    const diff = -moment(event.start).diff(event.end);

    let until: moment.Moment;

    if (event.recurrency.until !== undefined) {
        until = moment(event.recurrency.until);
    }
    else if (event.recurrency.count !== undefined) {
        until = moment(event.start).add((event.recurrency.count - 1) * event.recurrency.interval, event.recurrency.frequency);
    }
    else {
        until = lastDisplayableDate;
    }

    until = until.isBefore(lastDisplayableDate) ? until : lastDisplayableDate;

    for (;
        start.isSameOrBefore(until);
        start.add(event.recurrency.interval, event.recurrency.frequency)) {
        const isException = event.recurrency.exceptions?.find((date) => moment(date).isSame(start, event.recurrency?.frequency));

        if (isException !== undefined) {
            continue;
        }

        const displayEvent: DisplayEvent = {
            ...event,
            start: start.toDate(),
            end: start.clone().add(diff, "milliseconds").toDate(),
        }

        displayEvents.push(displayEvent);
    }

    return displayEvents;
}

function calendarEventsToDisplayEvents(events: CalendarEvent[], firstDisplayableMoment: Date | moment.Moment, lastDisplayableDate: Date | moment.Moment,): DisplayEvent[] {
    const displayEvents: DisplayEvent[] = [];

    events.forEach(event => displayEvents.push(...calendarEventToDisplayEvents(event, firstDisplayableMoment, lastDisplayableDate)));

    return displayEvents;
}

export const EventCalendar: FC<Props> = ({ events, createEvent, importEvents, onSelectEvent, onSelectSlot }: Props) => {
    const [t, i18n] = useTranslation();
    moment.updateLocale(i18n.language, {
        week: {
            dow: 1,
        },
        longDateFormat: {
            L: "DD.MM.YYYY",
            LL: "D MMMM YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd, D MMMM YYYY HH:mm",
            LT: "HH:mm",
            LTS: "HH:mm:ss",
        }
    })
    const localizer = momentLocalizer(moment);

    const [firstVisibleDay, setFirstVisibleDay] = useState<moment.Moment>(moment(localizer.firstVisibleDay(moment())));
    const [lastVisibleDay, setLastVisibleDay] = useState<moment.Moment>(moment(localizer.lastVisibleDay(moment())));

    const onNavigate = (newDate: Date | moment.Moment) => {
        const fvDay = localizer.firstVisibleDay(newDate);
        const lvDay = localizer.lastVisibleDay(newDate);

        if (firstVisibleDay?.diff(fvDay) == 0 && lastVisibleDay?.diff(lvDay) == 0) {
            return;
        }

        setFirstVisibleDay(moment(fvDay));
        setLastVisibleDay(moment(lvDay));
    }

    return (
        <Calendar
            selectable={true}
            popup={true}
            onSelectEvent={onSelectEvent}
            onSelectSlot={onSelectSlot}
            onNavigate={onNavigate}
            localizer={localizer}
            defaultDate={moment()}
            defaultView="month"
            events={calendarEventsToDisplayEvents(events, firstVisibleDay, lastVisibleDay)}
            showAllEvents={true}
            style={{ height: "100%", width: "100%" }}
            components={{
                event: (props) => <EventComp {...props} />,
                toolbar: (props) => <CalendarToolbar {...props}
                    createEvent={createEvent}
                    importEvents={importEvents}
                />
            }}
        />
    )
}

