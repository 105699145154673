import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import React, { FC } from "react";

export const Dropdown: FC<{
    name: string;
    label: React.ReactNode;
    value: string;
    onChange: (event: SelectChangeEvent) => void;
    items: Record<string, { name: string;[x: string]: unknown }>;
}> = (props) => {
    return (
        <FormControl fullWidth sx={{ marginTop: "10px" }}>
            <InputLabel id={props.name + "-label"}>{props.label}</InputLabel>
            <Select
                labelId={props.name + "-label"}
                id={props.name}
                value={props.value}
                label={props.label}
                onChange={props.onChange}
            >
                {Object.entries(props.items).map(([key, value], index) => (
                    <MenuItem key={index} value={key}>
                        {value.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};